import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import * as actions from '../../store/modules/auth/actions';

function SessionTimeout({ timeout }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const timeoutIdRef = useRef(null);
  const toastIdRef = useRef(null);
  const toastDisplayedRef = useRef(false);
  const intervalIdRef = useRef(null);

  const resetTimeout = () => {
    if (!isLoggedIn) {
      return;
    }

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
    }

    if (intervalIdRef.current) {
      // Adicione esta condição
      clearInterval(intervalIdRef.current);
    }

    toastIdRef.current = setTimeout(() => {
      if (!toastDisplayedRef.current) {
        let counter = 60;
        const id = toast.warning(`Você será desconectado por inatividade em ${counter} segundos.`);
        intervalIdRef.current = setInterval(() => {
          // Atualize esta linha
          counter -= 1;
          if (counter >= 0) {
            toast.update(id, { render: `Você será desconectado por inatividade em ${counter} segundos.` });
          } else {
            clearInterval(intervalIdRef.current);
          }
        }, 1000);
        toastDisplayedRef.current = true;
      }
    }, timeout - 1000 * 60);

    timeoutIdRef.current = setTimeout(() => {
      dispatch(actions.loginFailure());
      toastDisplayedRef.current = false;
      history.push('/');
    }, timeout);
  };

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    const resetAndClear = () => {
      resetTimeout();
    };

    events.forEach((event) => window.addEventListener(event, resetAndClear));

    // Inicie o timer de timeout
    resetTimeout();

    return () => {
      // Limpe o timer e os event listeners ao desmontar o componente
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      events.forEach((event) => window.removeEventListener(event, resetAndClear));
    };
  }, [isLoggedIn]);

  return null;
}

export default SessionTimeout;

SessionTimeout.propTypes = {
  timeout: PropTypes.number.isRequired,
};

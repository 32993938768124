import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 80vh;
  height: 35vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Composition = styled.div`
  margin-top: 30px;
  display: flex;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 980px;
  max-height: 1680px;
  margin: -5% auto 0%;
  overflow: auto;
  h1 {
    margin: 50px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  h4 {
    margin: 5px 0px;
    font-size: 10px;
    font-family: 'MyFont';
  }
  .button {
    margin: 20px 15px;
    width: 150px;
    color: white;
    display: flex;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    border: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100 !important;
    color: #fff !important;
    border: none !important;
    display: flex;
  }
  .button-custom:hover {
    background-color: #ae1100 !important;
  }
  .button-custom:disabled {
    background-color: #ae1100 !important;
    color: #ae1100 !important;
    display: flex;
  }
  .button-container {
    display: flex;
    gap: 10px; /* Espaço entre os botões */
    margin-top: 10px;
  }
`;
export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: 30px;
  display: flex;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 980px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  h1 {
    font-family: 'MyFont';
    margin-top: 10%;
  }
  /* width: 100%; */
  flex-direction: column;

  align-items: center;
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    font-size: 15px;
    font-family: 'MyFont';
  }
  .custom-textarea {
    width: 480px;
    min-height: 200px; /* Ajuste a altura conforme necessário */
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 15px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
  }
  h1 {
    margin: -35px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }
  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }
  button:not(.teste) {
    display: flex;
    margin-left: 0px;
    margin-top: 10px;
    width: 150px;
    color: white;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    background-color: #ae1100;
    font-family: 'MyFont';
    z-index: 1000;
  }
  button {
    margin: 20px 15px; /* Margem entre os botões */
    width: 150px;
    color: white;
    display: flex;
    background-color: #ae1100;
    font-size: 15px;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    border: none; /* Remova a borda para evitar espaçamento extra */
  }
  .text-field {
    width: 87%;
    margin-top: 3%;
    margin-bottom: 3%;

    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 50px;
    width: 500px;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 45%;
    background-color: white;
  }

  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    height: 20px;
    margin: -3% 0%;
    position: relative;
    width: 30px;
    cursor: default;

    &::before {
      bottom: -6px;
      content: '';
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: #0197f6;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }
`;

export const CenteredText = styled.h1`
  margin-bottom: 20px;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Arvore from '../../assets/img/Arvore.svg';
import { Container } from './styled';

export default function Loading({ isLoading }) {
  const [showLoading, setShowLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    let startTime;

    if (isLoading) {
      setShowLoading(true);
      setProgress(0);
      startTime = Date.now();

      interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = Math.min((elapsedTime / 1000) * 100, 100);
        setProgress(newProgress);

        if (newProgress >= 100) {
          clearInterval(interval);
          setShowLoading(false);
        }
      }, 100);
    } else {
      setShowLoading(false);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);

  if (!showLoading) return null;

  return (
    <Container>
      <div>
        <img src={Arvore} alt="Arvore" />
        <span>Carregando... {Math.round(progress)}%</span>
      </div>
    </Container>
  );
}

Loading.defaultProps = {
  isLoading: false,
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

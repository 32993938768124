import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { TextField, Button } from '@material-ui/core';
import { Form, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterFunction({ id, handleClose }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const history = useHistory();

  const idCreator = id;

  const [func, setFunction] = useState('');

  const handleStop = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleFunctionChange = (e) => {
    setFunction(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErrors = false;

    if (!func || func.length < 3 || func.length > 255) {
      formErrors = true;
      toast.error('Nome deve ter entre 3 e 255 caracteres');
    }

    if (formErrors) return;
    dispatch(
      actions.registerFunctionRequest({
        idCreator,
        func,
        history,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <Loading isLoading={isLoading} />
      <IconButton className="teste" style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Crie uma nova Função</h1>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Função"
            value={func}
            onChange={handleFunctionChange}
            placeholder="Nome da Função"
            fullWidth
            margin="normal"
          />
          <div className="button-container">
            <Button className="button" type="submit" variant="contained" color="primary" disableRipple>
              Cadastrar
            </Button>
          </div>
        </div>
      </Form>
    </Bloco>
  );
}
RegisterFunction.propTypes = {
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Composition = styled.div`
  input {
    width: 100%; /* Ajuste dinâmico */
    max-width: 480px; /* Limita a largura máxima */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px -10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  .textarea {
    width: 73%;
    height: 20%;
    padding: 10px;
    margin: 30px 10px -5px 0px;
    resize: vertical;
    font-family: 'MyFont';
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  overflow: auto;

  h1 {
    margin: 50px 0px;
    font-size: 1%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  button {
    margin-left: 0px;
    margin: 0px 5px;
    margin-top: 0px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100 !important;
    color: #fff !important;
    border: none !important;
  }
  .button-custom:hover {
    background-color: #ae1100 !important; /* Ou qualquer cor que você queira para o hover */
  }
  .button-custom:disabled {
    background-color: #ae1100 !important; /* Cor cinza para desabilitado */
    color: #ae1100 !important; /* Cor do texto quando desabilitado */
  }
`;

export const Form = styled.form`
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  justify-content: flex-start; /* Ajuste para justificar à esquerda */
  overflow: auto;
  text-align: left; /* Ajuste para justificar o texto à esquerda */

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }

  .file-input {
    margin-top: 5%;
    margin-bottom: 3%;
  }

  h4 {
    margin: 5px 0px;
    font-size: 18px;
    margin-bottom: -2%;
  }
  align-items: center;
  label {
    display: flex;
    font-family: 'MyFont';
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 15px;
  }

  button {
    margin-left: 0px;
    margin-top: 15px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }

  input {
    width: 100%; /* Ajuste dinâmico */
    max-width: 480px; /* Limita a largura máxima */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px -10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  .textarea {
    width: 89%; /* Define a largura do input como 100% */
    height: 20%;
    font-family: 'MyFont';
    font-size: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 30px 10px -5px 0px;
    &:focus {
      border: 1px solid #ae1100;
    }
  }
  select {
    width: 100%; /* Modificado para ocupar toda a largura disponível */
    margin-top: 5px;

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }

  @media (max-width: 320px) {
    input {
      width: 95%; /* Ajuste dinâmico para telas menores que 320px */
      max-width: 100%; /* Limita a largura máxima */
    }

    select {
      width: 95%; /* Ajuste dinâmico para telas menores que 320px */
      max-width: 100%; /* Limita a largura máxima */
      font-family: 'MyFont';
    }
  }
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 50%;
    background-color: white;
  }
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    width: 100%; /* Ajuste dinâmico */
    max-width: 480px; /* Limita a largura máxima */
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px 20px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;
    cursor: default;

    &::before {
      bottom: -6px;
      content: '';
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: #0197f6;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #ae1100;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }
`;

export const CenteredText = styled.h1`
  margin-bottom: 0px;
`;

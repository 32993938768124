import React from 'react';
import Rodapé from './styled';

function Footer() {
  return (
    <Rodapé>
      <p>@ BBit Solutions - Todos os Direitos Reservados</p>
    </Rodapé>
  );
}

export default Footer;

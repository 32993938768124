import styled, { createGlobalStyle } from 'styled-components';
import * as colors from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';
import px2vw from '../utils/px2vm';
import MyFont from '../fonts/eurosoft.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .svg {
    width: 25vw;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;

    @media (min-width: 1440px) {
      height: 120px;
    }
  }
  :root {
    font-size: ${px2vw(24)};

    @media (min-width: 768px) {
      font-size: ${px2vw(18)};
    }

    @media (min-width: 1024px) {
      font-size: ${px2vw(16)};
    }
  }

  body {
    font-family: 'MyFont', sans-serif;
    background: ${colors.primaryDarkColor};
    color: ${colors.primaryDarkColor};
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
  }

  button {
    cursor: pointer;
    background: ${colors.primaryColor};
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 700;
    transition: all 300ms;
    font-family: "MyFont";
    font-weight: bold;
  }

  button:hover {
    filter: brightness(75%);
  }

  a {
    text-decoration: none;
    color: ${colors.primaryColor};
  }

  ul {
    list-style: none;
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--success {
    background: #6edf4c;
  }

  body .Toastify .Toastify__toast-container .Toastify__toast--error {
    background: #ff6961;
  }

  textarea {
    width: 100%;
    font-family: 'MyFont';
    font-size: 18px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    &:focus {
      border: 1px solid red;
    }
  }
`;

export const Container = styled.section`
  overflow: hidden;
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5%;
  height: 100vh;
  z-index: 1;
  padding-left: 200px;

  @media (max-width: 1024px) {
    max-width: 100vw;
  }

  @media (max-width: 1024px) {
    max-width: 1024vw;
  }
  background: #fff;

  .content {
    margin-top: 5%;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { BaseModal, ModalContent } from './styled';

function ConfirmModal({ isOpen, message, onConfirm, onCancel }) {
  if (!isOpen) {
    return null;
  }

  return (
    <BaseModal className="base-modal">
      <ModalContent className="modal-content">
        <h3>{message}</h3>
        <button type="button" onClick={onConfirm}>
          Confirmar
        </button>
        <button type="button" onClick={onCancel}>
          Cancelar
        </button>
      </ModalContent>
    </BaseModal>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmModal;

import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from '../../services/axios';
import { Form, StyledContainer, StyledSection, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function SubmoduleEdit({ id, history, ...props }) {
  const dispatch = useDispatch();

  const QuizId = id;
  const [quizzes, setQuiz] = useState({ questions: [] });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!QuizId) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/quiz/${QuizId}`);
        const questions = JSON.parse(data.questions);
        if (!Array.isArray(questions)) {
          const quest = JSON.parse(questions);
          setQuiz({ questions: quest, maxScore: data.maxScore });
          setIsLoading(false);
          return;
        }
        setQuiz({ questions, maxScore: data.maxScore });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
        // history.push('/home-admin');
      }
    }

    getData();
  }, [QuizId, history]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleQuestionChange = (e, questionIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].questionText = e.target.value;
    setQuiz(newQuizzes);
  };

  const handleAnswerChange = (e, questionIndex, answerIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].answers[answerIndex].text = e.target.value;
    setQuiz(newQuizzes);
  };

  const handleCorrectChange = (e, questionIndex, answerIndex) => {
    const newQuizzes = { ...quizzes };
    newQuizzes.questions[questionIndex].answers[answerIndex].correct = e.target.checked;
    setQuiz(newQuizzes);
  };

  const handleSubmit = async (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    const formErrors = false;

    // Validar apenas a senha pode ser alterada

    if (formErrors) return;

    try {
      setIsLoading(true);

      dispatch(
        actions.editQuizRequest({
          history,
          id,
          questions: quizzes.questions,
          maxScore: quizzes.maxScore,
        }),
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      } else {
        console.log(errors);
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />
      <StyledContainer>
        <StyledSection>
          <Form onSubmit={handleSubmit}>
            <div className="box-container">
              <h1>Editar Avaliação</h1>
              <h4>Atualize conteúdo da Avaliação</h4>
              {Array.isArray(quizzes.questions) &&
                quizzes.questions.map((question, questionIndex) => (
                  <div key={question.id}>
                    <h2>Questões:</h2>
                    <input
                      type="text"
                      value={question.questionText}
                      onChange={(e) => handleQuestionChange(e, questionIndex)}
                    />
                    {question.answers &&
                      question.answers.map((answer, answerIndex) => (
                        <div key={answer.id}>
                          <h3>Resposta:</h3>
                          <input
                            type="text"
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(e, questionIndex, answerIndex)}
                          />
                          <label htmlFor="checkbox">
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={answer.correct}
                              onChange={(e) => handleCorrectChange(e, questionIndex, answerIndex)}
                            />
                            <p>Correta</p>
                          </label>
                        </div>
                      ))}
                  </div>
                ))}
              <button type="submit">Salvar</button>
            </div>
          </Form>
        </StyledSection>
      </StyledContainer>
    </Bloco>
  );
}

SubmoduleEdit.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    id: 'adaptable_performance',
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Plataforma Modularizada ',
    description:
      'A CyberSensei oferece uma estrutura modular que permite personalizar e adaptar os treinamentos de segurança cibernética às necessidades específicas da sua empresa.',
  },
  {
    id: 'built_to_last',
    icon: <SportsEsportsRoundedIcon />,
    title: 'Abordagem Gamificada',
    description:
      'A CyberSensei utiliza uma abordagem gamificada para tornar o treinamento de segurança cibernética interativo e envolvente.',
  },
  {
    id: 'great_user_experience',
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Gestão de Programas de Conscientização',
    description:
      'A CyberSensei facilita a gestão de programas de conscientização em segurança cibernética, permitindo monitorar o progresso dos colaboradores, avaliar o impacto dos treinamentos e ajustar as estratégias conforme necessário.',
  },
  {
    id: 'innovative_functionality',
    icon: <AutoFixHighRoundedIcon />,
    title: 'Desafios de Cibersegurança',
    description:
      'A CyberSensei incorpora desafios de cibersegurança que simulam situações reais, permitindo que os colaboradores testem suas habilidades em um ambiente seguro.',
  },
  {
    id: 'reliable_support',
    icon: <SupportAgentRoundedIcon />,
    title: 'Sensei de Cibersegurança',
    description:
      'O CyberSensei oferece um assistente virtual especializado em cibersegurança. Este "Sensel" guia os colaboradores em sua jornada de conscientização.',
  },
  {
    id: 'precision_in_every_detail',
    icon: <QueryStatsRoundedIcon />,
    title: 'Área de News e Conteúdos Colaborativos',
    description:
      'A CyberSensei inclui uma área dedicada a notícias e conteúdos colaborativos, onde os colaboradores podem acessar as últimas novidades em cibersegurança, compartilhar insights e aprender com as experiências uns dos outros, fortalecendo a cultura de segurança na empresa.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            style={{ fontFamily: 'MyFont', fontWeight: 'bold' }}
            sx={{
              fontSize: { xs: '24px', sm: '28px', md: '35px' },
            }}
          >
            Entenda como podemos ajudá-los
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="bold" gutterBottom style={{ fontFamily: 'MyFont' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'white', fontFamily: 'MyFont' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

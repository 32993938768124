import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

const Rodapé = styled.footer`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  z-index: 999;
  font-family: 'MyFont';
  p {
    font-size: small;
  }
`;

export default Rodapé;

import styled, { keyframes } from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

const blink = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
`;

export const Container = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  font-family: 'MyFont';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 30px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    overflow: hidden;
  }

  img {
    width: 400px;
    height: auto;
    margin-bottom: 0px;
    animation: ${blink} 4s infinite;
  }

  span {
    z-index: 3;
    margin-top: -30%;
  }
`;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button } from '@material-ui/core';
import { Form, Composition, Bloco } from './styled';
import Loading from '../../components/Loading';
import axios from '../../services/axios';
import * as actions from '../../store/modules/auth/actions';

export default function Register(props) {
  const id = useSelector((state) => state.auth.user.id);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const cnpj_enterprise = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [formErrors, setFormErrors] = useState(true);
  const [formStep, setFormStep] = useState(1);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [cargo, setCargo] = useState('');
  const [email, setEmail] = useState('');
  const [setor, setSetor] = useState([]);
  const [funcao, setFuncao] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState(null);
  const [selectedFuncao, setSelectedFuncao] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const departamento = 'colaborador';

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/sectors');
        if (Array.isArray(response.data)) {
          const empresaSectors = response.data.filter((sector) => sector.idCreator === id);
          console.log(empresaSectors);
          setSetor(Array.isArray(empresaSectors) ? empresaSectors : []);
        } else {
          console.error('Response data is not an array');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [id]);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/functions');
        if (Array.isArray(response.data)) {
          const empresaFunctions = response.data.filter((sector) => sector.idCreator === id);
          console.log(empresaFunctions);
          setFuncao(Array.isArray(empresaFunctions) ? empresaFunctions : []);
        } else {
          console.error('Response data is not an array');
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [id]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handleSobrenomeChange = (e) => {
    setSobrenome(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  useEffect(() => {
    const newFieldErrors = {};
    setFieldErrors(newFieldErrors);
    setFormErrors(Object.values(newFieldErrors).some((error) => error));
  }, [nome, sobrenome, cargo, email]);

  const handleSubmit = (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (formErrors) return;

    const randomPassword = Math.random().toString(36).slice(-8);

    dispatch(
      actions.registerRequest({
        cnpj_enterprise,
        nome,
        sobrenome,
        funcao: selectedFuncao,
        setor: selectedSetor,
        email,
        cargo,
        departamento,
        password: randomPassword,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px', color: '#ae1100' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1 style={{ fontFamily: 'MyFont' }}>Crie uma nova conta</h1>
          <h4 style={{ fontFamily: 'MyFont' }}>Inclua novo colaborador ao sistema</h4>
          {formStep === 1 && (
            <>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
                label="Nome *"
                value={nome}
                onChange={handleNomeChange}
                placeholder="Nome"
                margin="normal"
                error={!!fieldErrors.nome}
                helperText={fieldErrors.nome}
                style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
                label="Sobrenome *"
                value={sobrenome}
                onChange={handleSobrenomeChange}
                placeholder="Seu sobrenome"
                error={!!fieldErrors.sobrenome}
                helperText={fieldErrors.sobrenome}
                style={{ fontFamily: 'MyFont' }}
              />
              <Autocomplete
                className="autocomplete"
                InputProps={{ disableUnderline: true }}
                disablePortal
                id="combo-box-demo"
                options={setor.map((sector) => sector.sector)}
                sx={{ width: 300, fontFamily: 'MyFont' }}
                getOptionLabel={(option) => option || ''}
                onChange={(event, newValue) => {
                  setSelectedSetor(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    {...params}
                    label="Setor"
                    style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
                    inputProps={{ ...params.inputProps, style: { paddingLeft: 10, fontFamily: 'MyFont' } }}
                  />
                )}
              />

              <Autocomplete
                className="autocomplete"
                InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
                disablePortal
                style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
                id="combo-box-demo"
                options={funcao.map((func) => func.function)}
                getOptionLabel={(option) => option || ''}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  setSelectedFuncao(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="text-field"
                    {...params}
                    label="Função"
                    inputProps={{ ...params.inputProps, style: { paddingLeft: 10, fontFamily: 'MyFont' } }}
                  />
                )}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
                label="E-mail *"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Seu e-mail"
                fullWidth
                margin="normal"
                error={!!fieldErrors.email}
                helperText={fieldErrors.email}
                style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
              />
              <Button
                className="button"
                type="button"
                onClick={handleNextStep}
                variant="contained"
                color="primary"
                disabled={formErrors}
              >
                Próximo
              </Button>
            </>
          )}

          {formStep === 2 && (
            <Composition>
              <h1>Confirme dados</h1>

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                placeholder="Nome *"
                disabled
                margin="normal"
                // Adicionando o estilo para remover o foco azul
                style={{ boxShadow: 'none' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Sobrenome *"
                value={sobrenome}
                onChange={(e) => setSobrenome(e.target.value)}
                placeholder="Sobrenome"
                disabled
                margin="normal"
                // Adicionando o estilo para remover o foco azul
                style={{ boxShadow: 'none' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Perfil"
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
                placeholder="Departamento *"
                disabled
                margin="normal"
                // Adicionando o estilo para remover o foco azul
                style={{ boxShadow: 'none' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Cargo *"
                value={cargo}
                onChange={(e) => setCargo(e.target.value)}
                placeholder="Cargo"
                disabled
                margin="normal"
                // Adicionando o estilo para remover o foco azul
                style={{ boxShadow: 'none' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Setor"
                value={selectedSetor}
                disabled
                margin="normal"
                style={{ boxShadow: 'none' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Função"
                value={selectedFuncao}
                disabled
                margin="normal"
                style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Email *"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                disabled
                margin="normal"
                // Adicionando o estilo para remover o foco azul
                style={{ boxShadow: 'none' }}
              />
              <Button className="button" type="button" onClick={handlePrevStep} variant="contained" disableRipple>
                Voltar
              </Button>
              <Button className="button" type="submit" variant="contained" color="#ae1100" disableRipple>
                Finalizar
              </Button>
            </Composition>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

Register.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.section`
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0%;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background: #fff;
`;

export const StyledContainer = styled.div`
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
  height: 80%;
  width: 100%;
  margin: 90px 5px;
  font-family: 'MyFont';

  @media (max-width: 1440px) {
    max-height: 700px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .green-text {
    color: green;
  }

  .table1 {
    font-family: 'MyFont';
    margin-bottom: 20px;
  }

  .table1-button {
    width: 100%; /* Faz com que o botão ocupe 100% da largura */
  }
`;

/* export const SidebarWrapper = styled.div`
  width: ${({ isVisible }) => (isVisible ? '250px' : '0')};
  transition: width 0.3s ease;
  overflow: hidden;
  flex-shrink: 0; /* Impede que o sidebar encolha quando fechado */

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .acordeon {
    /* Exemplo de estilos do acordeão */
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .acordeon.active {
    /* Estilo para quando o acordeão está ativo */
    background-color: #f1f1f1;
  }

  label {
    flex-direction: column;
    font-family: 'MyFont';
    margin-bottom: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  button {
    margin: 20px 0;
    width: 150px;
    color: white;
    font-weight: bold;
    background-color: #ae1100;
    font-family: 'MyFont';
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  input {
    height: 40px;
    font-size: 18px;
    font-family: 'MyFont';
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 10px 0;
    &:focus {
      border: 1px solid #ae1100;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  select {
    width: 250px;
    height: 40px;
    font-family: 'MyFont';
    font-size: 18px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 10px;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-family: 'MyFont';
  font-size: 17px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    height: 80px;
    width: 80px;
    margin: 10px;
    position: relative;
    font-family: 'MyFont';
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
    }

    &:checked {
      background-color: #ae1100;

      &::after {
        left: 20px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #ae1100;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 50%;
      transition: all 100ms ease-out;
    }
  }
`;

export const StyledButton = styled.button`
  align-items: center;
  background-color: #ae1100;
  color: white;
  width: 100%;
  border: none;
  font-weight: bold;
  font-family: 'MyFont';
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #8b0a00;
  }

  svg {
    margin-right: 0px;
    width: 15px;
    height: 15px;
  }

  h3 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h5 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h2 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  h1 {
    font-family: 'MyFont';
    font-size: 20px;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .questoes {
    margin: 3% 0;
    font-family: 'MyFont';
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .table {
    font-family: 'MyFont';
    padding: 5px;
  }
`;

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  font-family: 'MyFont';

  iframe {
    width: ${({ isSidebarOpen }) => (isSidebarOpen ? '960px' : '100%')};
    height: ${({ isSidebarOpen }) => (isSidebarOpen ? '160px' : 'calc(100vh - 80px)')};
    margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '160px' : '0%')};
    transition: width 0.9s ease, height 0.9s ease;
    object-fit: contain;
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }

  .button-quiz {
    display: flex;
    align-items: center;
    background-color: #ae1100;
    color: white;
    border: none;
    font-weight: bold;
    font-family: 'MyFont';
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #8b0a00;
    }
  }

  .question {
    display: flex;
    align-items: center;
    font-family: 'MyFont';
    font-size: 20px;
    margin: 20px 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .answer-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
    }
  }

  input[type='radio'] {
    margin: 20px 20px;
    width: 20px;

    &:hover {
      background-color: #ccc;
    }
  }

  label {
    margin: 20px 2px;
    font-size: 20px;
    &:hover {
      background-color: #ccc;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .radio {
    width: 16px;
    height: 16px;
    border: 2px solid #007bff;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: #0056b3; /* cor da borda ao passar o mouse */
    }

    &:checked::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #007bff;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  .radio + label {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      color: #0056b3; /* cor do texto ao passar o mouse */
    }
  }
`;

export const SidebarWrapper = styled.div`
  width: ${({ isVisible }) => (isVisible ? '250px' : '0')};
  transition: width 0.3s ease;
  overflow: hidden;
  flex-shrink: 0; /* Impede que o sidebar encolha quando fechado */
`;

export const Sidebar = styled.div`
  width: 450px; /* Largura do sidebar */
  transition: width 0.3s ease;
  overflow: auto;
  height: 117%;
  max-height: auto;
  font-family: 'MyFont';
  padding: 20px;

  @media (max-width: 1000px) {
    max-height: 700px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MainContent = styled.div`
  flex: 1; /* Usa flex para se ajustar ao espaço disponível */
  margin-left: ${({ isSidebarOpen }) =>
    isSidebarOpen ? '20px' : '-250px'}; /* Ajusta a margem esquerda com base no estado do sidebar */
  transition: margin-left 0.8s ease; /* Transição suave ao abrir/fechar */
  padding: 10px; /* Adiciona algum padding para o conteúdo */
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0px' : '-25%')};

  @media (max-width: 768px) {
    margin-left: 0; /* Remove a margem no mobile */
    padding: 10px;
  }
  p {
    font-family: 'MyFont';
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  input[type='radio'] {
    margin: 20px -20px;
    width: 100px;
    border: 2px solid #007bff;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: #0056b3; /* cor da borda ao passar o mouse */
    }
  }

  .radio + label {
    display: inline-block;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }
  .radio {
    margin-right: 10px;
  }

  .radio:checked + label {
    background-color: #ccc; /* cor de fundo quando o rádio está selecionado */
    color: #ae1100;
  }

  .radio + label {
    display: inline-block;
    margin-left: 0px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .radio + label:hover {
    color: #ae1100; /* cor de texto ao passar o mouse */
  }

  /* Estilo para a questão */
  .question {
    display: flex;
    align-items: center;
    font-family: 'MyFont';
    font-size: 20px;
    margin: 20px 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    padding: 5px;

    &:hover {
      background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
    }
  }

  .question:hover {
    background-color: #f0f0f0; /* cor de fundo ao passar o mouse */
  }

  .button-quiz {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ae1100;
    color: white;
    width: 150px;
    margin-bottom: 10px;
    height: 40px; /* Adicione uma altura para ajudar na centralização vertical */
    border: none;
    margin: 20px 20px;
    font-weight: bold;
    font-family: 'MyFont';
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #8b0a00;
    }
  }
`;

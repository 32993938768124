import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 5px 0px;
    font-size: 18px;
    margin-bottom: 2%;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
  }

  button {
    margin-left: 0px;
    margin-top: 20px;
    width: 150px;
    color: white;
    background-color: #ae1100;
  }

  input,
  select {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    font-family: 'MyFont';
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px;
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;

    input,
    select {
      width: calc(100% - 20px); /* Considerando a margem esquerda de 10px e a margem direita de 10px */
    }
  }
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd; /* Adiciona a borda preta */
    padding: 20px; /* Adiciona preenchimento interno */
    margin-top: 90px; /* Adiciona margem superior */
    border-radius: 8px; /* Adiciona cantos arredondados */
    margin-bottom: 10px; /* Adiciona margem inferior */
    position: relative; /* Define posicionamento relativo */
  }

  .box-container h1 {
    position: relative; /* Define posicionamento relativo para o h1 */
    padding: 0px 5px;
    z-index: 1; /* Define a ordem de empilhamento para ficar acima da borda */
    margin-right: 75%; /* Ajusta a margem direita */
    background-color: white;
  }
`;

export const CoursePicture = styled.div`
  width: 40vh;
  height: 40vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

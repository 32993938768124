import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'; // Import useHistory
import axios from '../../services/axios';

import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import Sensei from '../../assets/img/AvatarFinal.jpeg';

import { Container } from '../../styles/GlobalStyles';
import { WelcomeContainer, TextContent, ParagraphContent } from './styled';

export default function Welcome() {
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const history = useHistory(); // Use useHistory

  const id = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/rhusers/${id}`);
        setFinished(response.data.started);
      } catch (err) {
        console.log('Error:', err);
      }
    };

    fetchUserData();
  }, [id]);

  const handleClick = async () => {
    const userData = {
      started: true,
    };
    if (started) {
      try {
        const response = await axios.put(`/rhusers/${id}`, userData);
        console.log('Response:', response);
        toast.success('Iniciado com sucesso.');
        history.push('/training');
        window.location.reload();
      } catch (err) {
        console.log('Error:', err);
        const status = err.response?.status || 0;
        const errors = err.response?.data?.errors || [];

        if (status === 400) errors.map((error) => toast.error(error));
      }
    } else {
      setStarted(true);
    }
  };

  return (
    <div>
      {' '}
      {/* Replace the fragment with a div */}
      {!started ? (
        <Container>
          <WelcomeContainer>
            <TextContent>
              <Logo className="logo-box" />
              <h1>Bem Vindo ao CyberSensei</h1>
              <p>
                A primeira linha de defesa <br /> está nas pessoas
              </p>
              {!finished && (
                <button className="button-welcome" type="button" onClick={handleClick}>
                  Iniciar
                </button>
              )}
            </TextContent>
            <img src={Sensei} alt="Sensei" className="sensei-box" />
          </WelcomeContainer>
        </Container>
      ) : (
        <Container>
          <WelcomeContainer>
            <TextContent>
              <Logo className="logo-box2" />
              <h2>O que estou fazendo aqui?</h2>
            </TextContent>
            <ParagraphContent>
              <p>
                Parabéns! Se você chegou até aqui, significa que foi selecionado para participar do programa de
                Onboarding em Segurança da Informação. A seguir, você terá acesso a todas as capacitações que precisam
                ser concluídas para finalizar o programa. <br />
                <br />É imprescindível que você finalize todos os conteúdos com máxima atenção, pois nossa empresa
                valoriza muito a segurança das informações do nosso negócio, e você é peça fundamental nesse processo!
                <br />
                <br />
                Boa jornada!
              </p>
              {!finished && (
                <button className="button-welcome" type="button" onClick={handleClick}>
                  Iniciar
                </button>
              )}
            </ParagraphContent>
          </WelcomeContainer>
        </Container>
      )}
    </div>
  );
}

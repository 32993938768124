import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { TextField, Button, InputAdornment, IconButton, useMediaQuery } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import { Form, LoginContainer, Container } from './styled';
import * as actions from '../../store/modules/auth/actions';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';

export default function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const prevPath = get(props, 'location.state.prevPath', '/');
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [isLoading, setIsLoading] = React.useState(true);
  const departament = useSelector((state) => state.auth.user.departamento);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [isComponentMounted, setIsComponentMounted] = React.useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    if (isLoggedIn) {
      if (departament === 'Empresa') history.push('/home-empresa');
      if (departament === 'Admin') history.push('/home-admin');
      if (departament === 'colaborador') history.push('/home');
    }

    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, [isLoggedIn, history, departament]);

  React.useEffect(() => {
    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const handleVerify = (response) => {
    setRecaptchaToken(response);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = false;

    if (!isEmail(email)) {
      formErrors = true;
      toast.error('E-mail inválido.');
    }

    if (password.length < 6 || password.length > 50) {
      formErrors = true;
      toast.error('Senha inválida');
    }

    if (!recaptchaToken) {
      formErrors = true;
      toast.error('Por favor, confirme que você não é um robô.');
    }
    if (formErrors) return;

    dispatch(actions.loginRequest({ email, password, prevPath, history, recaptchaToken }));
  };

  const eyeIconStyle = {
    fontSize: isSmallScreen ? '24px' : '20px',
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      {!isLoading && isComponentMounted && (
        <>
          <LoginContainer smallScreen={isSmallScreen} isTablet={isTablet}>
            <Form onSubmit={handleSubmit} smallScreen={isSmallScreen} isTablet={isTablet}>
              <Logo className="logo" />
              <TextField
                InputLabelProps={{
                  className: 'input-label',
                }}
                className="text-field"
                InputProps={{ disableUnderline: true }}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Seu e-mail"
                placeholder="Seu e-mail"
                variant="outlined"
              />
              <TextField
                className="text-field-password"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        classes="IconButton"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="button-eye"
                        style={{ padding: '10px' }}
                      >
                        {showPassword ? <Visibility style={eyeIconStyle} /> : <VisibilityOff style={eyeIconStyle} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  className: 'input-label',
                }}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Sua senha"
                placeholder="Sua senha"
                variant="outlined"
              />

              <ReCAPTCHA
                className="recaptcha-container"
                sitekey="6LcRF8YpAAAAACQT3aj3S6H2e3eMZpEGtEA5EpxQ"
                onChange={handleVerify}
              />

              <Button type="submit" variant="contained" color="primary">
                Entrar
              </Button>
              <Link className="enviar" to="/CreateAccount">
                Criar Conta
              </Link>
              <Link className="enviar" to="/recuperar-senha">
                <p> Esqueceu sua senha?</p>
              </Link>
            </Form>
          </LoginContainer>
          <Footer />
        </>
      )}
    </Container>
  );
}

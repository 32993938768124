import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';
import breakpoints from '../../styles/breakpoints';

export const Nav = styled.nav`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  background-color: white;
  width: 100%;
  position: fixed; /* Fixa a Nav no topo */
  top: 0;
  height: 100px; /* Ajuste conforme necessário */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
  p {
    color: black;
    font-size: medium;
    font-family: 'MyFont';
  }
  button {
    background-color: transparent;
  }

  -ms-overflow-style: none;
  .bar {
    margin-top: 5%;
  }
  .logo-box {
    object-fit: contain;
    width: 200px;
    height: 100%;
  }
  .logo-avatar {
    width: 47px;
    margin-bottom: 8px;
    height: 30%;
    margin-left: -50%;
    border-radius: 50%;
    z-index: 0;
    object-fit: contain;
  }
  .moldura {
    width: 76px;
    height: 77px;
    margin-left: -100%;
    margin-top: 13px;
    z-index: 1;
    object-fit: contain;
  }
  .div-arrow {
    display: flex;
    align-items: center;
  }
  .logo-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    height: 100px;
  }

  .teste {
    margin: -10px 10px;
    font-size: 15px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    max-width: 200px; /* Ajuste conforme necessário */
    flex-wrap: wrap; /* Permite que o texto quebre para a próxima linha */
    align-items: center;
    word-break: break-word;
    @media ${breakpoints.md} {
      margin: auto;
    }
    @media ${breakpoints.lg} {
      margin: -15 20px;
    }

    @media ${breakpoints.sm} {
      margin: auto;
    }
  }

  .AvatarOff {
    width: 49px;
    margin-bottom: 8px;
    margin-left: -50%;
    border-radius: 50%;
    z-index: 0;
    object-fit: contain;
  }

  .menu {
    position: absolute;
    list-style-type: none;
    margin: 20% 10%;
    top: -225%;
    padding: 0;
    border: 1px solid grey; /* Ajuste os estilos de borda se necessário */
    @media ${breakpoints.md} {
      margin: auto;
    }
  }

  .menu > li {
    margin: 0;
    background-color: white;
  }

  .menu > li:hover {
    background-color: lightgray;
  }

  .menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
    font: inherit;
    cursor: pointer;
    font-family: 'MyFont';
  }

  a {
    color: #fff;
    margin: 0 10px 0 0;
    font-weight: bold;
    white-space: nowrap; /* Evita que o texto seja quebrado em várias linhas */
    text-overflow: ellipsis; /* Adiciona reticências (...) para indicar que há mais texto além do visível */
  }

  /* Ajustes responsivos */

  @media (max-width: 1024px) {
    .logo-bar {
      display: flex;
    }
    .bar {
      margin-top: 10%;
      margin-bottom: 30px;
    }
    .nome h3 {
      font-size: 15px;
    }

    .menu {
      width: 20%; /* Mantém a largura total */
    }
  }
  @media (max-width: 1440px) {
    .logo-bar {
      display: flex;
    }
    .moldura {
      display: flex;
      object-fit: contain;
    }
    .bar {
      margin-top: 10%;
    }

    .nome h3 {
      font-size: 20px;
    }

    .menu {
      width: 20%; /* Mantém a largura total */
    }
  }
  @media (max-width: 768px) {
    .nome h3 {
      font-size: 20px;
    }

    .menu {
      width: 20%; /* Mantém a largura total */
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    .logo-box {
      width: 120px;
      height: 80px;
    }

    .logo-avatar {
      width: 40px;
      height: 30px;
      margin-top: 15%;
    }

    .moldura {
      width: 50px;
      height: 50px;
    }

    .nome h3 {
      font-size: 10px;
    }

    .div-arrow {
      margin-right: 5px;
    }
  }
  @media (max-width: 768px) {
    .menu {
      position: static; /* Alterando para posicionamento estático em telas menores */
      width: 100%; /* Definindo a largura total */
      border: none;
    }
  }
`;

export const SecondNav = styled.nav`
  .active {
    background-color: #ae1100;
    width: 170px;
    transition: all 0.7s ease;
    transform: scale(1.1);
    width: 180px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
  }
  position: fixed;
  top: 100px; /* Altura da Nav para que SecondNav comece logo abaixo */
  left: 0;
  width: 180px;
  background: black;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(100vh - 100px);
  z-index: 1;
  transition: all 0.7s ease;
  a {
    color: #fff;
    margin: 10px 0; /* Adicionei um espaçamento vertical entre os links */
    font-weight: bold;
    display: flex;
    align-items: center;
    transition: all 0.7s ease;
    transform: scale(0.9);
    cursor: pointer;
    width: 190px;
  }

  .logout-button {
    cursor: auto;
    background: transparent;
    border: none;
    color: #fff;
    padding: 0px 35px;
    border-radius: 0;
    font-weight: normal;
    transition: none;
    font-family: inherit;
    margin-top: auto; /* Faz o botão de logout ficar no final */
    align-self: flex-end;
  }

  .logout-button:hover {
    background-color: #ae1100;
    width: 170px;
    transition: all 0.7s ease;
    transform: scale(1.2);
    width: 180px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
  }

  a:hover,
  button.button-teste:hover {
    background-color: #ae1100;
    width: 170px;
    transition: all 0.7s ease;
    transform: scale(1.1);
    width: 180px;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
  }
  button.button-teste {
    cursor: auto;
    background: transparent;
    border: none;
    color: #fff;
    padding: 0px;
    border-radius: 0;
    font-weight: normal;
    transition: none;
    font-family: inherit;
  }

  button.button-teste:hover {
    filter: none;
  }
  button {
    border: none;
    background-color: transparent;
    color: #fff;
    margin: 10px 0; /* Adicionei um espaçamento vertical entre os links */
    font-weight: bold;
    display: flex;
    align-items: center;
    transition: all 0.7s ease;
    transform: scale(0.9);
    width: 190px;
    white-space: nowrap;
  }
  button:hover {
    filter: brightness(100%);
  }

  a p {
    white-space: nowrap; /* Evita que o texto quebre para a próxima linha */
  }
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 425px) {
    width: 100px;
  }
`;

export const StyledP = styled.p`
  margin-right: 15px;
  padding: 0px 0px 0px 12px;
  position: relative;
  top: 2.5px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 38px;
  color: white;
  font-family: 'MyFont';
  font-weight: 400;
  cursor: pointer;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const Svg = styled.svg`
  width: 44%;

  path {
    transition: all 0.2s;
  }
`;

export const Tooltip = styled.span`
  --tooltip-color: rgb(41, 41, 41);
  position: absolute;
  top: -40px;
  background-color: var(white);
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 12px;
  font-weight: 600;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.105);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  transition: all 0.5s;
  min-width: 100px;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    content: '';
    background-color: var(--tooltip-color);
    bottom: -10%;
  }
`;

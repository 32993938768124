import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export const WelcomeContainer = styled.div`
  margin-left: 7%;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -3%;
  /* flex-direction: row; */
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  .sensei-box {
    margin-top: 2%;
    width: 50%;
    height: 80%;

    @media ${breakpoints.xl} {
      object-fit: contain;
    }
  }
`;

export const TextContent = styled.div`
  .button-welcome {
    margin-left: 1%;
    margin-top: 10px;
    font-size: 15px;
    width: 60%;
    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }
  display: flex;
  flex-direction: column;
  .logo-box {
    width: 70%;
    margin-top: -10%;
    margin-left: -5%;

    @media ${breakpoints.xl} {
      object-fit: contain;
    }

    @media ${breakpoints.lg} {
      width: 90%;
    }
    @media ${breakpoints.md} {
      width: 90%;
    }
  }
  h2 {
    margin-top: -20%;
    font-size: 60px;
    @media ${breakpoints.xl} {
      object-fit: contain;
    }
    @media ${breakpoints.lg} {
      object-fit: contain;
      font-size: 50px;
      margin-top: -50%;
    }
    @media ${breakpoints.md} {
      object-fit: contain;
      font-size: 50px;
      margin-top: -60%;
    }

    @media (max-width: 1464px) {
      margin-top: -25%;
    }

    @media (max-width: 1372px) {
      margin-top: -30%;
    }

    @media (max-width: 1118px) {
      font-size: 60px;
      margin-top: -40%;
    }

    @media (max-width: 1373px) {
      font-size: 50px;
      margin-top: -20%;
    }
    @media (max-width: 1248px) {
      font-size: 40px;
      margin-top: -50%;
    }
  }
  h1 {
    margin-top: -20%;
    font-size: 70px;
    @media ${breakpoints.xl} {
      object-fit: contain;
    }
    @media ${breakpoints.lg} {
      object-fit: contain;
      font-size: 70px;
      margin-top: -60%;
    }
    @media ${breakpoints.md} {
      object-fit: contain;
      font-size: 40px;
      margin-top: -60%;
    }

    @media (max-width: 1464px) {
      margin-top: -25%;
    }

    @media (max-width: 1372px) {
      margin-top: -30%;
    }

    @media (max-width: 1118px) {
      font-size: 60px;
      margin-top: -40%;
    }
  }
  p {
    margin: 10px;
    font-weight: bold;
    font-size: 35px;

    @media ${breakpoints.xl} {
      object-fit: contain;
    }

    @media ${breakpoints.lg} {
      object-fit: contain;
      font-size: 30px;
    }
  }

  .logo-box2 {
    width: 90%;
    margin-top: -15%;
    margin-left: -35%;
    margin-bottom: 5%;

    @media ${breakpoints.xl} {
      object-fit: contain;
    }

    @media ${breakpoints.lg} {
      width: 90%;
    }
    @media ${breakpoints.md} {
      width: 90%;
    }
  }
  .logo-box2 {
    width: 500px;
    margin-top: 5%;
    margin-left: -10%;
    margin-bottom: -5%;

    @media ${breakpoints.xl} {
      object-fit: contain;
    }

    @media ${breakpoints.lg} {
      width: 300px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
  }
`;

export const ParagraphContent = styled.div`
  .button-welcome {
    margin-left: 1%;
    margin-top: 10px;
    font-size: 20px;
    width: 100%;
    height: 40px;
    color: white;
    text-transform: none;
    font-weight: bold;
    font-family: 'MyFont';
    background-color: #ae1100;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;
    }
  }
  margin: 9%;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 50px;
    margin-left: -15%;
    @media ${breakpoints.xl} {
      object-fit: contain;
    }
    @media ${breakpoints.lg} {
      object-fit: contain;
      font-size: 50px;
      margin-top: -60%;
    }
    @media ${breakpoints.md} {
      object-fit: contain;
      font-size: 20px;
      margin-top: -60%;
    }

    @media (max-width: 1464px) {
      margin-top: -25%;
    }

    @media (max-width: 1372px) {
      margin-top: -30%;
      font-size: 20px;
    }

    @media (max-width: 1118px) {
      font-size: 60px;
      margin-top: -40%;
    }
  }
  p {
    margin: 10px;
    font-weight: bold;
    margin-top: 20%;
    font-size: 20px;
    text-align: justify;
    @media ${breakpoints.xl} {
      object-fit: contain;
    }

    @media ${breakpoints.lg} {
      object-fit: contain;
      font-size: 20px;
    }
  }
`;

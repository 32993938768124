import styled from 'styled-components';

export const BaseModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin-left: 0px;
    margin: 0px 20px;
    margin-top: 20px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100 !important;
    color: #fff !important;
    border: none !important;
  }
  .button-custom:hover {
    background-color: #ae1100 !important; /* Ou qualquer cor que você queira para o hover */
  }
  .button-custom:disabled {
    background-color: #ae1100 !important; /* Cor cinza para desabilitado */
    color: #ae1100 !important; /* Cor do texto quando desabilitado */
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  button {
    background-color: red;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import Slider from 'react-slick';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Fricon from '../../assets/img/Fricon.svg';
import Bahia from '../../assets/img/Bahia.svg';
import Diorama from '../../assets/img/Diorama.svg';
import ServTax from '../../assets/img/ServTaxi.svg';
import Monte from '../../assets/img/Monte (1).svg';
import Planet from '../../assets/img/planet.svg';
import My from '../../assets/img/myConnect.svg';
import Combogo from '../../assets/img/Combogo.svg';
import Ear from '../../assets/img/ear (1).svg';
import Troppa from '../../assets/img/Troppa.svg';
import Qca from '../../assets/img/QCA.svg';
import Ciel from '../../assets/img/ciel.svg';
import Vila from '../../assets/img/Vila.svg';

const logos = [
  { id: 1, src: ServTax, link: 'https://www.servitaxi.com.br/' },
  { id: 2, src: Fricon, link: 'https://fricon.com.br/' },
  { id: 3, src: Qca, link: 'https://www.qca.adv.br/' },
  { id: 4, src: Diorama, link: 'https://dioramadigital.com/' },
  { id: 5, src: Monte, link: 'https://www.monterodovias.com.br/' },
  { id: 6, src: Planet, link: 'https://www.planetprinter.com.br/' },
  { id: 7, src: My, link: 'https://myconnectpe.com.br/' },
  { id: 8, src: Combogo, link: 'https://combogocomunicacao.com.br/' },
  { id: 9, src: Ear, link: 'https://www.ear.com.br/pt-br/' },
  { id: 10, src: Troppa, link: 'https://troppadigital.com.br/' },
  { id: 11, src: Bahia, link: 'https://www.bahiagas.com.br/' },
  { id: 12, src: Ciel, link: null },
  { id: 13, src: Vila, link: 'https://planosempre.com.br/' },
];

const logoContainerStyle = {
  margin: '0', // Define uma margem menor para diminuir o espaçamento entre as imagens
};

const logoStyle = {
  width: '60%',
  height: 'auto',
  opacity: 1,
  margin: '0 25%',
};

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ae1100',
        borderRadius: '50%',
        padding: '10px',
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      aria-label="Próximo"
    >
      <ArrowForward style={{ color: 'white' }} />
    </div>
  );
}

NextArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#ae1100',
        borderRadius: '50%',
        padding: '10px',
        position: 'absolute',
        left: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      aria-label="Anterior"
    >
      <ArrowBack style={{ color: 'white' }} />
    </div>
  );
}

PrevArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      },
    },
  ],
};

export default function LogoCollection() {
  const theme = useTheme();
  const logosToShow = theme.palette.mode === 'light' ? logos : logos;

  return (
    <Box id="logoCollection" sx={{ py: 9, position: 'relative' }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
        style={{ fontFamily: 'MyFont', color: '#ae1100', fontWeight: 'bold' }}
        sx={{
          fontSize: { xs: '24px', sm: '28px', md: '35px' },
        }}
      >
        Empresas que já iniciaram sua jornada
      </Typography>
      <Box sx={{ width: '80%', margin: '0 auto', position: 'relative' }}>
        <Slider {...sliderSettings}>
          {logosToShow.map((logo) => (
            <div key={logo.id} style={logoContainerStyle}>
              {logo.link ? (
                <a href={logo.link} target="_blank" rel="noopener noreferrer">
                  <img src={logo.src} alt={`Logo ${logo.id}`} style={logoStyle} />
                </a>
              ) : (
                <img src={logo.src} alt={`Logo ${logo.id}`} style={logoStyle} />
              )}
            </div>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

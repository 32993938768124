import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';
import MyFont from '../../fonts/eurosoft.ttf';

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  h1 {
    margin: 5px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }
  h4 {
    margin: 10px 0px;
    font-size: 18px;
    margin-bottom: 2%;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  label {
    display: flex;
    font-family: 'MyFont';
    flex-direction: column;
    margin-bottom: 20px;
    margin: 5px 5px;
    font-size: 15px;
  }
  input {
    width: 480px; /* Modificado para ocupar toda a largura disponível */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 2px 0px 10px 0px; /* Ajustado para ocupar menos espaço vertical */
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
    @media (max-width: 768px) {
      input,
      select {
        width: 80%;
      }
    }

    @media (max-width: 425px) {
      overflow-y: auto;
      max-height: 100vh;
      input,
      select {
        width: 100%;
      }
    }
  }
  button:not(.my-special-button) {
    margin-bottom: 3%;
    margin-top: 2%;
    width: 150px;
    height: 40px;
    color: white;
    background-color: #ae1100;
    border-radius: 4%;
    padding: 0; /* Ensure padding doesn't affect the shape */
    text-align: center; /* Center the text */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  p {
    font-family: 'MyFont';
    color: black;
    text-decoration: underline;
    text-align: center;
  }

  margin-top: 5%;
  .text-field {
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  margin-bottom: 1%;
  margin-left: 3.5%;
  display: flex;
  justify-content: start;
  width: 100%;
`;

export const ExpandedContainer = styled.div`
  margin-bottom: 1%;
  margin-left: 3.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const Toggle = styled.label`
  margin-top: 0.37%;
  margin-left: 20.7%;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  transition-duration: 0.5s;
  transform: ${(props) => (props.checked ? 'rotate(180deg)' : 'none')};
`;

export const Bar = styled.div`
  width: ${(props) => (props.id === 'bar2' ? '100%' : '70%')};
  height: 3px;
  background-color: #ae1100;
  border-radius: 3px;
  position: ${(props) => (props.checked ? 'absolute' : 'static')};
  transform: ${(props) => {
    if (!props.checked) return 'none';
    if (props.id === 'bar1') return 'rotate(45deg)';
    if (props.id === 'bar2') return 'scaleX(0)';
    if (props.id === 'bar3') return 'rotate(-45deg)';
    return 'none';
  }};
  transition-duration: 0.5s;
`;

export const Button = styled.button`
  background-color: transparent;
  position: relative;
  border: none;
  margin-left: -15%;

  &:hover > svg {
    transform: scale(1.5);
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
    top: -50%;
  }

  &::after {
    content: 'delete';
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    height: fit-content;
    background-color: rgb(168, 7, 7);
    padding: 4px 8px;
    border-radius: 5px;
    transition: 0.2s linear;
    transition-delay: 0.2s;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
  }
`;

export const Icon = styled.svg`
  transform: scale(1.2);
  transition: 0.2s linear;
  margin: 0px 10px;

  & path {
    transition: 0.2s linear;
  }

  &:hover path {
    fill: rgb(168, 7, 7);
  }
`;

export const ButtonAjust = styled.button`
  margin: 0px 40px;
  margin-top: 5%;
  background-color: transparent;
  position: relative;
  border: none;

  &:hover > svg {
    transform: scale(1.5);
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
    top: -50%;
  }

  &::after {
    content: 'Ajustar';
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    height: fit-content;
    background-color: grey;
    padding: 4px 8px;
    border-radius: 5px;
    transition: 0.2s linear;
    transition-delay: 0.2s;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
  }
  .ajust {
    transform: scale(1.2);
    transition: 0.2s linear;

    & path {
      transition: 0.2s linear;
    }

    &:hover path {
      fill: grey;
    }
  }
`;
export const AjustIcon = styled(FaCog)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: ${(props) => (props.isMobile ? '10px' : '16px')};
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

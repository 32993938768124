import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { TextField, Button, TextareaAutosize } from '@material-ui/core';
import { Form, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterSubmodule({ id, handleClose }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const history = useHistory();

  const courseId = id;

  const [formStep, setFormStep] = useState(1);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleStop = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleNomeChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErrors = false;
    switch (formStep) {
      case 1:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        if (!description || description.length < 3 || description.length > 10000) {
          formErrors = true;
          toast.error('Descrição deve ter entre 3 e 10000 caracteres');
        }
        break;
      case 2:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        if (!description || description.length < 3 || description.length > 10000) {
          formErrors = true;
          toast.error('Descrição deve ter entre 3 e 10000 caracteres');
        }
        break;

      default:
        break;
    }

    if (formErrors) return;
    dispatch(
      actions.registerSubmoduleRequest({
        courseId,
        name,
        description,
        history,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <Loading isLoading={isLoading} />
      <IconButton className="teste" style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          {formStep === 1 && (
            <>
              <h1>Crie um novo Submódulo</h1>
              <h4>Inclua novo submódulo ao curso</h4>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={handleNomeChange}
                placeholder="Nome do Submodulo"
                fullWidth
                margin="normal"
              />
              <TextareaAutosize
                aria-label="minimum height"
                InputProps={{ disableUnderline: true }}
                label="Descrição"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Descrição do Submodulo"
                fullWidth
                margin="normal"
                className="custom-textarea"
              />
              <div className="button-container">
                <Button className="button" type="button" onClick={handleNextStep}>
                  Próximo
                </Button>
              </div>
            </>
          )}

          {formStep === 2 && (
            <>
              <h1>Confirme Submodulo</h1>

              <TextField
                className="text-field-password"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
                disabled
                margin="normal"
              />
              <TextareaAutosize
                InputProps={{ disableUnderline: true }}
                label="Descrição"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Descrição"
                disabled
                margin="normal"
                className="custom-textarea"
              />

              <div className="button-container">
                <Button className="button" type="button" onClick={handlePrevStep} variant="contained" disableRipple>
                  Voltar
                </Button>
                <Button className="button" type="submit" variant="contained" color="primary" disableRipple>
                  Finalizar
                </Button>
              </div>
            </>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

RegisterSubmodule.propTypes = {
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

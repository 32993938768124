import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, NoHoverButton } from './styled'; // Adicionando NoHoverButton
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import EditEmp from '../EmpEdit';

export default function User() {
  const [rhusers, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => state.auth.user.id);
  const cnpj_enterprise_loged = useSelector((state) => state.auth.user.cnpj_enterprise);

  const [editUserId, setEditUserId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (id) => {
    setEditUserId(id);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/rhusers');
        const empresaAdminUsers = response.data.filter(
          (user) =>
            user.departamento &&
            user.departamento.toLowerCase() !== 'empresa' &&
            user.departamento.toLowerCase() !== 'admin' &&
            user.cnpj_enterprise === cnpj_enterprise_loged,
        );
        setUsers(empresaAdminUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [userId]);

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <CollaboratorContainer>
        <h1>Ajuste de Colaboradores</h1>
        <h4>Atualize informações de colaborador</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rhusers.map((rhuser) => (
              <TableRow key={String(rhuser.id)}>
                <TableCell>{rhuser.nome}</TableCell>
                <TableCell>{rhuser.email}</TableCell>
                <TableCell>
                  <NoHoverButton type="button" onClick={() => handleOpen(rhuser.id)} aria-label="Edit">
                    <FaEdit color="black" size={16} />
                  </NoHoverButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          open={open}
          onClose={handleClose}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          {handleClose && <EditEmp id={editUserId} handleClose={handleClose} />}
        </Modal>
      </CollaboratorContainer>
    </Container>
  );
}

import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  background-color: white;
  display: flex;
  width: 100%;
  height: 75%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  -ms-overflow-style: none;
  overflow-y: auto;
  h1 {
    margin: 5px 0px;
    margin-top: -35px;
    font-size: 20px;
    font-family: 'MyFont';
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 0px;
    font-family: 'MyFont';
  }

  .button {
    margin: 20px;
    margin-left: 0px;
    margin-top: 15px;
    width: 428px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    overflow-y: auto;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100;
    color: #fff;
    border: none;
  }
  .button-custom:hover {
    background-color: #ae1100;
  }
  .button-custom:disabled {
    background-color: #ae1100;
    color: #ae1100;
  }
  .Button {
    margin: -10px 17px;
    margin-left: 10px;
    margin-top: -80px;
    width: 202px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
  .button-custom {
    background-color: #ae1100;
    color: #fff;
    border: none;
  }
  .button-custom:hover {
    background-color: #ae1100;
  }
  .button-custom:disabled {
    background-color: #ae1100;
    color: #ae1100;
  }
  .text-field {
    font-family: 'MyFont';
    & label.Mui-focused {
      font-family: 'MyFont';
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      font-family: 'MyFont';
      &.Mui-focused fieldset {
        border-color: #ae1100;
        .no-border {
          border: none;
        }
      }
    }
  }
  .fixed-height .MuiOutlinedInput-root {
    height: auto; /* Altura automática para ajustar ao conteúdo */
    border: none;
  }

  .fixed-height .MuiOutlinedInput-multiline {
    padding: 5px; /* Remover padding interno */
    border: none;
  }

  .fixed-height .MuiInputBase-inputMultiline {
    height: 100px; /* Altura fixa */
    border: none;
  }

  .form-control {
    margin: 50px 0px;
    width: 50px;
    margin-right: 10%;
  }
  .form-control h1 {
    margin-right: 80%; /* Ajusta a margem direita */
  }

  input {
    width: 380px;
    height: 80px;
    margin: -10px;
    font-size: 18px;
    font-family: 'MyFont';
    padding: -10 0px;
    border-radius: 4px;
    border: none;
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 0px 20px;
    width: 520px;
    height: 460px;
    margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 0px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 50%;
    background-color: white;
  }
  @media screen and (min-width: 868px) {
    margin-top: 20px;
    width: 580px;
  }

  /* Estilos para telas menores */
  @media screen and (max-width: 867px) {
    margin-top: 10px;
    width: calc(100% - 40px); /* Largura total menos a margem lateral */
  }
  .option-label {
    margin-top: -10px;
  }
`;

export const Popup = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  position: fixed;
  font-family: 'MyFont';
  width: 700px; /* Largura fixa */
  /* height: 600px; */ /* Remova a altura para que o tamanho seja determinado pelo conteúdo */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;
`;
export const StyledButton = styled.button`
  margin: 20px 0px;
  margin-top: -20px;
  width: 365px;
  height: 33px;
  padding: 1.05%;
  text-align: center;
  border-radius: 4px;
  color: white;
  background-color: #ae1100;
  font-family: 'MyFont';
  font-weight: bold;
  z-index: 1000;
  font-size: 16px;
  text-transform: none;
  &:hover {
    background-color: #990000;
  }
  &:disabled {
    background-color: #ae1100;
    color: #ae1100;
  }
`;

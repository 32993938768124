import React, { useState } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import * as actions from '../../store/modules/auth/actions';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';

import { Form, Composition, Container } from './styled';
import Loading from '../../components/Loading'; // Importe o componente Loading
import Footer from '../../components/Footer';

function PasswordResetPage() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const location = useLocation();
  const isRecovery = location.pathname === '/recuperar-senha';
  const history = useHistory();

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isEmail(email)) {
      toast.error('E-mail inválido.');
      return;
    }

    dispatch(actions.passwordResetRequest({ history, email, endpoint: '/rhusers/recover' }));
  };

  return (
    <Container>
      <Loading isLoading={isLoading} /> {/* Renderize o componente Loading aqui */}
      <Composition>
        <Logo className="logo" />
        <h1>{isRecovery ? 'Recuperação de Senha' : 'Redefinição de Senha'}</h1>
        <Form onSubmit={handleSubmit}>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Seu e-mail" />
          <div className="button-container">
            <Link className="link" to="/login">
              <button className="buttonLink" type="button">
                Voltar
              </button>
            </Link>
            <button type="submit" disabled={isLoading}>
              {isRecovery ? 'Próximo' : 'Redefinir Senha'}
            </button>
          </div>
        </Form>
      </Composition>
      <Footer />
    </Container>
  );
}

export default PasswordResetPage;

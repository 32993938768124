import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { TextField, Button } from '@material-ui/core';
import { Form, Bloco } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterSector({ id, handleClose }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const idSector = id;

  const [sector, setSector] = useState('');

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get(`/sectors/${idSector}`);
        setSector(response.data.sector); // Atualize o estado do setor com o nome do setor
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [id]);

  const handleStop = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleSectorChange = (e) => {
    setSector(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErrors = false;

    if (!sector || sector.length < 3 || sector.length > 255) {
      formErrors = true;
      toast.error('Nome deve ter entre 3 e 255 caracteres');
    }

    if (formErrors) return;
    dispatch(
      actions.editSectorRequest({
        id,
        sector,
        history,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <Loading isLoading={isLoading} />
      <IconButton className="teste" style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Ajuste o nome do setor</h1>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Setor"
            value={sector} // O valor do campo de entrada é o estado do setor
            onChange={handleSectorChange}
            placeholder="Nome do Setor"
            fullWidth
            margin="normal"
          />
          <div className="button-container">
            <Button className="button" type="submit" variant="contained" color="primary" disableRipple>
              Ajustar
            </Button>
          </div>
        </div>
      </Form>
    </Bloco>
  );
}
RegisterSector.propTypes = {
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const size = {
  sm: '460px', // Small devices (tablets, 600px and up)
  md: '801px', // Medium devices (small laptops, 900px and up)
  lg: '1240px', // Large devices (desktops, 1200px and up)
  xl: '1660px',
};

const breakpoints = {
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
};

export default breakpoints;

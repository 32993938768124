import React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Fundo from '../../assets/img/Backgroud.svg';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={{
        position: 'relative',
        backgroundColor: 'black',
        width: '100%',
        pt: { xs: 4, sm: 8 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Barra branca na parte superior */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100px', // Altura da barra branca
          backgroundColor: 'white',
          zIndex: 3,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${Fundo})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          opacity: 0.3, // Ajuste a opacidade conforme necessário
          zIndex: 1,
        }}
      />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 10, sm: 14 },
          pb: { xs: 6, sm: 8 },
          marginX: 'auto',
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            textAlign: 'center',
            mb: { xs: 3, sm: 2 },
            fontSize: { xs: '30px', sm: '40px', md: '40px' },
          }}
          style={{ fontFamily: 'MyFont', color: 'white', fontWeight: 'bold', marginTop: '30px' }}
        >
          Bem-vindo ao CyberSensei
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            mb: { xs: 3, sm: 2 },
            fontSize: { xs: '24px', sm: '28px', md: '25px' },
          }}
          style={{ fontFamily: 'MyFont', color: 'white' }}
        >
          Junte-se a nós nesta jornada de democratização da Segurança da Informação.
        </Typography>

        <Box
          id="video"
          sx={(theme) => ({
            mt: { xs: 6, sm: 8 },
            alignSelf: 'center',
            height: { xs: 200, sm: 400, md: 500 },
            width: '100%',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor: theme.palette.mode === 'light' ? alpha('#BFCCD9', 0.5) : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#BFCCD9', 0.2)}`
                : `0 0 24px 12px ${alpha('#BFCCD9', 0.2)}`,
          })}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ztzL_IitYHY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              borderRadius: '10px',
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { toast } from 'react-toastify';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from '@mui/material/Modal';
import { FaCog } from 'react-icons/fa';

import { useMediaQuery } from 'react-responsive';
import Typography from '@material-ui/core/Typography';
import { Container } from '../../styles/GlobalStyles';
import { Button, ButtonAjust, Icon, CollaboratorContainer } from './styled';
import axios from '../../services/axios';

import ConfirmModal from '../../components/Delete';
import Loading from '../../components/Loading';
import NewCourse from '../NewCourse';
import CourseManager from '../CourseManager';
import NewSubmodule from '../NewSubmodule';

export default function Course() {
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [openCourse, setOpenCourse] = useState(false);
  const handleOpenCourse = (id) => {
    setSelectedCourseId(id);
    setOpenCourse(true);
  };
  const handleCloseCourse = () => setOpenCourse(false);
  const handleClose = () => setOpen(false);

  const [openNewSubmodule, SetOpenNewSubmodule] = useState(false);
  const handleOpenNewSubmodule = (e, id) => {
    if (!selectedCourseId) {
      setSelectedCourseId(id);
    }
    SetOpenNewSubmodule(true);
  };
  const handleCloseNewSubmodule = () => SetOpenNewSubmodule(false);

  const [courses, setCourse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/course');
        const course = response.data;
        setCourse(course);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
    if (localStorage.getItem('deleted')) {
      toast.success('Item excluído com sucesso.');
      localStorage.removeItem('deleted');
    }
    getData();
  }, []);

  const handleDelete = async (e, id, index) => {
    e.persist();

    setConfirmAction(() => async () => {
      try {
        setIsLoading(true);
        await axios.delete(`/course/${id}`);
        const novosCursos = [...courses];
        novosCursos.splice(index, 1);
        setCourse(novosCursos);

        setIsLoading(false);
        localStorage.setItem('deleted', 'true');
        window.location.reload();
      } catch (err) {
        const status = get(err, 'response.status', 0);

        if (status === 401) {
          toast.error('Você precisa fazer login');
        }

        setIsLoading(false);
      }
    });
    setConfirmModalOpen(true);
  };

  const handleDeleteSubmodule = async (e, id) => {
    e.persist();

    setConfirmAction(() => async () => {
      try {
        setIsLoading(true);
        await axios.delete(`/submodule/${id}`);
        setIsLoading(false);
        localStorage.setItem('deleted', 'true');
        window.location.reload();
      } catch (err) {
        const status = get(err, 'response.status', 0);

        if (status === 401) {
          toast.error('Você precisa fazer login');
        } else {
          toast.error('Ocorreu um erro ao excluir o submódulo');
        }

        setIsLoading(false);
      }
    });
    setConfirmModalOpen(true);
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1> Conteúdos existentes</h1>
        <h4>Inclua novos conteúdos e módulos</h4>
        {courses.map((course, index) => (
          <div key={String(course.id)}>
            <Accordion className="acordeon">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h6" style={{ fontFamily: 'MyFont', textDecoration: 'none', flex: '1' }}>
                    {course.name}
                  </Typography>
                  <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <ButtonAjust
                      className="my-special-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenCourse(course.id);
                      }}
                      aria-label={`Configurar curso ${course.name}`}
                    >
                      <FaCog color="black" size={isMobile ? 10 : 16} style={{ fontFamily: 'MyFont' }} />
                    </ButtonAjust>
                    {selectedCourseId && openCourse && (
                      <Modal
                        open={openCourse}
                        onClose={handleClose}
                        BackdropProps={{
                          style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <CourseManager id={selectedCourseId} handleClose={handleCloseCourse} />
                      </Modal>
                    )}
                    <Button
                      className="my-special-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(e, course.id, index);
                      }}
                      aria-label={`Excluir curso ${course.name}`}
                      style={{ marginLeft: '10px' }}
                    >
                      <Icon
                        viewBox="0 0 15 17.5"
                        height="17.5"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ fontFamily: 'MyFont' }}
                      >
                        <path
                          transform="translate(-2.5 -1.25)"
                          d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                        />
                      </Icon>
                    </Button>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table style={{ fontFamily: 'MyFont' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontFamily: 'MyFont' }} />
                        <TableCell style={{ fontFamily: 'MyFont' }}>Nome do Módulo</TableCell>
                        <TableCell style={{ fontFamily: 'MyFont' }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {course.Submodules.map((submodule, submoduleIndex) => (
                        <TableRow key={submodule.id}>
                          <TableCell style={{ fontFamily: 'MyFont' }}>{submoduleIndex + 1}</TableCell>
                          <TableCell style={{ fontFamily: 'MyFont' }}>{submodule.name}</TableCell>
                          <TableCell>
                            <Link to={`/submoduleManager/${submodule.id}/edit`}>
                              <ButtonAjust
                                className="my-special-button"
                                aria-label={`Editar submódulo ${submodule.name}`}
                              >
                                <FaCog
                                  style={{ marginLeft: '10px', fontFamily: 'MyFont' }}
                                  color="black"
                                  size={isMobile ? 10 : 16}
                                />
                              </ButtonAjust>
                            </Link>
                            <Button
                              className="my-special-button"
                              onClick={(e) => handleDeleteSubmodule(e, submodule.id)}
                              aria-label={`Excluir submódulo ${submodule.name}`}
                              style={{ marginLeft: '10px' }}
                            >
                              <Icon
                                viewBox="0 0 15 17.5"
                                height="17.5"
                                width="15"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ fontFamily: 'MyFont' }}
                              >
                                <path
                                  transform="translate(-2.5 -1.25)"
                                  d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                                />
                              </Icon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan="3">
                          <button
                            type="button"
                            onClick={(e) => handleOpenNewSubmodule(e, course.id)}
                            className="submodulo"
                          >
                            <h1>Adicionar Módulo</h1>
                            <Modal
                              open={openNewSubmodule}
                              onClose={handleCloseNewSubmodule}
                              BackdropProps={{
                                style: {
                                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                },
                              }}
                            >
                              {handleCloseNewSubmodule && (
                                <NewSubmodule id={selectedCourseId} handleClose={handleCloseNewSubmodule} />
                              )}
                            </Modal>
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        <button type="button" onClick={handleOpen} style={{ marginTop: '10px' }}>
          Novo Conteúdo
        </button>
        <Modal open={open} onClose={handleClose}>
          {handleClose && <NewCourse handleClose={handleClose} />}
        </Modal>
      </CollaboratorContainer>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        message="Tem certeza de que deseja excluir este item?"
        onConfirm={async () => {
          await confirmAction();
          setConfirmModalOpen(false);
        }}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </Container>
  );
}

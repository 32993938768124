import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FaCog } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextField } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import axios from '../../services/axios';
import { Container } from '../../styles/GlobalStyles';
import { Button, ButtonAjust, Icon, CollaboratorContainer, HeaderContainer, Checkbox } from './styled';
import ConfirmModal from '../../components/Delete';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

import NewQuiz from '../NewQuiz';
import NewContent from '../NewContent';
import AjustQuiz from '../QuizManager';
import AjustContent from '../ContentManager';

const StyledAccordion = styled(Accordion)`
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  &.Mui-expanded {
    max-height: 300px;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  max-height: 200px;
  overflow-y: auto;
`;

export default function SubmoduleEdit({ match, history }) {
  const dispatch = useDispatch();

  const [openAjustQuiz, setOpenAjustQuiz] = useState(false);
  const [openAjustContent, setOpenAjustContent] = useState(false);

  const [editingQuizId, setEditingQuizId] = useState(null);
  const [editingContentId, setEditingContentId] = useState(null);

  const [openNewQuiz, setOpenNewQuiz] = useState(false);
  const [openNewContent, setOpenNewContent] = useState(false);

  const handleOpenNewQuiz = () => setOpenNewQuiz(true);
  const handleCloseNewQuiz = () => setOpenNewQuiz(false);

  const handleOpenNewContent = () => setOpenNewContent(true);
  const handleCloseNewContent = () => setOpenNewContent(false);

  const handleCloseAjustQuiz = () => setOpenAjustQuiz(false);
  const handleCloseAjustContent = () => setOpenAjustContent(false);

  const handleOpenAjustQuiz = (quizId) => {
    setEditingQuizId(quizId);
    setOpenAjustQuiz(true);
  };

  const handleOpenAjustContent = (contentId) => {
    setEditingContentId(contentId);
    setOpenAjustContent(true);
  };

  const id = get(match, 'params.id', '');
  const [submodule, setSubmodule] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isQuizExpanded, setIsQuizExpanded] = useState(false);
  const [isContentSubmodulesExpanded, setIsContentSubmodulesExpanded] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isQuizChecked, setIsQuizChecked] = useState(false);
  const [isContentSubmodulesChecked, setIsContentSubmodulesChecked] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const handleQuizExpand = () => {
    setIsQuizChecked(!isQuizChecked);
    setIsQuizExpanded(!isQuizExpanded);
  };
  const handleContentSubmodulesExpand = () => {
    setIsContentSubmodulesChecked(!isContentSubmodulesChecked);
    setIsContentSubmodulesExpanded(!isContentSubmodulesExpanded);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deleted = urlParams.get('deleted');

    if (deleted) {
      toast.success('Item excluido com sucesso');
    }
  }, []);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/submodule/${id}`);
        setName(data.name);
        setDescription(data.description);
        setSubmodule(data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.forEach((error) => toast.error(error));
        history.push('/home-admin');
      }
    }

    getData();
  }, [id, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    let formErrors = false;

    if (name.length > 0 && (name.length < 3 || name.length > 255)) {
      toast.error('Nome precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (formErrors) return;

    try {
      setIsLoading(true);

      dispatch(
        actions.editSubmoduleRequest({
          history,
          id,
          name,
          description,
        }),
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.forEach((error) => toast.error(error));
      } else {
        toast.error('Erro desconhecido');
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
  };

  const handleDelete = async (e, quizID) => {
    e.persist();
    setConfirmAction(() => async () => {
      try {
        setIsLoading(true);
        await axios.delete(`/quiz/${quizID}`);
        setIsLoading(false);
      } catch (err) {
        const status = get(err, 'response.status', 0);

        if (status === 401) {
          toast.error('Você precisa fazer login');
        } else {
          toast.error('Ocorreu um erro ao excluir quiz');
        }

        setIsLoading(false);
      }
    });
    setConfirmModalOpen(true);
  };

  const handleDeleteContent = async (e, contentID) => {
    e.persist();
    setConfirmAction(() => async () => {
      try {
        setIsLoading(true);
        await axios.delete(`/contentsubmodule/${contentID}`);
        setIsLoading(false);
      } catch (err) {
        const status = get(err, 'response.status', 0);

        if (status === 401) {
          toast.error('Você precisa fazer login');
        } else {
          toast.error('Ocorreu um erro ao excluir conteúdo');
        }

        setIsLoading(false);
      }
    });
    setConfirmModalOpen(true);
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <form onSubmit={handleSubmit}>
          <h1>Editar Módulo</h1>
          <h4>Atualize informações de módulos</h4>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Nome do Modulo"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <button type="submit">Salvar</button>
          <StyledAccordion expanded={isQuizExpanded} onChange={handleQuizExpand}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="quiz-content" id="quiz-header">
              <HeaderContainer>
                <h1>Avaliação</h1>
                <Checkbox id="quizCheckbox" checked={isQuizChecked} onChange={handleQuizExpand} />
              </HeaderContainer>
            </AccordionSummary>
            <StyledAccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontFamily: 'MyFont' }}>#</TableCell>
                      <TableCell style={{ fontFamily: 'MyFont' }}>Nome da Avaliação</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submodule.Quizzes && submodule.Quizzes.length > 0 ? (
                      submodule.Quizzes.map((quiz, quizIndex) => (
                        <TableRow key={quiz.id}>
                          <TableCell style={{ fontFamily: 'MyFont' }}>{quizIndex + 1}</TableCell>
                          <TableCell style={{ fontFamily: 'MyFont' }}>Avaliação</TableCell>
                          <TableCell>
                            <ButtonAjust className="my-special-button" onClick={() => handleOpenAjustQuiz(quiz.id)}>
                              <FaCog className="ajust" color="black" size={isMobile ? 10 : 16} />
                            </ButtonAjust>
                            {openAjustQuiz && editingQuizId !== null && (
                              <Modal open={openAjustQuiz} onClose={handleCloseAjustQuiz}>
                                {openAjustQuiz && <AjustQuiz id={editingQuizId} handleClose={handleCloseAjustQuiz} />}
                              </Modal>
                            )}
                            <Button className="my-special-button" onClick={(e) => handleDelete(e, quiz.id)}>
                              <Icon viewBox="0 0 15 17.5" height="17.5" width="15" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  transform="translate(-2.5 -1.25)"
                                  d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                                  id="Fill"
                                />
                              </Icon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <button className="add-quiz" type="button" onClick={handleOpenNewQuiz}>
                            Adicionar Avaliação
                          </button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal open={openNewQuiz} onClose={handleCloseNewQuiz}>
                {handleCloseNewQuiz && <NewQuiz handleClose={handleCloseNewQuiz} />}
              </Modal>
            </StyledAccordionDetails>
          </StyledAccordion>

          <StyledAccordion expanded={isContentSubmodulesExpanded} onChange={handleContentSubmodulesExpand}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="content-submodules-content"
              id="content-submodules-header"
            >
              <HeaderContainer>
                <h1>Conteúdos</h1>
                <Checkbox
                  id="contentSubmodulesCheckbox"
                  checked={isContentSubmodulesChecked}
                  onChange={handleContentSubmodulesExpand}
                />
              </HeaderContainer>
            </AccordionSummary>
            <StyledAccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontFamily: 'MyFont' }}>#</TableCell>
                      <TableCell style={{ fontFamily: 'MyFont' }}>Nome do Conteúdo</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {submodule.ContentSubmodules /* eslint-disable prettier/prettier */ &&
                    submodule.ContentSubmodules.length > 0
                      ? submodule.ContentSubmodules.map((contentSubmodule, contentIndex) => (
                          <TableRow key={contentSubmodule.id || Math.random()}>
                            <TableCell style={{ fontFamily: 'MyFont' }}>{contentIndex + 1}</TableCell>
                            <TableCell style={{ fontFamily: 'MyFont' }}>
                              {contentSubmodule.name || 'Nome não disponível'}
                            </TableCell>
                            <TableCell>
                              <ButtonAjust
                                className="my-special-button"
                                onClick={() => handleOpenAjustContent(contentSubmodule.id)}
                              >
                                <FaCog
                                  style={{ marginRight: '10px' }}
                                  className="ajust"
                                  color="black"
                                  size={isMobile ? 10 : 16}
                                />
                              </ButtonAjust>
                              {openAjustContent && editingContentId !== null && (
                                <Modal open={openAjustContent} onClose={handleCloseAjustContent}>
                                  {openAjustContent && (
                                    <AjustContent id={editingContentId} handleClose={handleCloseAjustContent} />
                                  )}
                                </Modal>
                              )}
                              <Button
                                className="my-special-button"
                                onClick={(e) => handleDeleteContent(e, contentSubmodule.id)}
                              >
                                <Icon viewBox="0 0 15 17.5" height="17.5" width="15" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    transform="translate(-2.5 -1.25)"
                                    d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                                    id="Fill"
                                  />
                                </Icon>
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <button className="add-content" type="button" onClick={handleOpenNewContent}>
                          Adicionar conteúdo
                        </button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Modal open={openNewContent} onClose={handleCloseNewContent}>
                {handleCloseNewContent && <NewContent handleClose={handleCloseNewContent} />}
              </Modal>
            </StyledAccordionDetails>
          </StyledAccordion>
        </form>
      </CollaboratorContainer>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        message="Tem certeza de que deseja excluir este item?"
        onConfirm={async () => {
          await confirmAction();
          setConfirmModalOpen(false);
          window.location.href = `${window.location.pathname}?deleted=true`;
        }}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </Container>
  );
}

SubmoduleEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

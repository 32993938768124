import React, { useState, useEffect, useRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { TextField } from '@material-ui/core';
import axios from '../../services/axios';
import { Form, CoursePicture, ImageContainer, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function CourseEdit(props) {
  const dispatch = useDispatch();

  const { id } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [picture, setPicture] = useState('');
  const [preview, setPreview] = useState('');
  const [image, setImage] = useState(null);
  const fileInputRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/course/${id}`);
        setName(data.name);
        setDescription(data.description);

        // Directly use the image URL
        setPicture(data.img);
        setFileName(data.Pictures[0]?.filename || '');
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
      }
    }

    getData();
  }, [id]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleSubmit = async (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    let formErrors = false;

    if (name.length > 0 && (name.length < 3 || name.length > 255)) {
      toast.error('Nome precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (formErrors) return;
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      if (image) {
        formData.append('img', image);
      }

      dispatch(
        actions.editCourseRequest({
          id,
          formData,
        }),
      );
      handleClose();

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      } else {
        toast.error('Erro desconhecido');
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setImage(file); // Atualiza o estado da imagem com o arquivo selecionado

    // Gera uma URL local para a imagem selecionada
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL); // Atualiza o estado `preview` com a URL local
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Editar curso</h1>
          <h4>Atualize informações de curso</h4>
          <ImageContainer>
            <CoursePicture>
              {preview ? <img src={preview} alt={name} /> : picture !== '' && <img src={picture} alt={name} />}
            </CoursePicture>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                fileInputRef.current.click();
              }}
            >
              Alterar imagem
            </button>
          </ImageContainer>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Descrição"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true }}
            label="Titulo do curso"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button type="submit">Salvar</button>
        </div>
      </Form>
    </Bloco>
  );
}

CourseEdit.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FaUserPlus, FaUserCog, FaUsers, FaAddressBook, FaKey } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';

import { Container } from '../../styles/GlobalStyles';
import { Row, NewUser, AttUser, CollaboratorContainer, BlockUser } from './styled'; // Remova LowerItem da importação

import Register from '../Register';
import Token from '../TokenEnterprise';

export default function User() {
  const [openRegister, setOpenRegister] = useState(false); // Estado para controlar a visibilidade do modal Admin
  const [openToken, setOpenToken] = useState(false); // Estado para controlar a visibilidade do modal Token

  const handleOpenRegister = () => setOpenRegister(true);
  const handleCloseRegister = () => setOpenRegister(false);

  const handleOpenToken = () => setOpenToken(true);
  const handleCloseToken = () => {
    const tokenMessage = localStorage.getItem('tokenMessage');
    if (tokenMessage) {
      toast.success(tokenMessage);
      localStorage.removeItem('tokenMessage');
    }
    setOpenToken(false);
  };

  return (
    <Container>
      <CollaboratorContainer>
        <Row>
          <div className="collabs">
            <NewUser to="register" onClick={handleOpenRegister}>
              <FaUserPlus className="test" />
              <h1>Criar Conta</h1>
            </NewUser>
            <Modal open={openRegister} onClose={handleCloseRegister}>
              {handleCloseRegister && <Register handleClose={handleCloseRegister} />}
            </Modal>
          </div>
          <div className="token">
            <NewUser to="register" onClick={handleOpenToken}>
              <FaKey className="test" />
              <h1>Token</h1>
            </NewUser>
            <Modal open={openToken} onClose={handleCloseToken}>
              {handleCloseToken && <Token handleClose={handleCloseToken} />}
            </Modal>
          </div>
          <div className="edite">
            <Link className="link" to="/empresa-painel">
              <BlockUser>
                <FaUserCog className="test" />
                <h1>Editar Usuários</h1>
              </BlockUser>
            </Link>
          </div>
        </Row>
        <Row>
          <div className="user">
            <Link className="link" to="/sectors">
              <AttUser>
                <FaUsers className="test" />
                <h1>Setores</h1>
              </AttUser>
            </Link>
          </div>
          <div className="att">
            <Link className="link" to="/funcoes">
              <AttUser>
                <FaAddressBook className="test" />
                <h1>Funções</h1>
              </AttUser>
            </Link>
          </div>
        </Row>
      </CollaboratorContainer>
    </Container>
  );
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserEdit, FaUserSlash, FaAddressCard, FaUserCog, FaUserPlus } from 'react-icons/fa';
import Modal from '@mui/material/Modal';
import { Container } from '../../styles/GlobalStyles';
import { Row, NewUser, AttUser, CollaboratorContainer, Edit, BlockUser } from './styled';
import EditUser from '../EditUser';
import Admin from '../admin';

export default function User() {
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);

  const handleOpenEditUser = () => setOpenEditUser(true);
  const handleCloseEditUser = () => setOpenEditUser(false);

  const handleOpenAdmin = () => setOpenAdmin(true);
  const handleCloseAdmin = () => setOpenAdmin(false);

  return (
    <Container>
      <CollaboratorContainer>
        <Row>
          <div className="edite">
            <Edit onClick={handleOpenEditUser}>
              <FaUserCog className="Svg" />
              <h1>Editar Perfil</h1>
            </Edit>
            <Modal open={openEditUser} onClose={handleCloseEditUser}>
              {handleCloseEditUser && <EditUser handleClose={handleCloseEditUser} />}
            </Modal>
          </div>
          <div className="collabs">
            <NewUser onClick={handleOpenAdmin}>
              <FaUserPlus className="Svg" />
              <h1>Criar Conta</h1>
            </NewUser>
            <Modal open={openAdmin} onClose={handleCloseAdmin}>
              {handleCloseAdmin && <Admin handleClose={handleCloseAdmin} />}
            </Modal>
          </div>
        </Row>
        <Row>
          <div className="edite">
            <Link className="link" to="/admin-painel">
              <BlockUser>
                <FaUserEdit className="Svg" />
                <h1>Editar Usuário</h1>
              </BlockUser>
            </Link>
          </div>
          <div className="user">
            <Link className="link" to="/course">
              <AttUser>
                <FaAddressCard className="Svg" />
                <h1>Conteúdo</h1>
              </AttUser>
            </Link>
          </div>
          <div className="att">
            <Link className="link" to="/course-permission">
              <AttUser>
                <FaUserSlash className="Svg" />
                <h1>Permissões</h1>
              </AttUser>
            </Link>
          </div>
        </Row>
      </CollaboratorContainer>
    </Container>
  );
}

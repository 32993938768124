import styled from 'styled-components';

export const Containered = styled.div`
  h1 {
    margin: 50% -50%;
    margin-bottom: 5%;
    text-align: center;
  }
  .container {
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;

    button {
      margin-left: -10px !important;
      margin-top: 20px !important;
      width: 250px !important;
      color: white !important;
      font-weight: bold !important;
      background-color: #ae1100 !important;
      font-family: 'MyFont' !important;
      z-index: 1000 !important;
      text-transform: none !important;
      &:hover {
        background-color: #ae1100 !important;
        transition-duration: 0s !important;
      }
    }

    .add-icon {
      width: 50px;
      height: 50px;
      margin: 0% 35%;
    }

    input {
      display: none;
    }

    .img-container {
      margin-top: 30px;
      border-radius: 50%;
      border: 3px solid black;

      img {
        display: block;
        border-radius: 50%;
      }
    }
  }

  footer {
    margin-top: 100px;

    a {
      color: black;
      text-decoration: none;
    }
  }
  small {
    text-align: center;
    justify-self: center;
    margin-left: 5%;
  }
`;

import styled from 'styled-components';

export const Popup = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .Button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    min-width: 580px;
    margin-top: 7%;
    min-height: 400px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
  }

  .box-container h1 {
    padding: 0px 5px;
    background-color: white;
    margin-bottom: 20px;
  }

  .user-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .user-item:hover {
    background-color: #f0f0f0;
  }

  .span {
    margin-right: auto;
  }

  button {
    background-color: #ae1100;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: auto;
  }
`;

export const CollaboratorContainer = styled.div`
  display: block;
  text-align: center;
  align-items: flex-end;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-inline: 0%;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  @media (min-width: 40px) {
    grid-gap: 10px;
  }
  @media (min-width: 1024px) {
    grid-gap: 80px;
  }

  @media (max-width: 790px) {
    flex-direction: column;
    display: block;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: 50px;
  cursor: pointer;
  padding: 10px;

  @media (min-width: 40px) {
    grid-gap: 20px;
    max-height: 100%;
  }
  @media (min-width: 1024px) {
    grid-gap: 80px;
  }

  @media (max-width: 790px) {
    flex-direction: column;
  }
  .link {
    text-decoration: none;
    color: inherit;
  }
`;

const CommonStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 200px;
  border-radius: 20px;
  max-width: 30vh;
  height: 200px;
  background: white;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    flex-direction: column;
    display: block;
    height: 100px;
    width: 100px;
  }

  h1 {
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
    }

    text-align: center;
  }

  .Svg {
    width: 25vw;
    height: 2%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;
    margin: 3%;
  }

  &:hover {
    transition: all 0.3s ease;
    transform: scale(1.05);
    color: #ae1100;
    border-radius: 20px;
    box-shadow: 1px 1px 7px #ae1100, 1px 2px 8px #ae1100;
  }
`;

export const NewUser = styled(CommonStyles)``;
export const AttUser = styled(CommonStyles)``;
export const Edit = styled(CommonStyles)``;
export const BlockUser = styled(CommonStyles)``;
export const EditUser = styled(CommonStyles)``;

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { toast } from 'react-toastify';
import PasswordChecklist from 'react-password-checklist';
import { Button, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from '../../services/axios';
import * as actions from '../../store/modules/auth/actions';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei (1).svg';

import { Form, Composition, Container } from './styled';
import Footer from '../../components/Footer';

function PasswordRecoveryPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';
  const history = useHistory();
  const [isTokenValid, setIsTokenValid] = useState(null);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    async function checkTokenValidity() {
      try {
        const response = await axios.get(`/rhusers/check-token?token=${token}`);
        console.log(response);
        if (response.data.isValid) {
          setIsTokenValid(true);
        }
        // if (!token) {
        //   setIsTokenValid(false);
        // }
        else {
          setIsTokenValid(false);
        }
      } catch (error) {
        console.error('Erro ao verificar a validade do token:', error);
        setIsTokenValid(false);
      }
    }

    checkTokenValidity();
  }, [token]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordValidationChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isPasswordValid) {
      toast.error('A senha não cumpre todos os requisitos');
      return;
    }

    dispatch(actions.passwordRecoveryRequest({ history, token, newPassword }));
  };

  return (
    <Container>
      <Composition>
        <Logo className="logo" />
        <h1>Redefinição de Senha</h1>
        {isTokenValid === false ? (
          <p>Link inválido. Por favor, solicite uma nova redefinição.</p>
        ) : (
          <Form onSubmit={handleSubmit}>
            <TextField
              InputLabelProps={{
                className: 'input-label',
              }}
              className="text-field"
              variant="outlined"
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={handleNewPasswordChange}
              label="Nova Senha"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="button-eye"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className="text-field"
              InputLabelProps={{
                className: 'input-label',
              }}
              variant="outlined"
              id="standard-adornment-password-again"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              label="Confirmar Senha"
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="button-eye"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <PasswordChecklist
              className="password-checklist"
              rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
              minLength={8}
              value={newPassword}
              valueAgain={confirmPassword}
              onChange={handlePasswordValidationChange}
              messages={{
                minLength: 'A senha deve ter no mínimo 8 caracteres.',
                specialChar: 'A senha deve conter pelo menos un caracteres especiais(Ex: !, @, #, etc.).',
                number: 'A senha deve conter pelo menos um número.',
                capital: 'A senha deve conter pelo menos uma letra maiúscula.',
                match: 'As senhas devem coincidir.',
              }}
            />

            <Button type="submit" variant="contained" color="primary" fullWidth>
              Redefinir Senha
            </Button>
          </Form>
        )}
      </Composition>
      <Footer />
    </Container>
  );
}

export default PasswordRecoveryPage;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { TextField, Button } from '@material-ui/core';
import { Form, Composition, Bloco, TextareaAutosizeStyled } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterCourse(props) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const history = useHistory();

  const [formStep, setFormStep] = useState(1);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // armazena o arquivo selecionado no estado
  };

  const handleNomeChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    const { handleClose } = props;
    e.preventDefault();

    // Adicione a lógica de validação com base na etapa atual (formStep)
    let formErrors = false;
    switch (formStep) {
      case 1:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        if (!description || description.length < 3 || description.length > 10000) {
          formErrors = true;
          toast.error('Descrição deve ter entre 3 e 200 caracteres');
        }
        break;
      case 2:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        if (!description || description.length < 3 || description.length > 10000) {
          formErrors = true;
          toast.error('Descrição deve ter entre 3 e 200 caracteres');
        }
        break;

      default:
        break;
    }

    if (formErrors) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('img', image);

    dispatch(
      actions.registerCourseRequest({
        formData, // envia o formData em vez do objeto literal
        history,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Criar novo curso</h1>
          <h4>Inclua um novo curso no sistema</h4>
          {formStep === 1 && (
            <>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={handleNomeChange}
                placeholder="Nome do Curso"
                fullWidth
                margin="normal"
              />
              <TextareaAutosizeStyled
                className="Textarea"
                InputProps={{ disableUnderline: true }}
                label="Descrição"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Descrição do curso"
                fullWidth
                margin="normal"
              />
              <input type="file" onChange={handleImageChange} />
              <button type="button" className="button" onClick={handleNextStep}>
                Próximo
              </button>
            </>
          )}

          {formStep === 2 && (
            <Composition>
              <h2>Confirme conteúdo</h2>

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
                disabled
                margin="normal"
              />
              <TextareaAutosizeStyled
                className="Textarea"
                InputProps={{ disableUnderline: true }}
                label="Descrição"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Descrição"
                disabled
                margin="normal"
              />

              <Button type="button" onClick={handlePrevStep} variant="contained">
                Voltar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Finalizar
              </Button>
            </Composition>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

RegisterCourse.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';
import MyFont from '../../fonts/eurosoft.ttf';

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
    font-family: 'MyFont';
  }
  .acordeon {
    margin-bottom: 1%;
    font-family: 'MyFont';
  }
  width: 100%;
  max-width: 65%;
  padding: 10px; /* Reduzindo o preenchimento */
  border-radius: 8px;
  margin-top: 2%; /* Ajustando a margem superior */
  h1 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 1%;
  }
  h4 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 3%;
  }
  form {
    width: 100%;
  }
  button:not(.my-special-button, .submodulo) {
    margin-bottom: 3%;
    margin-top: 8%;
    width: 150px;
    height: 40px;
    color: white;
    background-color: #ae1100;
    border-radius: 4%;
    padding: 0; /* Ensure padding doesn't affect the shape */
    text-align: center; /* Center the text */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }

  button.submodulo {
    font-size: 10px;
    margin-bottom: 3%;
    margin-top: 8%;
    width: 180px;
    height: 40px;
    color: white;
    background-color: #ae1100;
    border-radius: 4%;
    padding: 0;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center; /* Add this line to vertically center the text */
  }

  p {
    font-family: 'MyFont';
    color: black;
    text-decoration: underline;
    text-align: center;
  }

  margin-top: 5%;
`;

export const HeaderContainer = styled.div`
  width: 80%;
  max-width: 900px;
  margin: 0 auto; /* Centralizando na página */
  padding: 10px; /* Reduzindo o preenchimento */
  border-radius: 8px;
  margin-bottom: 2%; /* Ajustando a margem inferior */
`;

export const ExpandedContainer = styled.div`
  width: 90%;
  max-width: 900px;
  margin: 0 auto; /* Centralizando na página */
  padding: 10px; /* Reduzindo o preenchimento */
  border-radius: 8px;
  margin-bottom: 7%; /* Ajustando a margem inferior */
  overflow-y: auto;
  .add-content {
    width: 50px;
    height: 50px;
    font-size: 10px;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const Toggle = styled.label`
  margin-top: 0.37%;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 3px;
  transition-duration: 0.5s;
  transform: ${(props) => (props.checked ? 'rotate(180deg)' : 'none')};
`;

export const Button = styled.button`
  background-color: transparent;
  position: relative;
  border: none;

  &:hover > svg {
    transform: scale(1.5);
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
    top: -50%;
  }

  &::after {
    content: 'delete';
    position: absolute;
    top: -20%;
    transform: translateX(-50%);
    width: fit-content;
    height: fit-content;
    background-color: rgb(168, 7, 7);
    border-radius: 5px;
    transition: 0.2s linear;
    transition-delay: 0.2s;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
  }
`;

export const Icon = styled.svg`
  display: inline-block; /* Torna o ícone um bloco de nível inline */
  vertical-align: middle; /* Alinha o ícone verticalmente ao centro */
  transform: scale(1.2);
  transition: 0.2s linear;

  & path {
    transition: 0.2s linear;
  }

  &:hover path {
    fill: rgb(168, 7, 7);
  }
`;
export const StyledIcon = styled.svg`
  display: inline-block; /* Torna o ícone um bloco de nível inline */
  vertical-align: middle; /* Alinha o ícone verticalmente ao centro */
  transform: scale(1.2);
  transition: 0.2s linear;

  & path {
    transition: 0.2s linear;
  }

  &:hover path {
    fill: rgb(168, 7, 7);
  }
`;
export const ButtonAjust = styled.button`
  margin-top: 0%; /* Ajustando a margem superior */
  background-color: transparent;
  position: relative;
  border: none;

  &:hover > svg {
    transform: scale(1.5);
  }

  &:hover::after {
    visibility: visible;
    opacity: 1;
    top: -50%;
  }

  &::after {
    content: 'Ajustar';
    position: absolute;
    top: -20%;
    transform: translateX(-50%);
    width: fit-content;
    height: fit-content;
    background-color: grey;
    border-radius: 5px;
    transition: 0.2s linear;
    transition-delay: 0.2s;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
  }
  .ajust {
    transform: scale(1.2);
    transition: 0.2s linear;

    & path {
      transition: 0.2s linear;
    }

    &:hover path {
      fill: grey;
    }
  }
`;

export const AjustIcon = styled(FaCog)`
  display: inline-block; /* Torna o ícone um bloco de nível inline */
  vertical-align: middle; /* Alinha o ícone verticalmente ao centro */
  position: relative;
  color: black;
  font-size: ${(props) => (props.isMobile ? '10px' : '16px')};
`;

export const ListItem = styled.li`
  margin: 0 auto;
  font-family: 'MyFont'; /* Centralizando na página */
`;

export const CoursePicture = styled.div`
  width: 10vh;
  height: 10vh;
  margin: 0 auto; /* Centralizando na página */

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0px -50px;
  }
`;

export const CourseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-family: 'MyFont';
  margin: 0 auto; /* Centralizando na página */
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { get } from 'lodash';
import { format, isPast } from 'date-fns';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';
import axios from '../../services/axios';
import UserPopup from '../../components/Popup';
import Loading from '../../components/Loading';

export default function Course({ match }) {
  const id = get(match, 'params.id', '');
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const userId = useSelector((state) => state.auth.user.id);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [accessWithUserNames, setAccessWithUserNames] = useState([]);

  useEffect(() => {
    if (!Array.isArray(users)) return;

    const newAccess = access
      .filter((acesso) => acesso.userId !== userId)
      .map((acesso) => {
        const foundUser = users.find((user) => user.id === acesso.userId);
        return {
          ...acesso,
          userName: foundUser ? `${foundUser.nome} ${foundUser.sobrenome}` : '',
        };
      });
    setAccessWithUserNames(newAccess);
  }, [access, users, userId]);

  async function fetchData() {
    setIsLoading(true);
    try {
      const response = await axios.get(`/usercourse`);
      const userCourses = response.data.filter(
        (userCourse) => userCourse.courseId && userCourse.courseId === Number(id),
      );
      // Verifica se a data de expiração passou e ajusta o acesso
      const updatedAccess = await Promise.all(
        userCourses.map(async (userCourse) => {
          // Verifica se a data de expiração é válida e se já passou
          if (userCourse.accessExpirationDate && isPast(new Date(userCourse.accessExpirationDate))) {
            // Atualiza o acesso se a data de expiração passou
            await axios.put(`/usercourse/${userCourse.id}`, { hasAccess: false });
            return { ...userCourse, hasAccess: false };
          }
          // Mantém o acesso como está se a data for null ou não tiver passado
          return userCourse;
        }),
      );
      setAccess(updatedAccess);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/rhusers');
        const empresaUsers = response.data.filter(
          (user) =>
            user.departamento &&
            user.departamento.toLowerCase() !== 'empresa' &&
            user.departamento.toLowerCase() !== 'admin' &&
            user.cnpj_enterprise === cnpj,
        );
        setUsers(empresaUsers);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  const handleToggleAccess = async (userCourseId, hasAccess) => {
    try {
      const response = await axios.get(`/usercourse/${userCourseId}`);
      if (response.status === 200) {
        await axios.put(`/usercourse/${userCourseId}`, { hasAccess: !hasAccess });
        fetchData();
      } else {
        console.error(`Curso de usuário com id ${userCourseId} não encontrado`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = async (userCourseId, newDate) => {
    try {
      const response = await axios.put(`/usercourse/${userCourseId}`, { accessExpirationDate: newDate });
      if (response.status === 200) {
        fetchData();
      } else {
        console.error(`Curso de usuário com id ${userCourseId} não encontrado`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        <h1>Permissões</h1>
        <h4>Selecione colaborador para ter acesso ao cursos</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuário</TableCell>
              <TableCell className="expiration-date-cell">Data de Expiração do Acesso</TableCell>
              <TableCell className="TableCell" style={{ paddingLeft: '10%' }}>
                Tem Acesso?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessWithUserNames
              .filter((acesso) => acesso.userName) // Filtra os acessos que têm um userName
              .map((acesso) => (
                <TableRow key={acesso.id}>
                  <TableCell>{acesso.userName}</TableCell>
                  <TableCell className="expiration-date-cell">
                    <input
                      type="date"
                      value={
                        acesso.accessExpirationDate ? format(new Date(acesso.accessExpirationDate), 'yyyy-MM-dd') : ''
                      }
                      onChange={(e) => handleDateChange(acesso.id, e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={acesso.hasAccess}
                      onChange={() => handleToggleAccess(acesso.id, acesso.hasAccess)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isPopupOpen && (
          <UserPopup
            userList={users.filter((user) => !access.some((acesso) => acesso.userId === user.id))}
            courseId={id}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
        <div className="button-container">
          <button type="button" onClick={() => setIsPopupOpen(true)}>
            Criar novo acesso
          </button>
        </div>
      </CollaboratorContainer>
    </Container>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

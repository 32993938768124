import styled from 'styled-components';
import Card from '@mui/material/Card';
import breakpoints from '../../styles/breakpoints';

export const CollaboratorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5%;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-top: 0%;
  .link {
    text-decoration: none;
    color: inherit;
  }
  .h1 {
    margin-bottom: 5%;
    font-size: 20px; /* 1.6 vezes o tamanho da fonte base */
    font-weight: 900; /* Torna o texto bem grosso */
    color: #000;
  }
  span {
    font-weight: 600;
    color: #000;
    object-fit: contain;
    min-height: 50px;
    margin: 5px 0px;
    align-items: center;
    @media (min-width: 1440px) {
      font-size: 1;
    }
  }
  svg {
    width: 25vw;
    height: 80px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    min-height: 50px;

    @media (min-width: 1440px) {
      height: 120px;
    }
  }
  @media (min-width: 1440px) {
    grid-gap: 80px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (min-width: 1024px) {
    grid-gap: 80px; /* Aumentar o espaçamento em telas maiores */
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled(Card)`
  width: 310px; /* Diminuir a largura do card */
  height: 400px; /* Definir uma altura fixa para o card */
  margin: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuir o espaço verticalmente */

  @media ${breakpoints.md} {
    height: 90%;
    width: 80%;
  }

  @media ${breakpoints.mg} {
    height: 80%;
    width: 70%;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px #ae1100;
  }

  .MuiCardMedia-root {
    height: 150px;
  }

  img {
    width: 250%; /* Ajuste a largura da imagem */
    height: 250%;
    margin-bottom: 12%;
    z-index: 1;
    object-fit: contain; /* Para garantir que a imagem se ajuste sem cortar */
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: -18%;
    margin-bottom: 18px; /* Ajustar a margem inferior para separar do botão */
    overflow-y: auto; /* Adicionar rolagem vertical */
    max-height: 300px; /* Definir altura máxima para o conteúdo */

    /* Ocultar barras de rolagem no navegador Webkit */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    /* Ocultar barras de rolagem no navegador Firefox */
    scrollbar-width: none;
  }

  .MuiTypography-root {
    margin-top: 8px;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
  }

  .MuiCardActions-root {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    margin-top: auto; /* Empurra o botão para o final do card */
  }

  button {
    display: inline-block;
    width: 100%; /* Ajusta a largura do botão para preencher o card */
    padding: 10px;
    font-size: 13px;
    color: white;
    margin-top: -8%;
    background-color: #ae1100;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-family: 'MyFont';
    font-weight: bold;
    text-transform: none;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: #d43210; /* Cor de fundo ao passar o mouse */
    }
  }
  .h1 {
    margin-bottom: 5%;
    font-size: 15px;
    font-weight: 900px;
    font-weight: bold;
    color: #000;
    border-bottom: 1px solid #000; /* Linha abaixo do título */
    padding-bottom: 8px; /* Espaço entre o título e a linha */
  }
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { DivContainer } from './styled';
import { Container } from '../../styles/GlobalStyles';
import axios from '../../services/axios';
import Loading from '../../components/Loading';

function GridSection({ title, children }) {
  return (
    <Grid item xs={12} style={{ marginBottom: '24px' }}>
      <Typography variant="h4" component="h1" gutterBottom style={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {children}
      </Grid>
    </Grid>
  );
}

GridSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function Dashboard() {
  const id = useSelector((state) => state.auth.user.id);
  const cnpj = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [isLoading, setIsLoading] = useState(false);
  const [participationData, setParticipationData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [averageScoreData, setAverageScoreData] = useState([]);
  const [sectorDistributionData, setSectorDistributionData] = useState([]);
  const [averageScoreBySectorAndCourseData, setAverageScoreBySectorAndCourseData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        // Fetch courses
        const coursesResponse = await axios.get('/course');
        const coursesData = coursesResponse.data;

        // Fetch submodules
        const submodulesResponse = await axios.get('/submodule');
        const submodulesData = submodulesResponse.data;

        // Fetch user courses
        const userCoursesResponse = await axios.get('/usercourse');
        const userCoursesData = userCoursesResponse.data;

        // Fetch user submodule
        const userSubmoduleResponse = await axios.get('/usersubmodule');
        const userSubmoduleData = userSubmoduleResponse.data;

        // Fetch company users
        const usersResponse = await axios.get('/rhusers');
        const allUsers = usersResponse.data.filter(
          (user) =>
            user.departamento &&
            user.departamento.toLowerCase() !== 'empresa' &&
            user.departamento.toLowerCase() !== 'admin' &&
            user.cnpj_enterprise === cnpj,
        );

        // Create a set of userIds for filtering
        const userIds = new Set(allUsers.map((user) => user.id));

        // Calculate participation data and totalTest
        let totalTest = 0;

        const courseData = coursesData.map((course) => {
          const courseId = course.id;

          // Count participants
          const participants = allUsers.reduce((count, user) => {
            const userCourseCount = userCoursesData.filter(
              (userCourse) => userCourse.userId === user.id && userCourse.courseId === courseId,
            ).length;
            return count + userCourseCount;
          }, 0);

          // Add to the totalTest
          totalTest += participants;

          // Count concluded
          const concluded = userCoursesData.filter(
            (userCourse) =>
              userCourse.courseId === courseId &&
              userCourse.completionPercentage === 100 &&
              userIds.has(userCourse.userId),
          ).length;

          return {
            name: course.name,
            participantes: participants,
            concluidos: concluded,
          };
        });

        // Calculate total concluded
        const totalConcluded = userCoursesData.filter(
          (userCourse) => userCourse.completionPercentage === 100 && userIds.has(userCourse.userId),
        ).length;

        setPieData([
          { id: 'total', name: 'Total de Participações Não Concluidas', value: totalTest - totalConcluded },
          { id: 'concluded', name: 'Participações com 100% de Conclusão', value: totalConcluded },
        ]);

        setParticipationData(courseData);

        // Calculate average score per course
        const averageScores = coursesData.map((course) => {
          const courseId = course.id;

          // Filter submodules related to the current course
          const filteredSubmodules = userSubmoduleData.filter(
            (submodule) => userIds.has(submodule.userId) && submodule.submodule_id !== null,
          );

          // Find the average score for the current course
          const submoduleScores = filteredSubmodules
            .filter((submodule) =>
              submodulesData.find((sub) => sub.id === submodule.submodule_id && sub.courseId === courseId),
            )
            .map((submodule) => submodule.quizScore);

          const averageScore = submoduleScores.length
            ? submoduleScores.reduce((acc, score) => acc + score, 0) / submoduleScores.length
            : 0;

          return {
            name: course.name,
            averageScore: averageScore.toFixed(2),
          };
        });

        setAverageScoreData(averageScores);

        // Calculate data for sectors
        const sectorMap = new Map();

        allUsers.forEach((user) => {
          const setorName = user.setor ? user.setor : 'Usuários ainda sem setor'; // Substitua null por string padrão
          if (!sectorMap.has(setorName)) {
            sectorMap.set(setorName, { participantes: 0, concluídos: 0 });
          }
          const sectorData = sectorMap.get(setorName);
          sectorData.participantes += userCoursesData.filter((userCourse) => userCourse.userId === user.id).length;
          sectorData.concluídos += userCoursesData.filter(
            (userCourse) => userCourse.userId === user.id && userCourse.completionPercentage === 100,
          ).length;
          sectorMap.set(setorName, sectorData);
        });

        const sectorArray = Array.from(sectorMap, ([name, data]) => ({
          name,
          participantes: data.participantes,
          concluídos: data.concluídos,
        }));

        setSectorDistributionData(sectorArray);

        // Calculate average score by sector and course
        const sectorCourseScores = {};

        allUsers.forEach((user) => {
          const setorName = user.setor ? user.setor : 'Usuários ainda sem setor'; // Substitua null por string padrão

          userSubmoduleData.forEach((submodule) => {
            if (userIds.has(submodule.userId) && submodule.quizScore != null) {
              const course = submodulesData.find((s) => s.id === submodule.submodule_id)?.courseId;
              const courseName = coursesData.find((c) => c.id === course)?.name;

              if (courseName) {
                const key = `${setorName} - ${courseName}`;
                if (!sectorCourseScores[key]) {
                  sectorCourseScores[key] = { totalScore: 0, count: 0 };
                }
                sectorCourseScores[key].totalScore += submodule.quizScore;
                sectorCourseScores[key].count += 1;
              }
            }
          });
        });

        const averageScoresBySectorAndCourse = Object.keys(sectorCourseScores).map((key) => {
          const { totalScore, count } = sectorCourseScores[key];
          return {
            id: key, // Added unique ID here
            name: key,
            averageScore: count ? (totalScore / count).toFixed(2) : 0,
          };
        });

        setAverageScoreBySectorAndCourseData(averageScoresBySectorAndCourse);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }

    fetchData();
  }, [id]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <DivContainer>
        {/* Progresso dos Colaboradores */}
        <GridSection title="Progresso/Desempenho">
          <Grid item xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <BarChart width={650} height={300} data={participationData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tickFormatter={(tick) => truncateText(tick, 5)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="participantes" fill="#ae1100" barSize={30} />
                <Bar dataKey="concluidos" fill="black" barSize={30} />
                <Legend layout="vertical" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: '20px' }} />
              </BarChart>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <PieChart width={650} height={300}>
                <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} label>
                  {pieData.map((entry) => (
                    <Cell key={`cell-${entry.id}`} fill={entry.id === 'concluded' ? '#000' : '#ae1100'} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend layout="vertical" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: '20px' }} />
              </PieChart>
            </div>
          </Grid>
        </GridSection>

        {/* Avaliações médias por Curso */}
        <GridSection title="Avaliações Médias por Curso">
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <BarChart width={1200} height={300} data={averageScoreData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tickFormatter={(tick) => truncateText(tick, 5)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="averageScore" fill="#ae1100" barSize={30} name="Nota Média por Capacitação" />
                <Legend layout="vertical" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: '20px' }} />
              </BarChart>
            </div>
          </Grid>
        </GridSection>

        {/* Distribuição por Setor */}
        <GridSection title="Distribuição por Setor">
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <BarChart width={1200} height={300} data={sectorDistributionData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tickFormatter={(tick) => truncateText(tick, 5)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="participantes" fill="#ae1100" barSize={30} />
                <Bar dataKey="concluídos" fill="#000" barSize={30} />
                <Legend layout="vertical" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: '20px' }} />
              </BarChart>
            </div>
          </Grid>
        </GridSection>

        {/* Desempenho por Setor e Curso */}
        <GridSection title="Desempenho por Setor e Capacitação">
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <BarChart width={1200} height={300} data={averageScoreBySectorAndCourseData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tickFormatter={(tick) => truncateText(tick, 5)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="averageScore" fill="#ae1100" barSize={30} name="Nota Média por Setor e Capacitação" />
                <Legend layout="vertical" verticalAlign="bottom" align="center" wrapperStyle={{ marginTop: '20px' }} />
              </BarChart>
            </div>
          </Grid>
        </GridSection>
      </DivContainer>
    </Container>
  );
}

export default Dashboard;

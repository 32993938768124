import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  font-family: 'MyFont';
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
  scrollbar-width: none;
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  label {
    display: flex;
    font-size: 15px;
    font-family: 'MyFont';
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 5px;
  }

  button {
    margin-top: 20px;
    width: 150px;
    font-weight: bold;
    color: white;
    background-color: #ae1100;
  }

  input,
  select {
    width: 480px;
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 0px 10px 0px;
    font-size: 15px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
  }

  /* Adicionado para fazer os inputs se ajustarem horizontalmente */
  display: grid;
  grid-template-columns: 1fr; /* Define uma coluna */
  grid-gap: 10px; /* Espaçamento entre os elementos */
  .text-field {
    width: 100%;
    margin-bottom: 10px;
    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
        font-family: 'MyFont';
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    max-width: 600px;
    width: 100%;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 70%;
    background-color: white;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-family: 'MyFont';
`;

export const CheckboxWrapper = styled.div`
  /* Removido o estilo do input[type='checkbox'] aqui para evitar repetição */
`;

export const StyledContainer = styled.div`
  /* Ajustes feitos para alinhar os itens horizontalmente */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  button {
    margin-left: 10px;
    margin-top: 20px;
    width: 150px;
    color: white;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
  }
`;

export const StyledSection = styled.div`
  /* Estilos ajustados para melhor alinhamento */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, StyledTableBody } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';

export default function Course() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/course');
        const coursesData = response.data;
        setCourses(coursesData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching courses:', error);
      }
    }

    fetchData();
  }, []);

  return (
    <Container>
      <Loading isLoading={isLoading} />

      <CollaboratorContainer>
        <h1> Cursos existentes</h1>
        <h4>Cursos disponíveis no sistema</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome do Curso</TableCell>
              <TableCell>Editar</TableCell>
            </TableRow>
          </TableHead>
          <StyledTableBody>
            {courses.map((course) => (
              <TableRow key={course.id}>
                <TableCell>{course.name}</TableCell>
                <TableCell>
                  <Link to={`/courseAcces/${course.id}/edit`}>
                    <FaEdit color="black" size={isMobile ? 10 : 16} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </Table>
      </CollaboratorContainer>
    </Container>
  );
}

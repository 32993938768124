import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function PrivateRoute({ component: Component, permittedDepartments, path }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userDepartamento = useSelector((state) => state.auth.user.departamento);

  if (!isLoggedIn) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  // Verifique se o departamento do usuário está incluído nos departamentos permitidos
  if (
    permittedDepartments &&
    permittedDepartments.length > 0 &&
    userDepartamento &&
    !permittedDepartments.includes(userDepartamento.toString())
  ) {
    return <Redirect to={{ pathname: '/access-denied' }} />;
  }

  return <Route path={path} render={(props) => <Component {...props} />} />;
}

PrivateRoute.defaultProps = {
  permittedDepartments: [], // Define departamentos permitidos por padrão
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  permittedDepartments: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string.isRequired,
};

import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import * as actions from './actions';
import * as types from '../types';
import axios from '../../../services/axios';

function* confirmRequest({ payload }) {
  try {
    const { tokenWithoutColon, password } = payload;
    console.log(password);
    yield call(axios.post, `/rhusers/confirm/?${tokenWithoutColon}`, { password });
    toast.success('Conta Validada.');
    yield put(actions.confirmAccountSuccess());
  } catch (error) {
    toast.error('Conta não validada.');
    yield put(actions.confirmAccountFailure());
  }
}

function* loginRequest({ payload }) {
  try {
    const response = yield call(axios.post, '/tokens', payload);

    const { token, user } = response.data;

    yield put(actions.setDepartment(user.departamento));

    axios.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(actions.loginSuccess({ ...response.data }));

    switch (user.departamento) {
      case 'Admin':
        yield payload.history.push('/home-admin');
        break;
      case 'Empresa':
        yield payload.history.push('/home-empresa');
        break;
      default:
        yield payload.history.push('/home');
        break;
    }

    toast.success('Você fez login');
  } catch (e) {
    // Adicione esta linha para verificar a resposta completa

    if (
      e.response &&
      e.response.status === 402 &&
      e.response.data.errors[0] === 'Conta bloqueada. Entre em contato com o suporte.'
    ) {
      toast.error('Conta bloqueada. Entre em contato com o suporte.');
    } else if (
      e.response &&
      e.response.status === 401 &&
      e.response.data.errors[0] === 'Conta não validada. Faça a validação do e-mail.'
    ) {
      toast.error('Conta não validada. Faça a validação do e-mail.');
    } else {
      toast.error('Usuário ou senha inválidos.');
    }

    yield put(actions.loginFailure());
  }
}
function* passwordResetRequest({ payload }) {
  try {
    const { email } = payload;

    yield call(axios.post, '/rhusers/recover-password', { email });

    toast.success('Um e-mail de recuperação de senha foi enviado.');

    yield put(actions.passwordResetSuccess());
    yield payload.history.push('/login');
  } catch (error) {
    console.error('Erro ao solicitar recuperação de senha:', error);

    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    toast.error('Ocorreu um erro ao solicitar a recuperação de senha.');
    yield put(actions.passwordResetFailure());
  }
}
function* passwordRecoveryRequest({ payload }) {
  try {
    const { token, newPassword } = payload;

    yield call(axios.post, `/rhusers/reset-password?token=${token}`, { newPassword });

    toast.success('Senha redefinida com sucesso.');

    yield put(actions.passwordRecoverySuccess());
    yield payload.history.push('/login');
  } catch (error) {
    console.error('Erro ao redefinir senha:', error);

    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }

    toast.error('Ocorreu um erro ao redefinir a senha.');
    yield put(actions.passwordRecoveryFailure());
  }
}

function persistRehydrate({ payload }) {
  const token = get(payload, 'auth.token', '');
  if (!token) return;
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

// eslint-disable-next-line consistent-return
function* registerRequest({ payload }) {
  const { cnpj_enterprise, funcao, setor, nome, sobrenome, departamento, email, password } = payload;

  try {
    const dataToSend = {
      cnpj_enterprise,
      funcao,
      setor,
      nome,
      sobrenome,
      departamento,
      email,
      password,
    };

    yield call(axios.post, '/rhusers', dataToSend);
    toast.success('Conta criada com sucesso!');
    yield put(
      actions.registerCreatedSuccess({
        cnpj_enterprise,
        funcao,
        setor,
        nome,
        sobrenome,
        departamento,
        email,
        password,
      }),
    );
    switch (departamento) {
      case 'Admin':
        yield payload.history.push('/home-admin');
        break;
      default:
        yield payload.history.push('/home-empresa');
        break;
    }
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    }
    yield put(actions.registerFailure());
  }
}

function* registerEnterpriseRequest({ payload }) {
  const { cnpj } = payload;

  try {
    const dataToSend = {
      cnpj,
    };

    yield call(axios.post, '/empresa', dataToSend);
    toast.success('Empresa cadastrada com sucesso!');
    yield put(
      actions.registerEnterpriseCreatedSuccess({
        cnpj,
      }),
    );
    switch (departamento) {
      case 'Admin':
        yield payload.history.push('/home-admin');
        break;
      default:
        yield payload.history.push('/home-empresa');
        break;
    }
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    }
    yield put(actions.registerEnterpriseFailure());
  }
}

function* registerCourseRequest({ payload }) {
  const { formData, history } = payload;
  Array.from(formData.entries()).forEach(([key, value]) => {
    console.log(`${key}, ${value}`);
  });
  try {
    yield call(axios.post, '/course', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // define o tipo de conteúdo para multipart/form-data
      },
    });
    toast.success('Curso criado com sucesso!');
    yield put(
      actions.registerCourseCreatedSuccess({
        history,
        name: formData.get('name'), // obtém o nome do formData
        description: formData.get('description'), // obtém a descrição do formData
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerCourseFailure());
  }
}

function* registerSubmoduleRequest({ payload }) {
  const { courseId, name, description, history } = payload;

  try {
    const dataToSend = {
      courseId,
      name,
      description,
      history,
    };

    yield call(axios.post, '/submodule', dataToSend);
    toast.success('Submodulo criado com sucesso!');
    yield put(
      actions.registerSubmoduleCreatedSuccess({
        courseId,
        name,
        description,
        history,
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerSubmoduleFailure());
  }
}

function* registerQuizRequest({ payload }) {
  const { submodule_id, questions, maxScore, history } = payload;
  try {
    const dataToSend = {
      submodule_id,
      questions,
      maxScore,
      history,
    };

    yield call(axios.post, '/quiz', dataToSend);
    toast.success('Quiz criado com sucesso!');
    yield put(
      actions.registerQuizCreatedSuccess({
        submodule_id,
        questions,
        maxScore,
        history,
      }),
    );

    yield payload.history.push(`/submoduleManager/${submodule_id}/edit`);
    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerQuizFailure());
  }
}

function* registerContentRequest({ payload }) {
  const { formData, history } = payload;
  Array.from(formData.entries()).forEach(([key, value]) => {
    console.log(`${key}, ${value}`);
  });
  try {
    yield call(axios.post, '/contentsubmodule', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // define o tipo de conteúdo para multipart/form-data
      },
    });
    toast.success('Conteudo adicionado com sucesso!');
    yield put(
      actions.registerContentCreatedSuccess({
        history,
        submodule_id: formData.get('submodule_id'), // obtém o id do submódulo do formData
        name: formData.get('name'), // obtém o nome do formData
        textContent: formData.get('textContent'), // obtém o conteúdo de texto do formData
        videoUrl: formData.get('videoUrl'), // obtém a URL do vídeo do formData
        documentUrl: formData.get('documentUrl'), // obtém a URL do documento do formData
      }),
    );

    yield payload.history.push(`/submoduleManager/${formData.get('submodule_id')}/edit`);
    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerContentFailure());
  }
}

function* editOtherUserRequest({ payload }) {
  const { id, nome, sobrenome, departamento, email, password } = payload;

  try {
    const userData = {
      id,
      nome,
      sobrenome,
      departamento,
      email,
    };

    if (password) {
      userData.password = password;
    }

    yield call(axios.put, `/rhusers/${id}`, userData);
    toast.success('Conta Editada com sucesso!');
    yield put(
      actions.editOtherUserSuccess({
        id,
        nome,
        sobrenome,
        departamento,
        email,
        password,
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editFailure());
  }
}

function* editRequest({ payload }) {
  const {
    id,
    nome,
    cnpj,
    nome_fantasia,
    responsavel_empresa,
    razao_social,
    telefone,
    cep,
    endereco,
    numero,
    bairro,
    complemento,
    cidade,
    estado,
    date_start,
    date_end,
    sobrenome,
    cargo,
    departamento,
    email,
    password,
  } = payload;

  try {
    const userData = {
      email,
      nome,
      sobrenome,
      cargo,
      departamento,
      cnpj,
      nome_fantasia,
      responsavel_empresa,
      razao_social,
      telefone,
      cep,
      endereco,
      numero,
      bairro,
      complemento,
      cidade,
      estado,
      date_start,
      date_end,
    };
    if (telefone !== undefined && telefone !== null && telefone !== '') {
      userData.telefone = telefone;
    } else {
      userData.telefone = null;
    }
    if (numero !== undefined && numero !== null && numero !== '') {
      userData.numero = numero;
    } else {
      userData.numero = '000';
    }
    if (password) {
      userData.password = password;
    }

    yield call(axios.put, `/rhusers/${id}`, userData);
    toast.success('Conta Editada com sucesso!');
    yield put(
      actions.editSuccess({
        id,
        nome,
        sobrenome,
        cargo,
        departamento,
        cnpj,
        nome_fantasia,
        responsavel_empresa,
        razao_social,
        telefone,
        cep,
        endereco,
        numero,
        bairro,
        complemento,
        cidade,
        estado,
        date_start,
        date_end,
        email,
        password,
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editFailure());
  }
}

function* editCourseRequest({ payload }) {
  const { id, formData } = payload;
  Array.from(formData.entries()).forEach(([key, value]) => {
    console.log(`${key}, ${value}`);
  });
  try {
    yield call(axios.put, `/course/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // define o tipo de conteúdo para multipart/form-data
      },
    });

    toast.success('Curso Editado com sucesso!');
    yield put(
      actions.editCourseSuccess({
        name: formData.get('name'), // obtém o nome do formData
        description: formData.get('description'), // obtém a descrição do formData
        img: formData.get('img'), // obtém a imagem do formData
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editCourseFailure());
  }
}

function* editSubmoduleRequest({ payload }) {
  const { history, id, name, description } = payload;
  try {
    const userData = {
      name,
      description,
    };

    yield call(axios.put, `/submodule/${id}`, userData);
    yield history.push(`/submoduleManager/${id}/edit`);

    yield put(
      actions.editSubmoduleSuccess({
        name,
        description,
      }),
    );
    toast.success('Submodulo Editado com sucesso !');
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editSubmoduleFailure());
  }
}
function* editQuizRequest({ payload }) {
  const { id, questions, maxScore, history } = payload;
  try {
    const userData = {
      id,
      questions,
      maxScore,
    };

    yield call(axios.put, `/quiz/${id}`, userData);
    toast.success('Quiz Editado com sucesso!');
    yield history.push('/course');
    yield put(
      actions.editQuizSuccess({
        id,
        questions,
        maxScore,
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      console.log(errorMessage);
    }

    yield put(actions.editQuizFailure());
  }
}

function* registerSectorRequest({ payload }) {
  const { idCreator, sector, history } = payload;

  try {
    const dataToSend = {
      idCreator,
      sector,
      history,
    };

    yield call(axios.post, '/sectors', dataToSend);
    toast.success('Setor criado com sucesso!');
    yield put(
      actions.registerSectorSuccess({
        idCreator,
        sector,
        history,
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerSectorFailure());
  }
}

function* registerFunctionRequest({ payload }) {
  const { idCreator, func, history } = payload;

  try {
    const dataToSend = {
      idCreator,
      func,
      history,
    };

    yield call(axios.post, 'functions', dataToSend);
    toast.success('Função criado com sucesso!');
    yield put(
      actions.registerFunctionSuccess({
        idCreator,
        func,
        history,
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.registerFunctionFailure());
  }
}

function* editFunctionRequest({ payload }) {
  const { id, func, history } = payload;

  try {
    const dataToSend = {
      id,
      func,
      history,
    };

    yield call(axios.put, `/functions/${id}`, dataToSend);
    toast.success('Setor editado com sucesso!');
    yield put(
      actions.editFunctionSuccess({
        id,
        func,
        history,
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editFunctionFailure());
  }
}

function* editSectorRequest({ payload }) {
  const { id, sector, history } = payload;

  try {
    const dataToSend = {
      id,
      sector,
      history,
    };

    yield call(axios.put, `/sectors/${id}`, dataToSend);
    toast.success('Setor editado com sucesso!');
    yield put(
      actions.editSectorSuccess({
        id,
        sector,
        history,
      }),
    );

    yield call(() => window.location.reload());
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editSectorFailure());
  }
}

function* editContentRequest({ payload }) {
  const { id, formData } = payload;

  try {
    yield call(axios.put, `/contentsubmodule/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // define o tipo de conteúdo para multipart/form-data
      },
    });

    toast.success('Conteudo Editado com sucesso!');
    yield put(
      actions.editContentSuccess({
        name: formData.get('name'), // obtém o nome do formData
        textContent: formData.get('textContent'), // obtém o texto do formData
        videoUrl: formData.get('videoUrl'), // obtém a URL do vídeo do formData
        documentUrl: formData.get('documentUrl'), // obtém a URL do documento do formData
      }),
    );
  } catch (e) {
    const response = get(e, 'response', null);
    if (response) {
      const errorMessage = get(response.data, 'error', 'Erro desconhecido');
      toast.error(errorMessage);
    } else {
      toast.error('Erro desconhecido');
    }

    yield put(actions.editContentFailure());
  }
}

export default all([
  takeLatest(types.EDIT_OTHER_USER_REQUEST, editOtherUserRequest),
  takeLatest(types.EDIT_CONTENT_REQUEST, editContentRequest),
  takeLatest(types.EDIT_QUIZ_REQUEST, editQuizRequest),
  takeLatest(types.EDIT_SUBMODULE_REQUEST, editSubmoduleRequest),
  takeLatest(types.EDIT_SECTOR_REQUEST, editSectorRequest),
  takeLatest(types.EDIT_FUNCTION_REQUEST, editFunctionRequest),
  takeLatest(types.EDIT_COURSE_REQUEST, editCourseRequest),
  takeLatest(types.CONFIRM_ACCOUNT_REQUEST, confirmRequest),
  takeLatest(types.LOGIN_REQUEST, loginRequest),
  takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
  takeLatest(types.REGISTER_REQUEST, registerRequest),
  takeLatest(types.REGISTER_ENTERPRISE_REQUEST, registerEnterpriseRequest),
  takeLatest(types.REGISTER_COURSE_REQUEST, registerCourseRequest),
  takeLatest(types.REGISTER_SUBMODULE_REQUEST, registerSubmoduleRequest),
  takeLatest(types.REGISTER_SECTOR_REQUEST, registerSectorRequest),
  takeLatest(types.REGISTER_FUNCTION_REQUEST, registerFunctionRequest),
  takeLatest(types.REGISTER_CONTENT_REQUEST, registerContentRequest),
  takeLatest(types.REGISTER_QUIZ_REQUEST, registerQuizRequest),
  takeLatest(types.EDIT_REQUEST, editRequest),
  takeLatest(types.PASSWORD_RESET_REQUEST, passwordResetRequest),
  takeLatest(types.PASSWORD_RECOVERY_REQUEST, passwordRecoveryRequest),
]);

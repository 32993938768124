import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 97vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  text-align: left;
  h2 {
    margin: 50px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: -2%;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  .text-field {
    font-family: 'MyFont';
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
`;

export const Form = styled.form`
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  max-height: 1680px;
  margin: -5% auto 0%;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  text-align: left;
  h1 {
    margin: -35px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }
  h4 {
    margin: 5px 0px;
    font-size: 18px;
    margin-bottom: -3%;
  }

  flex-direction: column;
  align-items: center;

  label {
    display: flex;
    font-family: 'MyFont';
    flex-direction: column;
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 15px;
  }

  h1 {
    display: flex;
    flex-direction: column;
  }

  button {
    margin-left: 10px;
    margin-top: 15px;
    width: 150px;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    z-index: 1000;
    text-transform: none;
    font-weight: bold;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }

  input {
    width: 480px;
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    font-family: 'MyFont';
    margin: 2px 0px 10px 0px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%;
    margin-top: 5px;

    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 30vh;
  }

  .text-field {
    & label.Mui-focused {
      color: #ae1100;
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 50px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .box-container h1 {
    position: relative; /* Define posicionamento relativo para o h1 */
    padding: 0px 5px;
    z-index: 1; /* Define a ordem de empilhamento para ficar acima da borda */
    margin-right: 70%; /* Ajusta a margem direita */
    background-color: white;
  }
`;

export const CenteredText = styled.h1`
  margin-bottom: 0px;
`;

export const TextareaAutosizeStyled = styled.textarea`
  width: 480px; /* Defina a largura desejada */
  height: 200px; /* Ajuste a altura conforme necessário */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: vertical; /* Permite a redimensionamento vertical */
  font-family: inherit; /* Herda a fonte do elemento pai */
`;

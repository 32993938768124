import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import favicon from './assets/img/Favcon (1).svg'; // Import the PNG favicon

// Função para adicionar o favicon ao documento
const setFavicon = (url) => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = url;
  link.type = 'image/png'; // Ensure the type is set correctly
  document.head.appendChild(link);
};

// Adiciona o favicon
setFavicon(favicon);

// Cria o elemento root
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Renderiza o componente App
root.render(<App />);

import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;
`;

export const Overlay = styled.div`
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: -20px;
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: -5% auto 0%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h1 {
    margin: -32px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  h4 {
    margin: 5px 0px;
    font-size: 18px;
    font-family: 'MyFont';
  }

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    margin: 5px 8px;
    font-size: 15px;
    font-family: 'MyFont';
  }

  button {
    margin-left: 20px;
    width: 150px;
    color: white;
    font-weight: bold;
    background-color: #ae1100;
    font-family: 'MyFont';
    z-index: 1000;
    margin: 40px 0px;
  }

  input {
    width: 70vw; /* Ajusta a largura para preencher 70% da largura da tela */
    max-width: 480px; /* Limita a largura máxima para 480px */
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin: 2px 0px 10px 0px;
    box-sizing: border-box;
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  select {
    width: 100%;
    margin-top: 5px;
    font-family: 'MyFont';
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
  }
  .text-field {
    font-family: 'MyFont';
    & label.Mui-focused {
      color: #ae1100;
      font-family: 'MyFont';
    }
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #ae1100;
      }
    }
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 90px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 65%;
    background-color: white;
  }
  .autocomplete {
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      margin-left: 15px;
      margin-top: 3.3%;
      font-family: 'MyFont';
      font-size: 15px; /* Adicionado tamanho da fonte */
    }
    .MuiAutocomplete-popupIndicator {
      position: absolute;
      right: 65px; /* ajuste este valor conforme necessário */
    }
    .MuiInput-underline:before {
      border-bottom: none;
    }
    .MuiInput-underline:after {
      border-bottom: none;
    }
    .MuiInput-underline:hover:before {
      border-bottom: none;
    }

    input {
      margin: initial;
      padding: 20px;
      margin-top: 3%;
    }

    button {
      color: initial;
      display: initial;
      font-family: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    button:hover {
      color: initial;
      display: initial;
      font-family: initial;
      font-size: initial;
      font-weight: initial;
      line-height: initial;
      letter-spacing: initial;
      padding: initial;
      border: initial;
      border-radius: initial;
      background: initial;
      margin: initial;
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      max-height: initial;
      overflow: initial;
      white-space: initial;
      align-items: initial;
      justify-content: initial;
      text-decoration: initial;
      transition: initial;
    }

    .MuiAutocomplete-option {
      color: black; /* ajuste a cor do texto conforme necessário */
      background-color: white; /* ajuste a cor de fundo conforme necessário */
    }

    .MuiAutocomplete-option:hover {
      background-color: #f5f5f5; /* ajuste a cor de fundo ao passar o mouse conforme necessário */
    }
  }
`;

export const Title = styled.h1`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin: 50px 0px;
  font-size: 20px;
  font-family: 'MyFont';
  align-self: flex-start; /* Justifica o título à esquerda */
`;

export const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    height: 20px;
    margin: -3% 20%;
    position: relative;
    width: 30px;
    cursor: pointer;
    &:checked {
      background-color: #c3073f;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;

      &:checked {
        background-color: #c3073f;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }
`;

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../assets/img/CyberSensei.svg';

const logoStyle = {
  width: '240px',
  height: 'auto',
  marginTop: '-20px',
};

function scrollToSection(sectionId) {
  const sectionElement = document.getElementById(sectionId);
  const offset = 128; // Ajuste conforme necessário
  if (sectionElement) {
    const targetScroll = sectionElement.offsetTop - offset;
    window.scrollTo({
      top: targetScroll,
      behavior: 'smooth',
    });
  }
}

function Copyright() {
  return (
    <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
      &nbsp;•&nbsp;
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 1 },
        py: { xs: 6, sm: 8 },
        textAlign: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: -5, mt: '-40px' }}>
        <img src={Logo} style={logoStyle} alt="logo of CyberSensei" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          textAlign: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            minWidth: { xs: '100%', sm: '20%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            style={{ margin: '0px 15px' }}
            sx={{
              fontFamily: 'MyFont',
              fontSize: '20px',
              color: '#ae1100',
              display: 'inline-block',
              borderBottom: '2px solid #ae1100',
              pb: 1,
              mb: 1,
              width: 'fit-content',
              fontWeight: 'bold',
            }}
          >
            Menu
          </Typography>
          <MenuItem onClick={() => scrollToSection('video')} sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
            Home
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('features')} sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
            Quem Somos
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('highlights')} sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
            Soluções
          </MenuItem>
          <MenuItem onClick={() => scrollToSection('faq')} sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
            FAQ
          </MenuItem>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '25%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'MyFont',
              fontSize: '20px',
              color: '#ae1100',
              borderBottom: '2px solid #ae1100',
              pb: 1,
              mb: 1,
              width: 'fit-content',
              fontWeight: 'bold',
            }}
          >
            Contate-nos
          </Typography>
          <Box display="flex" alignItems="center">
            <PhoneIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="tel:+558130497999"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              +55 81 3049-7999
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <WhatsAppIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="https://wa.me/5581996527732"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              +55 81 99652-7732
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <EmailIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="mailto:contato@bbitsolutions.com.br"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              contato@bbitsolutions.com.br
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '25%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'MyFont',
              fontSize: '20px',
              color: '#ae1100',
              borderBottom: '2px solid #ae1100',
              pb: 1,
              mb: 1,
              width: 'fit-content',
            }}
          >
            Siga-nos
          </Typography>
          <Box display="flex" alignItems="center">
            <InstagramIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="https://www.instagram.com/bbitsolutions"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              @bbitsolutions
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <YouTubeIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="https://www.youtube.com/c/BlackBeltItSolutions"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              BlackBeltitSolutions
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            <LinkedInIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Link
              color="text.secondary"
              href="https://www.linkedin.com/company/blackbelt-it-solutions/"
              sx={{ fontFamily: 'MyFont', fontSize: '18px' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              BlackBelt IT Solutions
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            minWidth: { xs: '100%', sm: '6%' },
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              fontFamily: 'MyFont',
              fontSize: '20px',
              color: '#ae1100',
              borderBottom: '2px solid #ae1100',
              pb: 1,
              mb: 1,
              width: 'fit-content',
            }}
          >
            Onde Estamos
          </Typography>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Typography color="text.secondary" sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
              R. do Cupim, 132 - Graças, Recife - PE, 52011-070
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LocationOnIcon sx={{ mr: 1, fontSize: 24, color: '#ae1100' }} />
            <Typography color="text.secondary" sx={{ fontFamily: 'MyFont', fontSize: '18px' }}>
              AV. Samuel Macdowell, 46 - Vila da Inabi, Camaragibe - PE, 54753-350
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 2, sm: 4 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="#" sx={{ fontFamily: 'MyFont' }} target="_blank" rel="noopener noreferrer">
            @ BBit Solutions
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5, fontFamily: 'MyFont' }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#" sx={{ fontFamily: 'MyFont' }} target="_blank" rel="noopener noreferrer">
            Todos os Direitos Reservados
          </Link>
          <Copyright />
        </div>
      </Box>
    </Container>
  );
}

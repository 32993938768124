import * as types from '../types';
import axios from '../../../services/axios';

const initialState = {
  isLoggedIn: false,
  token: false,
  user: {},
  isLoading: false,
};

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      const newState = { ...state };
      newState.isLoggedIn = true;
      newState.token = action.payload.token;
      newState.user = action.payload.user;
      newState.isLoading = false;
      newState.accountNotConfirmed = false;
      return newState;
    }

    case types.LOGIN_FAILURE: {
      delete axios.defaults.headers.Authorization;
      const newState = { ...initialState, accountNotConfirmed: true };
      return newState;
    }

    case types.LOGIN_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }
    case types.REGISTER_ENTERPRISE_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_ENTERPRISE_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_ENTERPRISE_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_COURSE_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_COURSE_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_COURSE_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_SUBMODULE_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_SUBMODULE_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_SUBMODULE_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_SECTOR_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_SECTOR_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_SECTOR_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_FUNCTION_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_FUNCTION_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_FUNCTION_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_QUIZ_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_QUIZ_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_QUIZ_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.REGISTER_CONTENT_CREATED_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_CONTENT_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.REGISTER_CONTENT_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.CONFIRM_ACCOUNT_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.CONFIRM_ACCOUNT_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.CONFIRM_ACCOUNT_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }
    case types.PASSWORD_RESET_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }

    case types.PASSWORD_RESET_SUCCESS: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.PASSWORD_RESET_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.EDIT_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedUser = { ...state.user }; // Copie as informações do usuário atual
      return newState;
    }
    case types.EDIT_SUCCESS: {
      const newState = { ...state };
      newState.user = action.payload; // Atualize o usuário com o payload da ação
      newState.user.id = action.payload.id; // Certifique-se de que o id está sendo definido
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.EDIT_OTHER_USER_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedOtherUser = { ...state.otherUser }; // Copie as informações do outro usuário
      return newState;
    }
    case types.EDIT_OTHER_USER_SUCCESS: {
      const newState = { ...state };
      newState.otherUser = { ...state.editedOtherUser }; // Atualize o outro usuário com o usuário editado
      newState.editedOtherUser = null; // Limpe os detalhes do usuário editado após o sucesso
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_OTHER_USER_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.EDIT_COURSE_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedUser = { ...state.user }; // Copie as informações do usuário atual
      return newState;
    }
    case types.EDIT_COURSE_SUCCESS: {
      const newState = { ...state };
      newState.editedUser = null; // Limpe os detalhes do usuário editado após o sucesso
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_COURSE_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.EDIT_CONTENT_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedUser = { ...state.user }; // Copie as informações do usuário atual
      return newState;
    }
    case types.EDIT_CONTENT_SUCCESS: {
      const newState = { ...state };
      newState.editedUser = null; // Limpe os detalhes do usuário editado após o sucesso
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_CONTENT_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.EDIT_SUBMODULE_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedUser = { ...state.user }; // Copie as informações do usuário atual
      return newState;
    }
    case types.EDIT_SUBMODULE_SUCCESS: {
      const newState = { ...state };
      newState.editedUser = null; // Limpe os detalhes do usuário editado após o sucesso
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_SUBMODULE_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_QUIZ_REQUEST: {
      const newState = { ...state };
      newState.isLoading = true;
      newState.editedUser = { ...state.user }; // Copie as informações do usuário atual
      return newState;
    }
    case types.EDIT_QUIZ_SUCCESS: {
      const newState = { ...state };
      newState.editedUser = null; // Limpe os detalhes do usuário editado após o sucesso
      newState.isLoading = false;
      return newState;
    }
    case types.EDIT_QUIZ_FAILURE: {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }

    case types.SET_DEPARTMENT: {
      const newState = { ...state, user: { ...state.user, departamento: action.payload.departamento } };
      return newState;
    }

    default: {
      return state;
    }
  }
}

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';

import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import { ReactComponent as Logo } from '../../assets/img/CyberSensei.svg';
import { Form, Composition, Container, StyledButton } from './styled';
import * as actions from '../../store/modules/auth/actions';
import axios from '../../services/axios';

function PasswordResetPage() {
  const dispatch = useDispatch();
  const history = useHistory(); // Use useNavigate para redirecionar
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState(''); // Estado para sobrenome
  const [email, setEmail] = useState(''); // Estado para email
  const [empresa, setEmpresa] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [formStep, setFormStep] = useState(1);
  useEffect(() => {
    setNome();
    setSobrenome();
    setDepartamento();
    setEmail();
  }, []);

  const handleSubmitToken = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.get('/empresa');
      const userEmpresa = response.data.find((empresaData) => empresaData.token_enterprise === token);
      const userCollaborator = response.data.find((empresaData) => empresaData.token_collaborator === token);

      if (userEmpresa) {
        setDepartamento('Empresa');
        setEmpresa(userEmpresa);
        setFormStep(2);
      } else if (userCollaborator) {
        setDepartamento('colaborador');
        setEmpresa(userCollaborator);
        setFormStep(2);
      } else {
        alert('Token inválido ou expirado');
      }
    } catch (error) {
      console.error('Erro ao validar token:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevStep = () => {
    setFormStep(1);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    const errors = {};
    if (!nome) errors.nome = 'Nome é obrigatório';
    if (!sobrenome) errors.sobrenome = 'Sobrenome é obrigatório';
    if (!email) errors.email = 'E-mail é obrigatório';
    // Adicione mais validações conforme necessário

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return; // Pare o envio do formulário até que os erros sejam corrigidos
    }

    const randomPassword = Math.random().toString(36).slice(-8);

    try {
      dispatch(
        actions.registerRequest({
          cnpj_enterprise: empresa.cnpj,
          nome,
          sobrenome,
          email,
          departamento,
          password: randomPassword,
        }),
      );
      history.push('/login');
    } catch (error) {
      console.error('Erro ao criar conta:', error); // Loga o erro
      alert('Erro desconhecido ao criar a conta. Tente novamente.');
    }
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };

  const handleSobrenomeChange = (e) => {
    setSobrenome(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <Loading isLoading={isLoading} />
      {formStep === 1 ? (
        <Composition>
          <Logo className="logo" />
          <h1>Insira Código</h1>
          <Form onSubmit={handleSubmitToken}>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              onChange={(e) => setToken(e.target.value)}
              placeholder="Insira Código de validação"
              style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
            />
            <div className="button-container">
              <Link className="link" to="/login">
                <button className="buttonLink" type="button">
                  Voltar
                </button>
              </Link>
              <button type="submit" disabled={isLoading || token === ''}>
                Próximo
              </button>
            </div>
          </Form>
        </Composition>
      ) : (
        formStep === 2 && (
          <Composition>
            <Logo className="logo" />
            <h2>Insira Seus Dados</h2>
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              label="Nome *"
              value={nome}
              onChange={handleNomeChange}
              placeholder="Nome"
              margin="normal"
              error={!!fieldErrors.nome}
              helperText={fieldErrors.nome}
              style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              label="Sobrenome *"
              value={sobrenome}
              onChange={handleSobrenomeChange}
              placeholder="Seu sobrenome"
              error={!!fieldErrors.sobrenome}
              helperText={fieldErrors.sobrenome}
              style={{ fontFamily: 'MyFont' }}
            />
            <TextField
              className="text-field"
              InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
              label="E-mail *"
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Seu e-mail"
              fullWidth
              margin="normal"
              error={!!fieldErrors.email}
              helperText={fieldErrors.email}
              style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
            />
            <div className="button-group">
              <StyledButton type="button" onClick={handlePrevStep}>
                Voltar
              </StyledButton>
              <StyledButton type="submit" onClick={handleRegister}>
                Finalizar
              </StyledButton>
            </div>
          </Composition>
        )
      )}
      <Footer />
    </Container>
  );
}

export default PasswordResetPage;

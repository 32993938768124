import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@material-ui/core';
import { Form, Bloco } from './styled';
import Loading from '../../components/Loading';
import axios from '../../services/axios';

export default function Register(props) {
  const { handleClose } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const cnpj_enterprise = useSelector((state) => state.auth.user.cnpj_enterprise);
  const [token, setToken] = useState('');
  const tokenRef = useRef();

  const handleStop = () => {
    handleClose();
  };
  useEffect(() => {
    if (!cnpj_enterprise) {
      setIsLoading(false);
      return;
    }
    async function getData() {
      try {
        const { data } = await axios.get(`/empresa`);
        const empFilter = data.filter((emp) => emp.cnpj === cnpj_enterprise);
        setToken(empFilter[0].token_collaborator);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
      }
    }

    getData();
  }, [cnpj_enterprise]);

  const handleGenerateToken = async () => {
    try {
      const response = await axios.post('/empresa/tokenCollaborator', { cnpj: cnpj_enterprise });
      setToken(response.data.token);
      localStorage.setItem('tokenMessage', 'Token gerado com sucesso!');
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error('Erro ao gerar token');
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(tokenRef.current.value);
    toast.success('Token copiado para a área de transferência!');
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px', color: '#ae1100' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form>
        <div className="box-container">
          <h1 style={{ fontFamily: 'MyFont' }}>Crie uma nova conta</h1>
          <h4 style={{ fontFamily: 'MyFont' }}>Inclua novo colaborador ao sistema</h4>
          <TextField
            className="text-field"
            InputProps={{ disableUnderline: true, style: { fontFamily: 'MyFont' } }}
            label="Token"
            value={token}
            disabled
            inputRef={tokenRef}
            margin="normal"
            style={{ boxShadow: 'none', fontFamily: 'MyFont' }}
          />
          <button type="button" onClick={handleGenerateToken}>
            Gerar Token
          </button>
          <button type="button" onClick={handleCopyToken}>
            Copiar Token
          </button>
        </div>
      </Form>
    </Bloco>
  );
}

Register.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
};

import styled from 'styled-components';

export const NavbarContainer = styled.div`
  display: flex;
  width: 100%;
  position: fixed;
  height: 7%;
  justify-content: space-between;
  z-index: 2;
  background-color: #ae1100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const NavbarButton = styled.button`
  background-color: #ae1100;
  color: white;
  border: none;
  width: 100%; /* Ajuste para ocupar toda a largura */
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #940e00;
  }

  &:disabled {
    background-color: #ae1100;
  }
`;

export const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #cccccc;

  &:hover {
    color: #ffffff;
  }
`;

export const VerticalSeparator = styled.div`
  width: 4px;
  height: 100%; /* Ajuste conforme necessário */
  background-color: black;
`;

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { TextField, Button } from '@material-ui/core';
import { Form, Composition, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterContent(props) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const history = useHistory();
  const { id } = useParams();

  const submodule_id = id;

  const [formStep, setFormStep] = useState(1);
  const [name, setName] = useState('');
  const [textContent, setText] = useState('');
  const [videoUrl, setVideo] = useState('');
  const [file, setFile] = useState(null);
  const [isFilled, setIsFilled] = useState(false);
  const [filledField, setFilledField] = useState('');

  useEffect(() => {
    if (textContent !== '' || videoUrl !== '' || file !== null) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [textContent, videoUrl, file]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleNomeChange = (e) => {
    setName(e.target.value);
  };

  const handleTextChange = (e) => {
    if (!isFilled || filledField === 'text') {
      setText(e.target.value);
      setFilledField('text');
    }
  };

  const handleVideoChange = (e) => {
    if (!isFilled || filledField === 'video') {
      setVideo(e.target.value);
      setFilledField('video');
    }
  };

  const handleFileChange = (e) => {
    if (!isFilled || filledField === 'file') {
      setFile(e.target.files[0]);
      setFilledField('file');
    }
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    const { handleClose } = props;
    e.preventDefault();

    let formErrors = false;
    switch (formStep) {
      case 1:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        break;
      case 2:
        if (!name || name.length < 3 || name.length > 255) {
          formErrors = true;
          toast.error('Nome deve ter entre 3 e 255 caracteres');
        }
        break;

      default:
        break;
    }

    if (formErrors) return;

    const formData = new FormData();
    formData.append('submodule_id', submodule_id);
    formData.append('name', name);
    formData.append('textContent', textContent);
    formData.append('videoUrl', videoUrl);
    formData.append('documentUrl', file);

    dispatch(
      actions.registerContentRequest({
        formData,
        history,
      }),
    );
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Adicione Novos Conteúdos</h1>
          <h4>Inclua novos conteúdos no submodulo</h4>
          {formStep === 1 && (
            <>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={handleNomeChange}
                placeholder="Nome do Curso"
                fullWidth
                margin="normal"
              />
              <textarea
                className="textarea"
                value={textContent}
                onChange={handleTextChange}
                placeholder="Conteúdo do curso"
                rows={10}
                disabled={isFilled && filledField !== 'text'}
              />

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Video"
                value={videoUrl}
                onChange={handleVideoChange}
                placeholder="Video do curso"
                fullWidth
                margin="normal"
                disabled={isFilled && filledField !== 'video'}
              />

              <input
                className="file-input"
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                disabled={isFilled && filledField !== 'file'}
              />

              <button type="button" onClick={handleNextStep}>
                Próximo
              </button>
            </>
          )}

          {formStep === 2 && (
            <Composition>
              <h1>Confirme seus dados</h1>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
                disabled
                fullWidth
                margin="normal"
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Conteudo de texto"
                value={textContent}
                onChange={(e) => setText(e.target.value)}
                placeholder="Conteudo de texto"
                disabled
                fullWidth
                margin="normal"
              />

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Video"
                value={videoUrl}
                onChange={(e) => setVideo(e.target.value)}
                placeholder="Video do curso"
                fullWidth
                disabled
                margin="normal"
              />

              <Button className="button" type="button" onClick={handlePrevStep} variant="contained" disableRipple>
                Voltar
              </Button>
              <Button className="button" type="submit" variant="contained" color="primary" disableRipple>
                Finalizar
              </Button>
            </Composition>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

RegisterContent.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
};

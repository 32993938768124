import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { NavbarContainer, NavbarButton, ToggleButton, VerticalSeparator } from './styled';

function Navbar({ onNext, onPreviousContent, hasNext, hasPreviousContent, onToggleSidebar }) {
  const history = useHistory();

  const handleGoBackToCourse = () => {
    history.push('/training');
  };

  return (
    <NavbarContainer>
      <ToggleButton onClick={onToggleSidebar}>
        <FaBars />
      </ToggleButton>
      <VerticalSeparator />
      <NavbarButton onClick={handleGoBackToCourse}>Retorne aos Cursos</NavbarButton>
      <VerticalSeparator />
      <NavbarButton onClick={onPreviousContent} disabled={!hasPreviousContent}>
        Conteúdo Anterior
      </NavbarButton>
      <VerticalSeparator />
      <NavbarButton onClick={onNext} disabled={!hasNext}>
        Próximo Conteúdo
      </NavbarButton>
    </NavbarContainer>
  );
}

Navbar.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPreviousContent: PropTypes.func.isRequired,
  hasNext: PropTypes.bool.isRequired,
  hasPreviousContent: PropTypes.bool.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
};

export default Navbar;

import styled from 'styled-components';

export const Popup = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: flex-start; /* Alinha os itens ao topo */
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  ::-webkit-scrollbar {
    width: 0px; /* Define a largura da barra de rolagem como 0 para escondê-la */
    background: transparent; /* Define o fundo da área de rolagem como transparente */
  }

  button {
    background-color: #ae1100;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: auto;
  }

  h1 {
    margin: -35px 0px;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
  }

  .user-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    transition: background-color 0.3s, transform 0.3s; /* Transição suave para o hover */
  }

  .user-item:hover {
    background-color: #f0f0f0; /* Cor de fundo durante o hover */
  }

  .span {
    margin-right: auto;
  }
  h4,
  p,
  textarea,
  .text-field {
    width: 100%;
    padding: 2px;
    font-size: 15px;
  }

  .Button {
    position: absolute;
    top: 20px; /* Ajusta a altura do botão de fechar */
    right: 20px;
    cursor: pointer;
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
    min-width: 580px;
    min-height: auto;
    margin-bottom: -20px;
    flex-direction: column;
    display: flex;
    position: relative;
    max-height: calc(915vh - 500px);
    top: 20px; /* Ajusta a posição do box-container */
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 60%;
    background-color: white;
  }
`;

import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';

import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer } from './styled';

export default function News() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const apikey = '7e2b706bcdaa4a488e0373c165d15c17';
    const category = 'technology';
    const query = 'cibersegurança';
    const sortby = 'relevance';
    const url = `https://gnews.io/api/v4/top-headlines?category=${category}&q=${query}&lang=pt&country=br&max=10&sortby=${sortby}&apikey=${apikey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setArticles(data.articles);
      });
  }, []);

  return (
    <Container>
      <CollaboratorContainer>
        {articles &&
          articles.map((article) => (
            <Card
              key={`${article.title}-${article.publishedAt}`}
              style={{ maxWidth: '300px', maxHeight: '400px', marginTop: '1%' }}
            >
              {' '}
              <CardActionArea href={article.url} target="_blank">
                <CardMedia
                  component="img"
                  alt={article.title}
                  height="140"
                  image={article.image}
                  title={article.title}
                />
                <CardContent
                  style={{
                    overflow: 'auto',
                    maxHeight: '200px',
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    {article.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {article.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
      </CollaboratorContainer>
    </Container>
  );
}

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ backgroundColor: 'black' }}>
      <Container
        id="faq"
        maxWidth="xl"
        style={{ fontFamily: 'MyFont', backgroundColor: 'black' }}
        sx={{
          color: 'white',
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
            fontFamily: 'MyFont',
            mb: 4,
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '24px', sm: '28px', md: '35px' },
          }}
        >
          Perguntas frequentes
        </Typography>
        <Box sx={{ width: '75%' }}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            sx={{ backgroundColor: 'black', color: 'white' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content" id="panel1d-header">
              <Typography
                component="h3"
                variant="subtitle2"
                sx={{ fontFamily: 'MyFont', color: 'white', fontWeight: 'bold' }}
              >
                Quem pode usar a CyberSensei?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxWidth: { sm: '100%', md: '70%' }, fontFamily: 'MyFont', color: 'white' }}>
              <Typography variant="body2" gutterBottom style={{ fontFamily: 'MyFont' }}>
                A CyberSensei é adequada para empresas de todos os tamanhos e segmentos. Nossa plataforma é projetada
                para ser acessível a todos os colaboradores, independentemente de seu papel ou nivel de conhecimento em
                segurança cibernética.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            sx={{ backgroundColor: 'black', color: 'white' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2d-content" id="panel2d-header">
              <Typography
                component="h3"
                variant="subtitle2"
                sx={{ fontFamily: 'MyFont', color: 'white', fontWeight: 'bold' }}
              >
                Como posso começar a usar a CyberSensei?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxWidth: { sm: '100%', md: '70%' }, fontFamily: 'MyFont', color: 'white' }}>
              <Typography variant="body2" gutterBottom style={{ fontFamily: 'MyFont' }}>
                Para começar a usar a CyberSensei, entre em contato conosco. Nossa equipe terá prazer em ajudar a
                configurar a plataforma e personalizar os treinamentos conforme suas necessidades.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            sx={{ backgroundColor: 'black', color: 'white' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3d-content" id="panel3d-header">
              <Typography
                component="h3"
                variant="subtitle2"
                sx={{ fontFamily: 'MyFont', color: 'white', fontWeight: 'bold' }}
              >
                Quais são os benefícios de usar a CyberSensei?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxWidth: { sm: '100%', md: '70%' }, fontFamily: 'MyFont', color: 'white' }}>
              <Typography variant="body2" gutterBottom style={{ fontFamily: 'MyFont' }}>
                Os benefícios incluem maior conscientização sobre segurança cibernética entre os colaboradores, redução
                de riscos de segurança, cumprimento de regulamentações de segurança, e uma equipe mais preparada para
                lidar com ameaças cibernéticas.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            sx={{ backgroundColor: 'black', color: 'white' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4d-content" id="panel4d-header">
              <Typography
                component="h3"
                variant="subtitle2"
                sx={{ fontFamily: 'MyFont', color: 'white', fontWeight: 'bold' }}
              >
                O que diferencia a CyberSensei de outras plataformas de treinamento em cibersegurança?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxWidth: { sm: '100%', md: '70%' }, fontFamily: 'MyFont', color: 'white' }}>
              <Typography variant="body2" gutterBottom style={{ fontFamily: 'MyFont' }}>
                A CyberSensei se destaca pela sua abordagem gamificada, que torna o aprendizado mais interativo e
                envolvente. Além disso, oferecemos um assistente virtual, o Sensei de Cibersegurança, para guiar os
                colaboradores e proporcionar suporte em tempo real.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}

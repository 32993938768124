import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { FaPlay } from 'react-icons/fa'; // Importando o ícone de play

import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, SubmoduleList, SubmoduleItem } from './styled'; // Adicione a importação do styled-components
import axios from '../../services/axios';

import Loading from '../../components/Loading';

export default function Course({ match }) {
  const id = get(match, 'params.id', '');
  const [course, setCourse] = useState(null);
  const [submodules, setSubmodules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startModule, setStartModule] = useState('');

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        // Obter dados do curso
        const responseCourse = await axios.get(`/course/${id}`);
        const courseData = responseCourse.data;
        setCourse(courseData);

        // Obter submódulos do curso
        const responseSubmodules = await axios.get('/submodule', {
          params: { courseId: id },
        });
        const submodulesData = responseSubmodules.data;

        // Filtrar submódulos para garantir que apenas os relacionados ao curso sejam exibidos
        const filteredSubmodules = submodulesData.filter((submodule) => submodule.courseId === Number(id));
        setSubmodules(filteredSubmodules);

        const lastAccessedModule = localStorage.getItem('lastAccessedModule');
        setStartModule(lastAccessedModule || '');

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    }

    getData();
  }, [id]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        {course?.img && <img src={course.img} alt={course.name} className="img" />}
        <div className="content">
          <div className="course-info">
            <div className="titulo">{course?.name} :</div>
            <div>{course?.description}</div>
          </div>
          {submodules.length > 0 && (
            <SubmoduleList>
              {submodules.map((submodule) => (
                <SubmoduleItem key={submodule.id}>
                  <FaPlay className="play-icon" /> {/* Ícone de play com espaçamento */}
                  {submodule.name}
                </SubmoduleItem>
              ))}
            </SubmoduleList>
          )}
        </div>
        <div className="button-container">
          <Link to={`/execute/${id}/${startModule}`} className="button-link">
            Iniciar
          </Link>
          <Link to="/training" className="button-link">
            Voltar
          </Link>
        </div>
      </CollaboratorContainer>
    </Container>
  );
}

Course.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

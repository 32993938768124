import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';
import PrivateRoute from './PrivateRoutes';

import EmpEdit from '../pages/EmpEdit';
import AdmEdit from '../pages/AdmEdit';
import AdmEditEmp from '../pages/AdmEditEmp';
import HomeAdmin from '../pages/HomeAdmin';
import HomeEmpresa from '../pages/HomeEmpresa';
import HomeColaborador from '../pages/HomeCollaborator';
import AdmPainel from '../pages/PainelADM';
import EmpPainel from '../pages/PainelEmpresa';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import Recovery from '../pages/Recovery';
import PasswordRecoveryPage from '../pages/RecoveryPassword';
import Edit from '../pages/EditUser/index';
import AdminDashboard from '../pages/admin';
import Confirm from '../pages/ConfirmAccount';
import Course from '../pages/Course';
import UserCourse from '../pages/UserCourse';
import NewCourse from '../pages/NewCourse';
import CourseManager from '../pages/CourseManager';
import NewSubmodule from '../pages/NewSubmodule';
import SubmoduleManager from '../pages/SubmoduleManager';
import NewQuiz from '../pages/NewQuiz';
import QuizManager from '../pages/QuizManager';
import CoursePermission from '../pages/CoursePermission';
import CourseAcces from '../pages/CourseAcces';
import NewContent from '../pages/NewContent';
import ContentManager from '../pages/ContentManager';
import MyCourse from '../pages/MyCourse';
import EmpPermission from '../pages/EmpPermission';
import Execute from '../pages/Execute';
import Training from '../pages/Training';
import LandingPage from '../pages/Home';
import Management from '../pages/Management';
import Sectors from '../pages/Sectors';
import Funcoes from '../pages/Funcao';
import ProfileImg from '../pages/ProfileImg';
import News from '../pages/News';
import Certification from '../pages/Certification';
import Suport from '../pages/Suport';
import CertificationEmp from '../pages/CertificationEmp';
import CreateAccount from '../pages/createAccount';
import AdmEditUsers from '../pages/AdmEditUsers';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/home" permittedDepartments={['colaborador']} component={HomeColaborador} />
      <PrivateRoute exact path="/training" permittedDepartments={['colaborador']} component={Training} />
      <PrivateRoute exact path="/user-course/:id" permittedDepartments={['colaborador']} component={UserCourse} />
      <PrivateRoute exact path="/execute/:id" permittedDepartments={['colaborador']} component={Execute} />
      <PrivateRoute exact path="/certification" permittedDepartments={['colaborador']} component={Certification} />
      <PrivateRoute exact path="/suport" permittedDepartments={['colaborador']} component={Suport} />

      <PrivateRoute exact path="/admin" permittedDepartments={['Admin']} component={AdminDashboard} />
      <PrivateRoute exact path="/admin-painel" permittedDepartments={['Admin']} component={AdmPainel} />
      <PrivateRoute exact path="/home-admin" permittedDepartments={['Admin']} component={HomeAdmin} />
      <PrivateRoute exact path="/new-course" permittedDepartments={['Admin']} component={NewCourse} />
      <PrivateRoute exact path="/new-content/:id" permittedDepartments={['Admin']} component={NewContent} />
      <PrivateRoute exact path="/course-permission" permittedDepartments={['Admin']} component={CoursePermission} />
      <PrivateRoute exact path="/courseAcces/:id/edit" permittedDepartments={['Admin']} component={CourseAcces} />
      <PrivateRoute exact path="/new-submodule/:id" permittedDepartments={['Admin']} component={NewSubmodule} />
      <PrivateRoute exact path="/course" permittedDepartments={['Admin']} component={Course} />
      <PrivateRoute exact path="/adm/:id/edit" permittedDepartments={['Admin']} component={AdmEdit} />
      <PrivateRoute exact path="/courseManager/:id/edit" permittedDepartments={['Admin']} component={CourseManager} />
      <PrivateRoute
        exact
        path="/submoduleManager/:id/edit"
        permittedDepartments={['Admin']}
        component={SubmoduleManager}
      />
      <PrivateRoute
        exact
        path="/contentSubmoduleManager/:id/edit"
        permittedDepartments={['Admin']}
        component={ContentManager}
      />
      <PrivateRoute exact path="/quizManager/:id/edit" permittedDepartments={['Admin']} component={QuizManager} />
      <PrivateRoute exact path="/new-quiz/:id" permittedDepartments={['Admin']} component={NewQuiz} />
      <PrivateRoute exact path="/adm/:id/users" permittedDepartments={['Admin']} component={AdmEditUsers} />
      <PrivateRoute exact path="/adm/:id/emp" permittedDepartments={['Admin']} component={AdmEditEmp} />

      <PrivateRoute exact path="/register" permittedDepartments={['Empresa']} component={Register} />
      <PrivateRoute exact path="/mycourse" permittedDepartments={['Empresa']} component={MyCourse} />
      <PrivateRoute exact path="/management" permittedDepartments={['Empresa']} component={Management} />
      <PrivateRoute exact path="/sectors" permittedDepartments={['Empresa']} component={Sectors} />
      <PrivateRoute exact path="/funcoes" permittedDepartments={['Empresa']} component={Funcoes} />

      <PrivateRoute exact path="/home-empresa" permittedDepartments={['Empresa']} component={HomeEmpresa} />
      <PrivateRoute exact path="/empresa-painel" permittedDepartments={['Empresa']} component={EmpPainel} />
      <PrivateRoute exact path="/emp/:id/edit" permittedDepartments={['Empresa']} component={EmpEdit} />
      <PrivateRoute exact path="/empAccess/:id/edit" permittedDepartments={['Empresa']} component={EmpPermission} />
      <PrivateRoute exact path="/certificationEmp" permittedDepartments={['Empresa']} component={CertificationEmp} />

      <MyRoute exact path="/login" component={Login} isClosed={false} />
      <MyRoute exact path="/CreateAccount" component={CreateAccount} isClosed={false} />
      <MyRoute exact path="/" component={LandingPage} isClosed={false} />
      <MyRoute exact path="/confirm/:token" component={Confirm} />
      <MyRoute exact path="/recuperar-senha" component={Recovery} isClosed={false} />
      <MyRoute path="/recovery" component={PasswordRecoveryPage} />
      <MyRoute exact path="/edit/" component={Edit} isClosed />
      <MyRoute exact path="/news" component={News} isClosed />
      <MyRoute exact path="/profile-img/" component={ProfileImg} isClosed />
      <MyRoute path="*" component={Page404} />
    </Switch>
  );
}

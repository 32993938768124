import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from './store';
import GlobalStyle from './styles/GlobalStyles';
import Header from './components/Header';
import Routes from './routes';
import Bug from './components/Bug';
import SessionTimeout from './components/TimeOut';

// Componente para decidir se o Header e o Bug devem ser renderizados
function Layout() {
  const location = useLocation();
  const shouldHideHeaderAndBug = /^\/execute/.test(location.pathname);

  return (
    <>
      {!shouldHideHeaderAndBug && <Header />}
      <Routes />
      {!shouldHideHeaderAndBug && <Bug />}
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Layout />
          <SessionTimeout timeout={1000 * 60 * 15} />
          <GlobalStyle />
          <ToastContainer autoClose={6000} className="toast-container" />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;

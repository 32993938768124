import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MyFont from '../../fonts/eurosoft.ttf';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  width: 80%;
  max-width: 900px;
  padding: 69px 20px;
  border-radius: 8px;

  h1 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 1%;
  }
  h4 {
    text-align: start;
    font-family: 'MyFont';
    margin-bottom: 2%;
  }

  p {
    font-family: 'MyFont';
    color: black;
    text-decoration: underline;
  }

  .button-container {
    text-align: start;
  }

  span {
    margin-right: 15px;
  }

  div + div {
    border-top: 1px solid #eee;
  }

  button {
    background-color: #ae1100;
    margin-top: 15px;
    font-family: 'MyFont';
    font-weight: bold;
  }

  .checkbox {
    appearance: none;
    background-color: #ae1100;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 4;
    height: 20px;
    margin: 5px 0px;
    margin-left: 60%;
    position: relative;
    width: 30px;
    cursor: pointer;

    &::before {
      bottom: -6px;
      content: '';
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &:checked {
      background-color: green;

      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;
      background-color: green;

      &:checked {
        background-color: green;
      }
    }

    &:focus:not(.focus-visible) {
      outline: 0;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }

    transition: all 100ms ease-out;
  }

  .expiration-date-cell {
    margin-left: 50%;
  }

  @media (max-width: 768px) {
    margin: 0 auto; /* Centraliza o conteúdo */
    width: 80%; /* Define a largura para 80% da tela */
  }

  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    font-family: 'MyFont';

    th {
      padding: 10px 0px 0px 0px;
    }
    td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      font-family: 'MyFont';
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    .edit-cell {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    margin-left: auto; /* Remova a margem à esquerda */
    margin-right: auto; /* Remova a margem à direita */
    display: block; /* Define como bloco para ocupar toda a largura disponível */
  }
`;

export const NewCollaborator = styled(Link)`
  display: block;
  padding: 20px 0 10px 0;
  font-family: 'MyFont';
`;

export const CenteredText = styled.h1`
  margin-top: 5%;
  text-align: center;
  margin-bottom: 50px;
  font-family: 'MyFont';
`;

import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { TextField, Button, TextareaAutosize } from '@material-ui/core';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { Form, Composition, CheckboxWrapper, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterQuiz(props) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const history = useHistory();
  const { id } = useParams();

  const submodule_id = id;

  const [formStep, setFormStep] = useState(1);
  const [questions, setQuestions] = useState([
    {
      id: uuidv4(),
      questionText: '',
      answers: [
        { id: uuidv4(), text: '', correct: false },
        { id: uuidv4(), text: '', correct: false },
      ],
    },
  ]);
  const [maxScore, setScore] = useState('');

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleQuestionTextChange = (index) => (e) => {
    const newQuestions = [...questions];
    newQuestions[index].questionText = e.target.value;
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (questionIndex, answerIndex) => (e) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers[answerIndex].text = e.target.value;
    setQuestions(newQuestions);
  };

  const handleCorrectChange = (questionIndex, answerIndex) => (e) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answers[answerIndex].correct = e.target.checked;
    setQuestions(newQuestions);
  };

  const handleScoreChange = (e) => {
    setScore(e.target.value);
  };

  const handleNextStep = () => {
    let formErrors = false;

    questions.forEach((question) => {
      if (!question.questionText.trim()) {
        formErrors = true;
        toast.error('Por favor, preencha o texto de todas as perguntas.');
      }

      question.answers.forEach((answer) => {
        if (!answer.text.trim()) {
          formErrors = true;
          toast.error('Por favor, preencha o texto de todas as respostas.');
        }
      });

      const isAnyAnswerCorrect = question.answers.some((answer) => answer.correct);
      if (!isAnyAnswerCorrect) {
        formErrors = true;
        toast.error('Por favor, marque pelo menos uma resposta como correta em cada pergunta.');
      }
    });

    if (!maxScore.trim()) {
      formErrors = true;
      toast.error('Por favor, preencha o score máximo.');
    } else if (parseInt(maxScore, 10) > questions.length) {
      formErrors = true;
      toast.error('Score máximo não pode ser maior que a quantidade de questões.');
    }

    if (!formErrors) {
      setFormStep(formStep + 1);
    }
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const removeQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  const formatQuestions = () => {
    return questions
      .map((question, index) => {
        const answers = question.answers
          .map((answer, i) => `Resposta ${i + 1}: ${answer.text} (Correta: ${answer.correct ? 'Sim' : 'Não'})`)
          .join(', ');
        return `Questão ${index + 1}: ${question.questionText}, Respostas: [${answers}]`;
      })
      .join('\n');
  };

  const addMoreQuestions = () => {
    setQuestions([
      ...questions,
      {
        id: uuidv4(),
        questionText: '',
        answers: [
          { id: uuidv4(), text: '', correct: false },
          { id: uuidv4(), text: '', correct: false },
        ],
      },
    ]);
  };

  const addMoreAnswers = (questionIndex) => {
    const newQuestions = [...questions];
    if (newQuestions[questionIndex].answers.length < 5) {
      newQuestions[questionIndex].answers.push({ id: uuidv4(), text: '', correct: false });
      setQuestions(newQuestions);
    } else {
      toast.error('Você só pode adicionar até 5 respostas.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErrors = false;

    questions.forEach((question) => {
      if (!question.questionText.trim()) {
        formErrors = true;
        toast.error('Por favor, preencha o texto de todas as perguntas.');
      }

      question.answers.forEach((answer) => {
        if (!answer.text.trim()) {
          formErrors = true;
          toast.error('Por favor, preencha o texto de todas as respostas.');
        }
      });

      const isAnyAnswerCorrect = question.answers.some((answer) => answer.correct);
      if (!isAnyAnswerCorrect) {
        formErrors = true;
        toast.error('Por favor, marque pelo menos uma resposta como correta em cada pergunta.');
      }
    });

    if (!maxScore.trim()) {
      formErrors = true;
      toast.error('Por favor, preencha o score máximo.');
    } else if (parseInt(maxScore, 10) > questions.length) {
      formErrors = true;
      toast.error('Score máximo não pode ser maior que a quantidade de questões.');
    }

    if (!formErrors) {
      const { handleClose } = props;
      dispatch(
        actions.registerQuizRequest({
          submodule_id,
          questions,
          maxScore,
          history,
        }),
      );
      handleClose();
    }
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Crie uma Nova Avaliação</h1>
          <h4>Inclua uma nova avaliação ao submódulo</h4>
          {formStep === 1 && (
            <>
              {questions.map((question, questionIndex) => (
                <div key={question.id}>
                  <TextareaAutosize
                    className="textarea"
                    InputProps={{ disableUnderline: true }}
                    label={`Enunciado da Questão ${questionIndex + 1}`}
                    value={question.questionText}
                    onChange={handleQuestionTextChange(questionIndex)}
                    placeholder={`Enunciado da Questão ${questionIndex + 1}`}
                    fullWidth
                    margin="normal"
                  />
                  {question.answers.map((answer, answerIndex) => (
                    <div key={answer.id}>
                      <TextField
                        className="text-field"
                        InputProps={{ disableUnderline: true }}
                        label={`Resposta ${answerIndex + 1}`}
                        value={answer.text}
                        onChange={handleQuestionChange(questionIndex, answerIndex)}
                        placeholder={`Resposta ${answerIndex + 1}`}
                        fullWidth
                        margin="normal"
                      />
                      <CheckboxWrapper>
                        <label htmlFor={`checkbox-${answer.id}`}>
                          <input
                            id={`checkbox-${answer.id}`}
                            className="chebox-input"
                            type="checkbox"
                            checked={answer.correct}
                            onChange={handleCorrectChange(questionIndex, answerIndex)}
                          />
                          Correta
                        </label>
                      </CheckboxWrapper>
                    </div>
                  ))}
                  {question.answers.length < 5 && (
                    <button type="button" onClick={() => addMoreAnswers(questionIndex)}>
                      Adicionar mais respostas
                    </button>
                  )}
                  {questions.length > 1 && (
                    <button type="button" onClick={() => removeQuestion(questionIndex)}>
                      Remover questão
                    </button>
                  )}
                </div>
              ))}

              <button type="button" onClick={addMoreQuestions}>
                Adicionar mais questões
              </button>

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Score Máximo"
                value={maxScore}
                onChange={handleScoreChange}
                placeholder="Score Máximo"
                fullWidth
                margin="normal"
              />

              <button className="Button" type="button" onClick={handleNextStep}>
                Próximo
              </button>
            </>
          )}

          {formStep === 2 && (
            <Composition>
              <h1>Confirme conteúdo</h1>

              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Questões"
                value={formatQuestions()}
                multiline
                disabled
                rows={questions.filter((q) => q.answers.some((a) => a.text.trim() !== '')).length} // Atualiza o número de linhas para questões não vazias
                margin="normal"
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Descrição"
                value={maxScore}
                disabled
                margin="normal"
              />

              <Button className="button" type="button" onClick={handlePrevStep} variant="contained" disableRipple>
                Voltar
              </Button>
              <Button className="button" type="submit" variant="contained" color="primary" disableRipple>
                Finalizar
              </Button>
            </Composition>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

RegisterQuiz.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Form, InputWrapper, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function RegisterAdmin(props) {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.isLoading);
  const { history } = props;

  const [formErrors, setFormErrors] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [cnpj, setCnpj] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [cargo, setCargo] = useState('');
  const [email, setEmail] = useState('');
  const [departamento, setDepartamento] = useState('');
  const randomPassword = Math.random().toString(36).slice(-8);

  function cleanCnpj(inputCnpj) {
    return inputCnpj.replace(/\D/g, '');
  }

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleCnpjChange = (e) => {
    setCnpj(e.target.value);
  };

  const handleNomeChange = (e) => {
    setNome(e.target.value);
  };
  const handleSobrenomeChange = (e) => {
    setSobrenome(e.target.value);
  };
  const handleCargoChange = (e) => {
    setCargo(e.target.value);
  };

  const handleDepartamentoChange = (e) => {
    setDepartamento(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  useEffect(() => {
    let errors = false;

    switch (formStep) {
      case 1:
        if (!departamento || departamento === 'Select') {
          errors = true;
        }
        break;
      case 2:
        if (departamento === 'Admin') {
          if (!nome || nome.length < 3 || nome.length > 255) {
            errors = true;
          }
          if (!sobrenome || sobrenome.length < 3 || sobrenome.length > 255) {
            errors = true;
          }
          if (!cargo || cargo.length < 3 || cargo.length > 255) {
            errors = true;
          }
          if (!email || !isEmail(email)) {
            errors = true;
          }
          // Validar outros campos específicos do Admin
        } else if (departamento === 'Empresa') {
          // Lógica de validação para campos específicos da Empresa
          if (!cnpj || cnpj.length < 14 || cnpj.length > 18) {
            errors = true;
          }
        }
        break;
      default:
        break;
    }

    setFormErrors(errors);
  }, [formStep, departamento, nome, sobrenome, cargo, email, cnpj]);

  const handleSubmit = (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (formErrors) return;

    const cleanedCnpj = cleanCnpj(cnpj);

    if (departamento === 'Empresa') {
      dispatch(
        actions.registerEnterpriseRequest({
          cnpj: cleanedCnpj,
        }),
      );
    } else if (departamento === 'Admin') {
      dispatch(
        actions.registerRequest({
          cnpj,
          nome,
          sobrenome,
          email,
          cargo,
          departamento,
          password: randomPassword,
          history,
        }),
      );
    }

    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />

      <Form onSubmit={handleSubmit}>
        <div className="box-container">
          <h1>Crie uma nova conta</h1>
          <h4>Inclua novos usuários no sistema</h4>
          {formStep === 1 && (
            <>
              <FormControl className="form-control">
                <InputLabel className="texto" id="departamento-label">
                  <p>Perfil</p>
                </InputLabel>
                <Select
                  labelId="departamento-label"
                  id="departamento"
                  value={departamento}
                  onChange={handleDepartamentoChange}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value="Select">
                    <p style={{ fontFamily: 'MyFont' }}>Selecione o Perfil</p>
                  </MenuItem>
                  <MenuItem value="Admin">
                    <p style={{ fontFamily: 'MyFont' }}>Admin</p>
                  </MenuItem>
                  <MenuItem value="Empresa">
                    <p style={{ fontFamily: 'MyFont' }}>Empresa</p>
                  </MenuItem>
                </Select>
                <FormHelperText>Selecione o Perfil</FormHelperText>
              </FormControl>
              {formErrors ? null : (
                <Button type="button" onClick={handleNextStep}>
                  Próximo
                </Button>
              )}
            </>
          )}

          {formStep === 2 && (
            <div className="form">
              {departamento === 'Empresa' && (
                <div className="form">
                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{
                        disableUnderline: true,
                        inputComponent: InputMask,
                        inputProps: {
                          mask: '99.999.999/9999-99',
                          value: cnpj,
                          onChange: handleCnpjChange,
                          maskChar: ' ',
                        },
                      }}
                      id="cnpj"
                      label="CNPJ *"
                      value={cnpj}
                      onChange={handleCnpjChange}
                    />
                  </InputWrapper>
                </div>
              )}
              {departamento === 'Admin' && (
                <div>
                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="nome"
                      label="Nome *"
                      value={nome}
                      onChange={handleNomeChange}
                      placeholder="Seu nome"
                    />

                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="sobrenome"
                      label="Sobrenome"
                      value={sobrenome}
                      onChange={handleSobrenomeChange}
                      placeholder="Seu sobrenome"
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="cargo"
                      label="Cargo"
                      value={cargo}
                      onChange={handleCargoChange}
                      placeholder="Seu cargo"
                    />

                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="email"
                      label="E-mail *"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Seu e-mail"
                    />
                  </InputWrapper>
                </div>
              )}
              <div>
                <Button variant="contained" color="primary" onClick={handlePrevStep}>
                  Voltar
                </Button>
                {formErrors ? null : (
                  <Button className="button" type="button" onClick={handleNextStep}>
                    Próximo
                  </Button>
                )}
              </div>
            </div>
          )}

          {formStep === 3 && (
            <>
              <Typography variant="h5">
                <h4>Confirme dados:</h4>
              </Typography>

              {departamento === 'Empresa' && (
                <div>
                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="cnpj"
                      label="CNPJ"
                      value={cnpj}
                      onChange={handleCnpjChange}
                      disabled
                    />
                  </InputWrapper>
                </div>
              )}

              {departamento === 'Admin' && (
                <div>
                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="nome"
                      label="Nome"
                      value={nome}
                      onChange={handleNomeChange}
                      placeholder="Seu nome"
                      disabled
                    />

                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="sobrenome"
                      label="Sobrenome"
                      value={sobrenome}
                      onChange={handleSobrenomeChange}
                      placeholder="Seu sobrenome"
                      disabled
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="cargo"
                      label="Cargo"
                      value={cargo}
                      onChange={handleCargoChange}
                      placeholder="Seu cargo"
                      disabled
                    />

                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      id="email"
                      label="E-mail"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Seu e-mail"
                      disabled
                    />
                  </InputWrapper>
                </div>
              )}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePrevStep}
                  disableRipple // Adicione esta propriedade
                >
                  Voltar
                </Button>
                {formErrors ? null : (
                  <Button variant="contained" color="primary" disableRipple onClick={handleSubmit}>
                    Criar
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </Form>
    </Bloco>
  );
}

RegisterAdmin.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  handleClose: PropTypes.func.isRequired,
};

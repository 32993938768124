import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 95vh;
  height: 85vh;
  align-items: flex-start; /* Alinha itens ao topo */
  justify-content: flex-start; /* Alinha itens ao topo */
  flex-direction: column;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 10px 20px;
  margin: -10px 25px;
  scrollbar-width: none;
`;

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: 30px; /* Ajuste para a margem superior desejada */
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: 0 auto; /* Centraliza horizontalmente */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  h1 {
    margin: -50px 0px; /* Ajuste para a margem superior */
    font-size: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  input {
    width: 480px;
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 30px 20px 10px 0px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  select {
    width: 100%;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 80vw;
  }
`;

export const Form = styled.form`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  margin-top: 30px; /* Ajuste para a margem superior desejada */
  font-family: 'MyFont';
  scrollbar-width: none;
  font-size: 20px;
  max-width: 580px;
  margin: 0px 0px; /* Centraliza horizontalmente */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  h1 {
    margin: -35px 0px; /* Ajuste para a margem superior */
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1%;
    font-family: 'MyFont';
    align-items: start;
  }
  h4 {
    margin: 5px 0px;
    font-size: 18px;
    margin-bottom: 2%;
  }
  h3 {
    margin: 25px 20px 10px 0px;
    font-size: 18px;
    margin-bottom: 2%;
    margin-bottom: -25px;
  }
  h2 {
    font-size: 18px;
    margin: 15px 0px;
  }
  p {
    margin: -5px -15px;
    font-size: 15px;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 15px;
    font-family: 'MyFont';
  }

  button {
    margin-left: 10px;
    margin-top: 20px;
    width: 150px;
    font-family: 'MyFont';
    color: white;
    background-color: #ae1100;
  }

  .score-input {
    max-width: 200px;
  }

  input {
    width: 480px;
    height: 50px;
    font-size: 15px;
    border: 1px solid #ddd;
    padding: 10px;
    font-family: 'MyFont';
    border-radius: 4px;
    margin: 0px 20px 10px 0px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  select {
    width: 100%;
    margin-top: 5px;
    font-family: 'MyFont';

    &:focus {
      border: 1px solid #ae1100;
    }
  }
  @media (max-width: 425px) {
    overflow-y: auto;
    max-height: 100vh;
    width: 80vw;
  }
  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    margin-top: 40px;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 67%;
    background-color: white;
  }
  .checkbox {
    width: 20px;
    padding: 5px;
    margin: 0px -5px;
    cursor: pointer;
  }
`;

export const CenteredText = styled.h1`
  margin: 0px 0px;
  margin-bottom: 20px;
  font-family: 'MyFont';
`;

export const StyledContainer = styled.div`
  display: flex;
  font-family: 'MyFont';
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px 0px;
  button {
    margin-left: 10px;
    margin-top: 20px;
    width: 150px;
    color: white;
    font-family: 'MyFont';
    background-color: #ae1100;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'MyFont';
  gap: 20px;
  margin: 0px 0px;
  width: 100%;
`;

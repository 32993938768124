import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import { Popup } from './styled';
import axios from '../../services/axios';

export default function UserPopup({ userList, courseId, onClose }) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      }
      return [...prevSelectedUsers, userId];
    });
  };

  const handleSave = async () => {
    try {
      await Promise.all(
        selectedUsers.map((userId) =>
          axios.post('/usercourse', {
            userId,
            courseId,
            hasAccess: false,
            completionPercentage: 0,
          }),
        ),
      );
      onClose(); // Fecha o modal após salvar
      window.location.reload(); // Atualiza a página
    } catch (error) {
      console.error(error);
    }
  };

  const handleExit = () => {
    onClose(); // Fecha o modal quando "Voltar" é clicado
  };

  const isUserListEmpty = userList.length === 0;

  return (
    <Popup>
      <FaTimes className="Button" onClick={handleExit} /> {/* Fechar modal ao clicar no ícone */}
      <div className="box-container">
        <h1>Lista de Usuários</h1>
        {isUserListEmpty ? (
          <h3>Não há usuários disponíveis para permissão no momento</h3>
        ) : (
          userList.map((user) => (
            <div key={user.id} className="user-item">
              <span className="span">
                {user.nome} {user.sobrenome} {/* Inclui o sobrenome */}
              </span>
              <input
                type="checkbox"
                checked={selectedUsers.includes(user.id)}
                onChange={() => handleSelectUser(user.id)}
              />
            </div>
          ))
        )}
        <button type="button" onClick={isUserListEmpty ? handleExit : handleSave}>
          {isUserListEmpty ? 'Voltar' : 'Salvar'}
        </button>
      </div>
    </Popup>
  );
}

UserPopup.propTypes = {
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nome: PropTypes.string.isRequired,
      sobrenome: PropTypes.string.isRequired, // Adiciona a validação para o sobrenome
    }),
  ).isRequired,
  courseId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import { FaYoutubeSquare, FaFileAlt, FaBookReader } from 'react-icons/fa'; // Importe os ícones necessários
import { get } from 'lodash';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';

import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Navbar from '../../components/NavbarCurse';
import { StyledContainer, StyledSection, Sidebar, MainContent, StyledButton, Container } from './styled';

export default function SubmoduleEdit({ match }) {
  const history = useHistory();
  const userId = useSelector((state) => state.auth.user.id);
  const courseId = get(match, 'params.id', '');
  const timeoutDuration = 300000;

  function getYoutubeVideoId(url) {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    const matches = regex.exec(url);
    if (matches) {
      return matches[1];
    }
    return url;
  }

  function parseQuestions(questions) {
    try {
      return JSON.parse(JSON.parse(questions));
    } catch (e1) {
      try {
        return JSON.parse(questions);
      } catch (e2) {
        return [];
      }
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submodules, setSubmodules] = useState([]);
  const [selectedSubmodule, setSelectedSubmodule] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [passedSubmodules, setPassedSubmodules] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [expandedSubmoduleIds, setExpandedSubmoduleIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedContent, setSelectedContent] = useState(null);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [contentVisible, setContentVisible] = useState(true);
  const [quizPassed, setQuizPassed] = useState(false);
  const [finalMessage, setFinalMessage] = useState('');
  const [quizScore, setQuizScore] = useState(0);
  const [setUnlockedSubmodules] = useState([]);
  const [isInTimeout, setIsInTimeout] = useState(false);
  const [timeoutEndTime, setTimeoutEndTime] = useState(null);
  const [timeoutRemaining, setTimeoutRemaining] = useState(0);
  useEffect(() => {
    async function fetchTimeout() {
      try {
        const response = await axios.get('/usersubmodule');
        const filteredData = response.data.filter(
          (item) => item.userId === Number(userId) && item.submodule_id === Number(selectedSubmodule.id),
        );

        const userSubmodule = filteredData[0];
        if (userSubmodule && userSubmodule.timeout) {
          setIsInTimeout(true);
          setTimeoutEndTime(new Date(userSubmodule.timeout).getTime());
        }
      } catch (error) {
        console.error('Erro ao buscar o timeout do usuário:', error);
      }
    }

    if (selectedSubmodule) {
      fetchTimeout();
    }
  }, [selectedSubmodule]);

  useEffect(() => {
    let interval;

    if (timeoutEndTime) {
      interval = setInterval(() => {
        const now = Date.now();
        const timeRemaining = Math.max(timeoutEndTime - now, 0);
        setTimeoutRemaining(timeRemaining);

        if (timeRemaining === 0) {
          setIsInTimeout(false);
          setTimeoutEndTime(null);
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeoutEndTime]);

  useEffect(() => {
    if (!courseId) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      setIsLoading(true);
      try {
        const responseSubmodules = await axios.get(`/submodule`);
        const submodulesData = responseSubmodules.data.filter(
          (submodule) => submodule.courseId && submodule.courseId === Number(courseId),
        );

        const submodulesWithContentAndQuiz = await Promise.all(
          submodulesData.map(async (submodule) => {
            const responseContents = await axios.get(`/contentsubmodule`);
            const contents = responseContents.data.filter(
              (content) => content.submodule_id && content.submodule_id === Number(submodule.id),
            );

            const responseQuiz = await axios.get(`/quiz`);
            const submoduleQuizzes = responseQuiz.data.filter(
              (quiz) => quiz.submodule_id && quiz.submodule_id === Number(submodule.id),
            );

            return { ...submodule, contents, quizzes: submoduleQuizzes };
          }),
        );

        setSubmodules(submodulesWithContentAndQuiz);

        const responsePassedSubmodules = await axios.get('/usersubmodule/passed', { params: { userId } });
        const fetchedPassedSubmodules = responsePassedSubmodules.data;
        setPassedSubmodules(fetchedPassedSubmodules);

        // Determina quais submódulos estão desbloqueados
        const fetchedUnlockedSubmodules = submodulesWithContentAndQuiz.filter((submodule, index) => {
          if (index === 0) {
            return true; // O primeiro módulo está sempre desbloqueado
          }

          const previousSubmodule = submodulesWithContentAndQuiz[index - 1];
          const hasPassedPreviousSubmodule = fetchedPassedSubmodules.some(
            (passedSubmodule) => passedSubmodule.submodule_id === previousSubmodule.id && passedSubmodule.passedQuiz,
          );
          return hasPassedPreviousSubmodule;
        });

        setUnlockedSubmodules(fetchedUnlockedSubmodules);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }
    getData();
  }, [courseId, history, userId]);

  const filteredSubmodules = submodules;

  function calculateCompletionPercentage() {
    const totalQuizzes = submodules.reduce((acc, submodule) => acc + submodule.quizzes.length, 0);
    const completedQuizzes = passedSubmodules.reduce((acc, passedSubmodule) => {
      const submodule = submodules.find((s) => s.id === passedSubmodule.submodule_id);
      return acc + (submodule ? submodule.quizzes.length : 0);
    }, 0);

    // Calcula a porcentagem e garante que não exceda 100%
    const percentage = (completedQuizzes + 1 / totalQuizzes) * 100;
    return Math.min(percentage, 100); // Garante que a porcentagem não exceda 100%
  }

  async function checkQuizAnswers(quiz) {
    const correctAnswersCount = Object.values(selectedAnswers).filter((answer) => answer.correct).length;
    const { maxScore } = quiz;
    const lastSubmoduleQuiz = submodules[submodules.length - 1].quizzes[0];
    const isLastQuiz = lastSubmoduleQuiz ? lastSubmoduleQuiz.id === quiz.id : false;
    if (isInTimeout) {
      toast.error(`Você está em timeout. Tente novamente em ${Math.ceil(timeoutRemaining / 1000)} segundos.`);
      return;
    }

    setQuizScore(correctAnswersCount); // Atualiza a pontuação do quiz

    if (correctAnswersCount >= maxScore && isLastQuiz) {
      setOpenModal(true);
    }
    if (correctAnswersCount >= maxScore) {
      setQuizPassed(true);
      setOpenModal(true);
      if (isLastQuiz) {
        axios.put(`/rhusers/${userId}`, {
          graduacao: +1,
        });
        setFinalMessage('Parabéns por essa graduação! Você concluiu o treinamento e ganhara uma mudança de faixa!.');
      } else {
        setFinalMessage('Parabéns! Você passou no quiz!');
      }

      const idUserCourse = await axios.get('/usercourse');
      console.log('idUserCourse data:', idUserCourse.data);
      const mycourse = idUserCourse.data.find(
        (course) => String(course.userId) === String(userId) && String(course.courseId) === String(courseId),
      );
      console.log('mycourse:', mycourse);

      // Add the currently completed submodule to the list of completed submodules
      const newPassedSubmodules = [...passedSubmodules, { submodule_id: selectedSubmodule.id, passedQuiz: true }];

      // Calculate completion percentage based on the new list of completed submodules
      const percentage = calculateCompletionPercentage(newPassedSubmodules.length, submodules.length);
      console.log('Completion Percentage:', percentage);

      axios.put(`/usercourse/${mycourse.id}`, {
        completionPercentage: percentage,
      });

      axios
        .post('/usersubmodule', {
          userId,
          submodule_id: selectedSubmodule.id,
          quizScore: correctAnswersCount,
          passedQuiz: true,
        })
        .then(() => {
          setPassedSubmodules(newPassedSubmodules);
        });
    } else {
      setIsInTimeout(true);
      const newTimeoutEndTime = new Date(Date.now() + timeoutDuration).toISOString();
      setTimeoutEndTime(Date.now() + timeoutDuration);

      // Verifica se o registro já existe
      try {
        const response = await axios.get('/usersubmodule');
        const filteredData = response.data.filter(
          (item) => item.userId === Number(userId) && item.submodule_id === Number(selectedSubmodule.id),
        );
        if (filteredData) {
          // Atualiza o registro existente
          await axios.put(`/usersubmodule/${filteredData[0].id}`, {
            timeout: newTimeoutEndTime,
          });
        } else {
          // Cria um novo registro
          await axios.post('/usersubmodule', {
            userId,
            submodule_id: selectedSubmodule.id,
            timeout: newTimeoutEndTime,
          });
        }
      } catch (error) {
        console.error('Erro ao atualizar o timeout no banco de dados:', error);
      }
      toast.error(`Você acertou ${correctAnswersCount} perguntas. A pontuação necessária para passar é ${maxScore}.`);
    }
  }
  const handleCloseModal = () => {
    setOpenModal(false); // Modificação: Modal fecha apenas quando usuário clica no botão
    window.location.reload(); // Recarregar a página após fechar o modal
  };

  const handleAccordionChange = (submoduleId) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedSubmoduleIds((prevIds) => [...prevIds, submoduleId]);
    } else {
      setExpandedSubmoduleIds((prevIds) => prevIds.filter((id) => id !== submoduleId));
    }
  };
  const handleToggleAccordion = () => {
    console.log('Toggling accordion', accordionOpen);
    setAccordionOpen((prevState) => !prevState);
  };

  const handleToggleContent = () => {
    setContentVisible((prevState) => !prevState);
  };

  const handleNextContent = () => {
    console.log('Next content button clicked');

    if (!selectedContent || !selectedSubmodule) return;

    // Obtém o índice do conteúdo atual
    const currentContentIndex = selectedSubmodule.contents.findIndex((content) => content.id === selectedContent.id);
    const nextContentIndex = currentContentIndex + 1;

    if (nextContentIndex < selectedSubmodule.contents.length) {
      const nextContent = selectedSubmodule.contents[nextContentIndex];

      // Verifica o tipo de conteúdo e define o estado adequadamente
      setSelectedContent(nextContent);
    } else {
      // Exibe uma mensagem informando que todos os conteúdos foram completados
      toast.info('Você completou todos os conteúdos deste submódulo.');
    }
  };

  // **Função para voltar ao conteúdo anterior**
  const handlePreviousContent = () => {
    if (!selectedContent || !selectedSubmodule) return;

    const currentContentIndex = selectedSubmodule.contents.findIndex((content) => content.id === selectedContent.id);
    const previousContentIndex = currentContentIndex - 1;

    if (previousContentIndex >= 0) {
      const previousContent = selectedSubmodule.contents[previousContentIndex];
      setSelectedContent(previousContent);
    } else {
      toast.info('Este é o primeiro conteúdo do submódulo.');
    }
  };
  const handleTakeQuiz = (currentSubmodule) => {
    const hasPassedQuiz = passedSubmodules.some(
      (passedSubmodule) => passedSubmodule.submodule_id === currentSubmodule.id && passedSubmodule.passedQuiz,
    );

    if (hasPassedQuiz) {
      toast.info('Você já passou neste quiz e não pode fazer isso novamente.');
      return;
    }

    // Verifique se o submódulo anterior foi concluído
    const previousSubmodule = submodules.find((sm) => sm.id === currentSubmodule.id - 1);
    const previousSubmoduleCompleted = previousSubmodule
      ? passedSubmodules.some(
          (passedSubmodule) => passedSubmodule.submodule_id === previousSubmodule.id && passedSubmodule.passedQuiz,
        )
      : true;

    if (!previousSubmoduleCompleted) {
      toast.info('Você deve completar o submódulo anterior antes de acessar este.');
      return;
    }

    // Agora, a lógica de verificação já foi executada, e podemos seguir com a definição do estado e abrir o conteúdo do quiz
    setSelectedSubmodule(currentSubmodule);
    setSelectedQuiz(currentSubmodule.quizzes[0]);
    setSelectedContent({ ...currentSubmodule.quizzes[0], type: 'quiz' });
    handleToggleContent();
    setCurrentQuestionIndex(0);
  };
  useEffect(() => {
    if (selectedSubmodule && selectedQuiz) {
      handleToggleContent();
    }
  }, [selectedSubmodule, selectedQuiz]);
  return (
    <Container>
      <Navbar
        onPrevious={() => history.goBack()}
        onNext={handleNextContent}
        onPreviousContent={handlePreviousContent}
        hasPrevious
        hasNext={
          selectedSubmodule &&
          selectedSubmodule.contents &&
          selectedContent !== selectedSubmodule.contents[selectedSubmodule.contents.length - 1]
        }
        hasPreviousContent={
          selectedSubmodule && selectedSubmodule.contents && selectedContent !== selectedSubmodule.contents[0]
        }
        onToggleSidebar={handleToggleAccordion}
      />
      <Loading isLoading={isLoading} />
      <StyledContainer>
        <Sidebar>
          {accordionOpen &&
            filteredSubmodules.map((submodule) => (
              <Accordion
                key={submodule.id}
                expanded={expandedSubmoduleIds.includes(submodule.id)}
                onChange={handleAccordionChange(submodule.id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <div className="borde" />
                  <h2
                    className={
                      passedSubmodules.some(
                        (passedSubmodule) =>
                          passedSubmodule.submodule_id === submodule.id && passedSubmodule.passedQuiz,
                      )
                        ? 'green-text'
                        : ''
                    }
                  >
                    {submodule.name}
                  </h2>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className="table" component={Paper}>
                    <Table className="table">
                      <TableHead className="table">
                        <TableRow>
                          <TableCell className="table" style={{ fontFamily: 'MyFont' }}>
                            Conteúdo
                          </TableCell>
                          <TableCell align="right" />
                        </TableRow>
                      </TableHead>
                      <TableBody className="table" style={{ fontFamily: 'MyFont' }}>
                        {submodule.contents.map((content) => (
                          <TableRow key={content.id} style={{ fontFamily: 'MyFont' }}>
                            <TableCell className="table" component="th" scope="row" style={{ fontFamily: 'MyFont' }}>
                              {content.name}
                            </TableCell>
                            <TableCell className="table" align="right">
                              {content.textContent && (
                                <StyledButton
                                  onClick={() => {
                                    setSelectedSubmodule(submodule);
                                    setSelectedContent({ ...content, type: 'text' });
                                  }}
                                >
                                  <FaBookReader /> Ver Texto
                                </StyledButton>
                              )}
                              {content.videoUrl && (
                                <StyledButton
                                  onClick={() => {
                                    setSelectedSubmodule(submodule);
                                    setSelectedContent({ ...content, type: 'video' });
                                  }}
                                >
                                  <FaYoutubeSquare /> Assistir Vídeo
                                </StyledButton>
                              )}
                              {content.documentUrl && (
                                <StyledButton
                                  onClick={() => {
                                    setSelectedSubmodule(submodule);
                                    setSelectedContent({ ...content, type: 'document' });
                                  }}
                                >
                                  <FaFileAlt /> Visualizar Documento
                                </StyledButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <StyledButton
                              className="button"
                              onClick={() => handleTakeQuiz(submodule)} // Chame a função handleTakeQuiz com o submódulo
                            >
                              Realizar Avaliação!
                            </StyledButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
        </Sidebar>

        <MainContent isSidebarOpen={accordionOpen}>
          <StyledSection>
            {selectedSubmodule && selectedContent && contentVisible && (
              <div>
                <h2>{selectedContent.name}</h2>

                {selectedContent.type === 'text' && (
                  <div>
                    <p>{selectedContent.textContent}</p>
                  </div>
                )}

                {selectedContent && selectedContent.videoUrl && (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(selectedContent.content)}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}

                {selectedContent && selectedContent.documentUrl && (
                  <iframe
                    src={selectedContent.documentUrl}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title={selectedContent.name}
                  />
                )}
                {selectedContent.type === 'quiz' && (
                  <>
                    {parseQuestions(selectedContent.questions)
                      .slice(currentQuestionIndex, currentQuestionIndex + 1)
                      .map((question) => (
                        <div key={question.id}>
                          <p className="question">{`${currentQuestionIndex + 1}. ${question.questionText}`}</p>
                          {question.answers.map((answer) => (
                            <div key={answer.id}>
                              <input
                                className="radio"
                                type="radio"
                                id={`answer-${answer.id}`}
                                name={`question-${question.id}`}
                                value={answer.id}
                                checked={selectedAnswers[`question-${question.id}`]?.id === answer.id}
                                onChange={(e) =>
                                  setSelectedAnswers({
                                    ...selectedAnswers,
                                    [e.target.name]: answer,
                                  })
                                }
                              />
                              <label htmlFor={`answer-${answer.id}`}>{answer.text}</label>
                            </div>
                          ))}
                        </div>
                      ))}
                    {currentQuestionIndex > 0 && (
                      <button
                        className="button-quiz"
                        type="button"
                        onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
                      >
                        Voltar
                      </button>
                    )}
                    {currentQuestionIndex < parseQuestions(selectedContent.questions).length - 1 ? (
                      <button
                        className="button-quiz"
                        type="button"
                        onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
                      >
                        Próximo
                      </button>
                    ) : (
                      <button className="button-quiz" type="button" onClick={() => checkQuizAnswers(selectedQuiz)}>
                        Enviar resposta
                      </button>
                    )}
                    <p>
                      {`Perguntas respondidas: ${Object.keys(selectedAnswers).length} /
                        ${parseQuestions(selectedContent.questions).length}`}
                    </p>
                  </>
                )}
              </div>
            )}
          </StyledSection>
        </MainContent>
      </StyledContainer>
      <Modal
        open={openModal}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
            backgroundColor: 'white',
            boxShadow: 24,
            padding: 16,
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ flex: '1 0 auto' }}>
            <h2 id="modal-modal-title">{selectedQuiz ? selectedQuiz.title : 'Quiz'}</h2>
            {selectedQuiz && currentQuestionIndex < selectedQuiz.questions.length && (
              <>
                <p id="modal-modal-description">{selectedQuiz.questions[currentQuestionIndex].question}</p>
                <div>
                  <ul>
                    {selectedQuiz.questions[currentQuestionIndex].options &&
                      selectedQuiz.questions[currentQuestionIndex].options.map((option) => (
                        <li key={option.id}>
                          <input
                            className="radio"
                            type="radio"
                            name="quiz-option"
                            value={option.option}
                            onChange={() =>
                              setSelectedAnswers({
                                ...selectedAnswers,
                                [currentQuestionIndex]: { ...option, correct: option.isCorrect },
                              })
                            }
                          />
                          {option.option}
                        </li>
                      ))}
                  </ul>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    if (currentQuestionIndex + 1 === selectedQuiz.questions.length) {
                      checkQuizAnswers(selectedQuiz);
                    } else {
                      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
                    }
                  }}
                >
                  {currentQuestionIndex + 1 === selectedQuiz.questions.length ? 'Finalizar Quiz' : 'Próxima Pergunta'}
                </button>
              </>
            )}
            {quizPassed && (
              <div>
                <h2>Avaliação Finalizada!</h2>
                <p>{finalMessage}</p>
                <p>Sua pontuação: {quizScore}</p>
              </div>
            )}
          </div>
          <StyledButton
            onClick={handleCloseModal}
            style={{ alignSelf: 'flex-start', marginTop: '16px', textAlign: 'center' }} // Ajusta o estilo do botão
          >
            Fechar
          </StyledButton>
        </div>
      </Modal>
    </Container>
  );
}
SubmoduleEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

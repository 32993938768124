import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';

export const Composition = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  display: flex;
  margin-top: -15%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    margin: -70px 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-family: 'MyFont';
    align-items: start;
    @media (max-width: 1440px) {
      font-size: large;
    }
  }
  .logo {
    margin-left: 10%;
    width: 350px;
    height: 350px;
    max-width: 550px; // Ajuste este valor conforme necessário
    max-height: 550px;
    margin: 0px 0px;
    padding: 1px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 482px) {
    margin-top: -30%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    font-family: 'MyFont';
    flex-direction: column;
    margin-bottom: 20px;
    margin: 20px;
    margin-left: 15px;
  }

  button {
    margin-left: -1px;
    margin-top: 15%;
    width: 150px;
    color: white;
    font-family: 'MyFont';
    font-weight: bold;
    background-color: #ae1100;
  }

  input {
    width: 100%;
    height: 50px;
    font-size: 18px;
    border: 1px solid #ddd;
    padding: 0 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: 'MyFont';
    &:focus {
      border: 1px solid #ae1100;
    }
  }

  @media (max-width: 425px) {
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .logo {
      width: 100%;
    }
  }

  a {
    margin-bottom: 20px;
    height: 40px;
    padding: 0 10px;
    border-radius: 4px;
  }
`;
export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff; /* Fundo branco */
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0; /* Mantém abaixo de Nav e SecondNav */
`;

import styled from 'styled-components';
import MyFont from '../../fonts/eurosoft.ttf';
import breakpoints from '../../styles/breakpoints';

export const CollaboratorContainer = styled.div`
  @font-face {
    font-family: 'MyFont';
    src: url(${MyFont}) format('truetype');
    font-style: normal;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;

  @media ${breakpoints.lg} {
    height: 100%;
    width: 150%;
  }

  .img {
    position: absolute;
    top: 4%;
    width: 160%;
    margin-left: -1.7%;
    height: 40vh;

    @media ${breakpoints.xl} {
      height: 40%;
      width: 102%;
      position: absolute;
      object-fit: contain;
      margin-top: -2%;
      padding: 0px 0px 0 0px;
    }

    @media ${breakpoints.lg} {
      height: 40%;
      width: 102%;
      position: absolute;
      object-fit: contain;
      margin-top: 1%;
      padding: 0px 0px 0 0px;
    }

    @media (max-width: 1524px) {
      top: 0%;
      width: 104%;
      margin-top: 0%;
      margin-left: -2.3%;
    }

    @media (max-width: 1055px) {
      top: 0.5%;
      width: 105%;
      margin-top: -1%;
      margin-left: -3.1%;
    }

    @media (max-width: 1376px) {
      margin-top: -2%;
    }

    @media (max-width: 1930px) {
      height: 40%;
      width: 102%;
      position: absolute;
      object-fit: contain;
      margin-top: -2%;
      padding: 0px 0px 0 0px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 88%;
    margin-bottom: 3%;
    margin-top: 15%;
    text-align: justify;
  }

  .course-info {
    max-width: 60%;
    padding-right: 90px;
  }

  .titulo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .list {
    text-align: justify;
  }

  .button-container {
    display: flex;
    gap: 10px; /* Espaçamento entre os botões */
  }

  .button-link {
    padding: 10px 20px;
    color: white;
    background-color: #ae1100;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    width: 150px; /* Ajuste a largura dos botões conforme necessário */
  }

  .button-link:hover {
    background-color: #8c0900;
  }
`;

export const SubmoduleList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%; /* Ajuste conforme necessário */
`;

export const SubmoduleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .play-icon {
    margin-right: 10px;
    color: #ae1100;
    font-size: 18px;
  }
`;

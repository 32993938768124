import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@material-ui/core';
import axios from '../../services/axios';
import { Form, StyledContainer, StyledSection, Bloco } from './styled';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

export default function SubmoduleEdit({ id, history, ...props }) {
  const dispatch = useDispatch();

  const ContentId = id;
  const [name, setName] = useState('');
  const [textContent, setText] = useState('');
  const [videoUrl, setVideo] = useState('');
  const [documentUrl, setDocument] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const handleDocumentChange = (e) => {
    setDocument(e.target.files[0]);
  };

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      return;
    }

    async function getData() {
      try {
        const { data } = await axios.get(`/contentsubmodule/${ContentId}`);
        setName(data.name);
        setText(data.textContent);
        setVideo(data.videoUrl);
        setDocument(data.documentUrl);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        const status = get(err, 'response.status', 0);
        const errors = get(err, 'response.data.errors', []);

        if (status === 400) errors.map((error) => toast.error(error));
        history.push('/home-admin');
      }
    }

    getData();
  }, [ContentId, history]);

  const handleStop = () => {
    const { handleClose } = props;
    handleClose();
  };

  const handleSubmit = async (e) => {
    const { handleClose } = props;
    e.preventDefault();

    if (!id) {
      toast.error('Ação não permitida.');
      return;
    }

    let formErrors = false;

    if (name.length > 0 && (name.length < 3 || name.length > 255)) {
      toast.error('Nome precisa ter entre 3 e 255 caracteres');
      formErrors = true;
    }

    if (formErrors) return;

    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('name', name);
      formData.append('textContent', textContent);
      formData.append('videoUrl', videoUrl);
      if (documentUrl) {
        formData.append('documentUrl', documentUrl); // Adiciona o PDF ao FormData
      }

      dispatch(
        actions.editContentRequest({
          history,
          id,
          formData,
        }),
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const status = get(err, 'response.status', 0);
      const data = get(err, 'response.data', {});
      const errors = get(data, 'errors', []);

      if (errors.length > 0) {
        errors.map((error) => toast.error(error));
      } else {
        toast.error('Erro desconhecido');
      }

      if (status === 401) dispatch(actions.loginFailure());
    }
    handleClose();
  };

  return (
    <Bloco>
      <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={handleStop}>
        <CloseIcon />
      </IconButton>
      <Loading isLoading={isLoading} />
      <StyledContainer>
        <StyledSection>
          <Form onSubmit={handleSubmit}>
            <div className="box-container">
              <h1>Editar Conteúdos</h1>
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Nome do Conteudo"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="Conteudo de texto"
                value={textContent}
                onChange={(e) => setText(e.target.value)}
              />
              <TextField
                className="text-field"
                InputProps={{ disableUnderline: true }}
                label="URL de video"
                value={videoUrl}
                onChange={(e) => setVideo(e.target.value)}
              />
              <input type="file" accept="application/pdf" onChange={handleDocumentChange} />
              <button type="submit">Salvar</button>
            </div>
          </Form>
        </StyledSection>
      </StyledContainer>
    </Bloco>
  );
}

SubmoduleEdit.propTypes = {
  match: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import Fricon from '../../assets/img/Fricon.svg';
import Diorama from '../../assets/img/Diorama.svg';
import Vila from '../../assets/img/Vila.svg';
import Hugo from '../../assets/img/HUgo.svg';
import Jose from '../../assets/img/Jose.svg';
import Fabio from '../../assets/img/Jose (1).svg';

const userTestimonials = [
  {
    avatar: <img src={Hugo} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />,
    name: 'Hugo Galvão',
    occupation: 'Supervisor de TI na Fricon',
    testimonial:
      'Sou suspeito de falar da BlackBelt IT Solutions. Sua equipe é extremamente íntegra, disciplinada, dedicada e focada nos resultados do cliente. Nosso trabalho focado na implantação da LGPD mostra o quanto a empresa está preparada para os desafios. Todas as expectativas condizem com a qualidade do serviço prestado junto a mesma. O relacionamento está além do básico, pois o time está sempre preocupado em entregar o melhor.',
  },
  {
    avatar: (
      <img src={Fabio} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />
    ),
    name: 'Fábio Gomes',
    occupation: 'CTO Diorama Digital',
    testimonial:
      'Trabalhar com a Blackbelt tem sido um prazer, a flexibilidade em entender nossa situação e conseguir trazer todas as medidas, indicações e ações necessárias em uma linguagem de fácil entendimento para nós foi com certeza um diferencial. Além disso, a Blackbelt é uma empresa que com certeza assume os desafios aos quais se propõe, para nós, são pessoas da nossa equipe, não parecem funcionários externos, mas sim alguém totalmente presente.',
  },
  {
    avatar: <img src={Jose} alt="Avatar Hugo Galvão" style={{ width: '90px', height: '90px', borderRadius: '100%' }} />,
    name: 'José Luiz Silva',
    occupation: 'Gerente de TI no Vila',
    testimonial:
      'A Blackbelt se mostrou uma empresa extremamente qualificada e disposta a nos ajudar a alcançar os objetivos do projeto que desenvolvemos juntos, no caso a adequação à Lei Geral de Proteção de Dados (LGPD). Desenvolvemos um excelente trabalho nas adequações à Lei e também na parte de Segurança da Informação. Os profissionais que atuaram no projeto se mostraram muito qualificados, solícitos e com foco na entrega. Foi um sucesso.',
  },
];

const whiteLogos = [Fricon, Diorama, Vila];
const darkLogos = [Fricon, Diorama, Vila];

const logoStyle = {
  width: '154px',
  opacity: 0.9,
};

export default function Testimonials() {
  const theme = useTheme();
  const [logo1] = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      style={{ fontFamily: 'MyFont' }}
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        style={{ fontFamily: 'MyFont' }}
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          style={{ fontFamily: 'MyFont', fontWeight: 'bold' }}
          sx={{
            fontFamily: 'MyFont',
            fontSize: { xs: '24px', sm: '28px', md: '35px' },
            fontWeight: 'bold',
            textAlign: { xs: 'center' },
          }}
        >
          O que nossos clientes dizem
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ fontFamily: 'MyFont' }}>
        {userTestimonials.map((testimonial, index) => {
          let logoSrc;
          if (testimonial.name === 'Fábio Gomes') {
            logoSrc = Diorama;
          } else if (testimonial.name === 'José Luiz Silva') {
            logoSrc = Vila;
          } else {
            logoSrc = logo1;
          }

          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={testimonial.name}
              sx={{ display: 'flex' }}
              style={{ fontFamily: 'MyFont' }}
            >
              <Card
                style={{ fontFamily: 'MyFont' }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                  p: 1,
                }}
              >
                <CardContent>
                  <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'MyFont' }}>
                    {testimonial.testimonial}
                  </Typography>
                </CardContent>
                <Box
                  style={{ fontFamily: 'MyFont' }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    pr: 2,
                  }}
                >
                  <CardHeader
                    avatar={
                      <Box
                        component="div"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '50%',
                          overflow: 'hidden',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {testimonial.avatar}
                      </Box>
                    }
                    title={
                      <Typography style={{ fontFamily: 'MyFont', fontWeight: 'bold' }}>{testimonial.name}</Typography>
                    }
                    subheader={
                      <Typography style={{ fontFamily: 'MyFont', color: 'black' }}>{testimonial.occupation}</Typography>
                    }
                    style={{ fontFamily: 'MyFont' }}
                  />
                  <img src={logoSrc} alt={`Logo ${index + 1}`} style={logoStyle} />
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { isPast } from 'date-fns';
import { Container } from '../../styles/GlobalStyles';
import { CollaboratorContainer, StyledCard } from './styled';
import axios from '../../services/axios';

import Loading from '../../components/Loading';

export default function User() {
  const id = useSelector((state) => state.auth.user.id);
  const [courses, setCourse] = useState([]);
  const [access, setAccess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filteredCourses = courses.filter((course) => access.some((ac) => ac.courseId === course.id && ac.hasAccess));

  useEffect(() => {
    async function getCourses() {
      setIsLoading(true);
      try {
        const response = await axios.get('/course');
        const course = response.data;
        setCourse(course);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getCourses();
  }, []);

  useEffect(() => {
    async function getAccess() {
      setIsLoading(true);
      try {
        const response = await axios.get('/usercourse');
        const courseAccess = response.data.filter(
          (UserCourse) => UserCourse.userId && UserCourse.userId === Number(id),
        );

        const updatedAccess = await Promise.all(
          courseAccess.map(async (ac) => {
            if (ac.accessExpirationDate && isPast(new Date(ac.accessExpirationDate))) {
              await axios.put(`/usercourse/${ac.id}`, { hasAccess: false });
              return { ...ac, hasAccess: false };
            }
            return ac;
          }),
        );

        setAccess(updatedAccess);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getAccess();
  }, [id]);

  return (
    <Container>
      <Loading isLoading={isLoading} />
      <CollaboratorContainer>
        {isLoading && <p>Carregando...</p>}
        {!isLoading && filteredCourses.length > 0 && (
          <Grid container spacing={1}>
            {filteredCourses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={String(course.id)}>
                <Link className="link" to={`/user-course/${course.id}/`}>
                  <StyledCard>
                    <CardMedia component="img" alt={course.name} height="110" image={course.img} className="img" />
                    <CardContent>
                      <Typography
                        variant="h1"
                        className="h1"
                        component="div"
                        align="left"
                        style={{ fontFamily: 'MyFont' }}
                      >
                        <h1>{course.name}</h1>
                      </Typography>
                      <div>{course.description}</div>
                    </CardContent>
                    <CardActions>
                      <Button size="small">Fazer capacitação</Button>
                    </CardActions>
                  </StyledCard>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
        {!isLoading && filteredCourses.length === 0 && (
          <p>Você ainda não foi selecionado para fazer curso, entre em contato com empresa responsável</p>
        )}
      </CollaboratorContainer>
    </Container>
  );
}
